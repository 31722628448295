import { Auth } from 'aws-amplify';
const AWS = require('aws-sdk');
AWS.config.update({
  region: 'eu-central-1',
});
/**
 * Authenticating requests to backend.
 * Permissions are set for 'amplify-nhlhub-prod-133423-authRole'
 * via the 'amplify-backend-policy'.
 */
export default class AuthService {
  getS3 = async () => {
    console.log('Auth service: S3');
    const credentials = await Auth.currentCredentials();
    const S3 = new AWS.S3({
      signatureVersion: 'v4',
      region: 'eu-central-1',
      //httpOptions: { timeout: 1000 * 86400 },
      credentials: Auth.essentialCredentials(credentials),
    });
    return S3;
  };
  getLambdaEU = async () => {
    const AWS = require('aws-sdk');
    AWS.config.update({
      region: 'eu-central-1',
    });
    const credentials = await Auth.currentCredentials();
    const lambda = new AWS.Lambda({
      credentials: Auth.essentialCredentials(credentials),
    });
    return lambda;
  };
  getLambdaUS = async () => {
    const AWS = require('aws-sdk');
    AWS.config.update({
      region: 'us-east-1',
    });
    const credentials = await Auth.currentCredentials();
    const lambda = new AWS.Lambda({
      credentials: Auth.essentialCredentials(credentials),
    });
    return lambda;
  };
  getCognito = async () => {
    const AWS = require('aws-sdk');
    AWS.config.update({
      region: 'eu-central-1',
    });
    console.log('Auth service: Cognito');
    const credentials = await Auth.currentCredentials();
    const cognito = new AWS.CognitoIdentityServiceProvider({
      credentials: Auth.essentialCredentials(credentials),
      config: { region: 'eu-central-1' },
      region: 'eu-central-1',
    });
    return cognito;
  };
}
