const EventName = ({ tricodeString, date }) => {
  // Check if the string contains exactly two tricodes separated by '@'
  const parts = tricodeString.split(' @ ');
  const colorStyle = date ? {} : { color: '#889' };

  if (parts.length === 2) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            padding: '0.2em',
            marginRight: '0.2em',
            fontWeight: 'bold',
            fontSize: '0.85em',
            ...colorStyle,
          }}
        >
          {parts[0]}
        </span>
        <span style={{ width: '1em', fontSize: '0.8em', ...colorStyle }}>@</span>
        <span
          style={{
            marginLeft: '0.2em',
            fontWeight: 'bold',
            fontSize: '0.85em',
            ...colorStyle,
          }}
        >
          {parts[1]}
        </span>
      </div>
    );
  }
  // If it doesn't match the pattern, return the string as is
  return <span style={colorStyle}>{tricodeString}</span>;
};

export default EventName;
