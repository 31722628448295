import aws_exports from '../aws-exports';
import { dbLambda } from '../utilities/functions';
import { CLUBS, BACKEND_LAMBDA } from '../utilities/constants';
import { Auth } from 'aws-amplify';
import AuthService from '../service/AuthService';
const AWS = require('aws-sdk');
AWS.config.update({
  region: aws_exports.aws_project_region,
});
const user_pool_id = aws_exports.aws_user_pools_id;

export default class UserService {
  async getUsers() {
    const [cognitoUsers, lambdaResult] = await Promise.all([
      this.handleListUsers(),
      dbLambda({ operation: 'listUsers' }, BACKEND_LAMBDA),
    ]);

    const users = [];
    const defaultClubId = 32;
    cognitoUsers.map(user => {
      const userDbRecord = lambdaResult.body.users.find(
        record => record.username === user.Username
      );

      // no record in the DB (external user)
      if (!userDbRecord) {
        let role = 'user';

        let club = CLUBS.find(club => club.id === defaultClubId); // NHL
        const profile = user.Attributes.find(attribute => attribute.Name === 'profile');
        if (profile) {
          const parsed = profile.Value.replaceAll('[', '')
            .replaceAll(']', '')
            .replaceAll(' ', '')
            .split(',');

          if (parsed.length > 1) {
            if (parsed[0] === 'Admin' || parsed[1] === 'Admin') {
              role = 'admin';
            }
            if (CLUBS.find(club => club.code === parsed[1])) {
              club = CLUBS.find(club => club.code === parsed[1]);
            } else if (CLUBS.find(club => club.code === parsed[0])) {
              club = CLUBS.find(club => club.code === parsed[0]);
            }
          }
          ////// supponor
          if (parsed[0] === 'Supponor' || parsed[1] === 'Supponor') {
            role = 'admin';
            club = CLUBS.find(club => club.code === 'NHL');
          }
        }
        const givenName = user.Attributes.find(attribute => attribute.Name === 'given_name');
        const familyName = user.Attributes.find(attribute => attribute.Name === 'family_name');
        users.push({
          id: 0,
          username: user.Username,
          status: user.UserStatus,
          enabled: user.Enabled,
          created: this.formatDate(user.UserCreateDate),
          role: role,
          first_name: givenName ? givenName.Value : 'External',
          last_name: familyName ? familyName.Value : 'User',
          email: user.Attributes.find(obj => obj.Name === 'email').Value,
          club: club,
        });
      }
      // internal Cognito user (non-NHL)
      else {
        users.push({
          //role,
          id: userDbRecord.id,
          username: user.Username,
          status: user.UserStatus,
          enabled: user.Enabled,
          created: this.formatDate(user.UserCreateDate),
          role: userDbRecord.role,
          email: userDbRecord.email,
          club: CLUBS.find(club => club.id === userDbRecord.club_id),
          first_name: userDbRecord.first_name,
          last_name: userDbRecord.last_name,
        });
      }
    });

    return { users };
  }
  changeAttribute = async user => {
    const authService = new AuthService();
    const cognito = await authService.getCognito();
    const result = await cognito
      .adminUpdateUserAttributes({
        UserPoolId: user_pool_id,
        Username: user.email,
        UserAttributes: [
          {
            Name: 'profile',
            Value: '[SJS, Admin]',
          },
        ],
      })
      .promise();
    return result;
  };

  createUsersFromDb = async () => {
    const response = await dbLambda({ operation: 'listUsers' }, 'hub2-api-venue-post');
    const formerDbUsers = response.body.users;
    let data = formerDbUsers.map(user => {
      return {
        id: user.id,
        club_id: user.clubId,
        role: user.role ? user.role : 'user',
        username: user.username,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      };
    });
  };

  async handleListUsers() {
    try {
      const maxNumOfIterations = 10;
      let iteration = 0;

      const authService = new AuthService();
      const cognito = await authService.getCognito();
      let result = await cognito
        .listUsers({
          UserPoolId: user_pool_id,
        })
        .promise();

      let cognitoUsers = result.Users;
      let nextToken = result.PaginationToken;
      while (nextToken && iteration < maxNumOfIterations) {
        iteration++;
        result = await cognito
          .listUsers({
            UserPoolId: user_pool_id,
            PaginationToken: nextToken,
          })
          .promise();
        nextToken = result.PaginationToken;
        cognitoUsers.push(...result.Users);
      }
      return cognitoUsers;
    } catch (err) {
      throw err;
    }
  }
  async createUser(user) {
    const authService = new AuthService();
    const cognito = await authService.getCognito();
    const cognitoResult = await cognito
      .adminCreateUser({
        UserPoolId: user_pool_id,
        Username: user.email,
        // setting UserAttributes array here allows
        // to completely remove email verification step
        UserAttributes: [
          {
            Name: 'email',
            Value: user.email,
          },
          {
            Name: 'email_verified',
            Value: 'true',
          },
        ],
      })
      .promise();

    const input = {
      username: cognitoResult.User.Username,
      role: user.role,
      email: user.email,
      clubId: user.club ? user.club.id : 1,
      // conditionally add optional properties
      ...(user.first_name && { first_name: user.first_name }),
      ...(user.last_name && { last_name: user.last_name }),
    };

    const dbUserResult = await dbLambda({ operation: 'createUser', data: input });
    return {
      id: dbUserResult.body.insertId,
      created: this.formatDate(cognitoResult.User.UserCreateDate),
      email: user.email,
      enabled: true,
      role: user.role,
      club: user.club,
      status: cognitoResult.User.UserStatus,
      username: cognitoResult.User.Username,
    };
  }
  async updateUser(user, oldUser = null) {
    // in case we don't have oldUser, we are brought here from Settings page
    // in this case no need to check whether email was changed
    if (oldUser && user.email !== oldUser.email) {
      const authService = new AuthService();
      const cognito = await authService.getCognito();
      await cognito
        .adminUpdateUserAttributes({
          UserAttributes: [
            {
              Name: 'email',
              Value: user.email,
            },
          ],
          UserPoolId: user_pool_id,
          Username: oldUser.username,
        })
        .promise();
    }
    const input = {
      id: user.id,
      username: user.username,
      role: user.role,
      organisation: user.organisation,
      email: user.email,
      settings: user.settings,
      clubId: user.club ? user.club.id : 1,
      // leagueId is null for roots
      leagueId: user.league && user.organisation !== 'root' ? user.league.id : null,
      // conditionally add optional properties
      ...(user.first_name && { first_name: user.first_name }),
      ...(user.last_name && { last_name: user.last_name }),
    };
    const dbUserResult = await dbLambda(
      { operation: 'updateUser', data: input },
      'hub2-api-venue-post'
    );
    console.log(dbUserResult);
    return;
  }

  async getUserData() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const dbUserResult = await dbLambda(
      {
        operation: 'getUser',
        username: cognitoUser.username,
      },
      BACKEND_LAMBDA
    );
    let dbUser;
    // non-external user (logged in directly through Cognito)
    if (dbUserResult.body && dbUserResult.body[0]) {
      dbUser = dbUserResult.body[0];
    }
    // external user (NHL)
    else {
      let club = 0,
        role = 'user';
      // try to parse profile attribute
      try {
        if (cognitoUser.attributes.profile) {
          if (cognitoUser.attributes.profile.includes('Supponor')) {
            club = 1;
            role = 'admin';
          }
          const parsed = cognitoUser.attributes.profile
            // TMP
            .replaceAll('[', '')
            .replaceAll(']', '')
            .replaceAll(' ', '')
            .split(',');

          if (parsed.length > 1) {
            if (parsed[1] === 'Admin' || parsed[0] === 'Admin') {
              role = 'admin';
            }
            let userClub;
            if (CLUBS.find(club => club.code === parsed[1])) {
              userClub = CLUBS.find(club => club.code === parsed[1]);
            } else if (CLUBS.find(club => club.code === parsed[0])) {
              userClub = CLUBS.find(club => club.code === parsed[0]);
            }

            if (userClub) {
              club = userClub.id;
            } else if (parsed[1] === 'Supponor') {
              club = 1; //{id: 1, name: 'National Hockey League', code: 'NHL'};
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      dbUser = {
        role: role,
        club_id: club,
        settings: null,
        id: null,
        firstName: cognitoUser.attributes.given_name,
        lastName: cognitoUser.attributes.family_name,
        email: cognitoUser.attributes.email ? cognitoUser.attributes.email : 'NHL',
        username: cognitoUser.username,
      };
    }

    return {
      email: cognitoUser.attributes.email ? cognitoUser.attributes.email : 'NHL',
      username: cognitoUser.username,
      id: dbUser ? dbUser.id : null,
      role: dbUser ? dbUser.role : 'user',
      firstName: dbUser ? dbUser.firstName : 'Club',
      lastName: dbUser ? dbUser.lastName : 'User',
      club: dbUser ? dbUser.club_id : null,
      settings: dbUser.settings ? dbUser.settings : null,
    };
  }
  async deleteUser(user) {
    const authService = new AuthService();
    const cognito = await authService.getCognito();
    await cognito
      .adminDeleteUser({
        UserPoolId: user_pool_id,
        Username: user.username,
      })
      .promise();
    const resultDb = await dbLambda({
      operation: 'deleteUser',
      data: { id: user.id },
    });
    return resultDb;
  }
  // async verifyEmail(username) {
  //     const res = await cognito.adminUpdateUserAttributes({
  //         UserPoolId: user_pool_id,
  //         Username: username,
  //         UserAttributes: [{ Name: 'email_verified', Value: 'true' }]
  //     }).promise();
  //     console.log(res);
  // }

  formatDate = (origDate, dbFormat = false) => {
    const d = new Date(origDate);
    let formattedDate;
    if (dbFormat) {
      // YYYY-mm-dd (DB)
      const ye = d.getFullYear();
      const mo = (1 + d.getMonth()).toString().padStart(2, '0');
      const da = d.getDate().toString().padStart(2, '0');
      formattedDate = `${ye}-${mo}-${da}`;
    } else {
      // dd MM yyy (UI)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      formattedDate = `${da} ${mo} ${ye}`;
    }
    return formattedDate;
  };
}
