/**
 * Client side of a component validating videos using FFmpeg
 */
import React, { useState, useEffect } from 'react';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import Loader from '../Loader';
import { ConfirmDialog } from '../ConfirmDialog';
import EventService from '../../service/EventsService';
import '../../App.css';
import EventName from '../VideoValidator/EventName';

export const VideoValidator = ({ isOpen, handleClose, event, date }) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [assets, setAssets] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [statusCounts, setStatusCounts] = useState({ true: 0, false: 0, undefined: 0 });

  let headerDate = '';
  let dateString = '';

  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    headerDate = `${month}/${day}/${year}`;
    dateString = `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const eventService = new EventService();
    if (date) {
      eventService.getDayEventVideoAssetList({ dateString }).then(data => {
        setAssets(data);
        console.log('Event video files:', data);
        updateStatusCounts(data);
      });
    } else if (event.id) {
      eventService.getEventVideoAssetList(event.id).then(data => {
        setAssets(data);
        console.log('Event video files:', data);
        updateStatusCounts(data);
      });
    }
  }, []);

  const updateStatusCounts = assetsArray => {
    const counts = { true: 0, false: 0, undefined: 0 };
    assetsArray.forEach(asset => {
      counts[asset.status] += 1;
    });
    setStatusCounts(counts);
  };

  const updateAssetsStatus = updatedBatch => {
    setAssets(prevAssets => {
      const updatedAssets = prevAssets.map(
        asset => updatedBatch.find(updatedAsset => updatedAsset.id === asset.id) || asset
      );
      updateStatusCounts(updatedAssets);
      return updatedAssets;
    });
  };

  const PARALLEL_PROCESSING = true; // lambdas are called in parallel (feature flag)

  const checkVideos = PARALLEL_PROCESSING
    ? // enable new version (when lambdas are called concurrently)
      async () => {
        setLoading(true);
        setProgress(0);

        const batchSize = 5;
        const maxParallelBatches = 5;
        const batches = [];

        for (let i = 0; i < assets.length; i += batchSize) {
          batches.push(assets.slice(i, i + batchSize));
        }

        let processed = 0;

        const processBatch = async batch => {
          const eventService = new EventService();
          const updatedBatch = await eventService.checkVideos(batch);
          updateAssetsStatus(updatedBatch);
          processed += batch.length;
          setProgress(processed);
        };

        while (batches.length > 0) {
          const currentBatches = batches.splice(0, maxParallelBatches);
          await Promise.all(currentBatches.map(processBatch));
        }

        setLoading(false);
      }
    : async () => {
        setLoading(true);
        setProgress(0);
        const batchSize = 5;
        let currentIndex = 0;

        while (currentIndex < assets.length) {
          const batch = assets.slice(currentIndex, currentIndex + batchSize);

          const eventService = new EventService();
          const updatedBatch = await eventService.checkVideos(batch);
          updateAssetsStatus(updatedBatch);
          console.log(updatedBatch);
          currentIndex += batchSize;
          setProgress(currentIndex);
        }

        setLoading(false);
      };

  const header = (
    <div
      className="table-header"
      style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
    >
      <div className="p-field" style={{ width: 230, marginTop: 16 }}>
        <Button
          label={loading ? `Checking Files (${progress}/${assets.length})` : 'Start Video Check'}
          icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-fw pi-check-square'}
          className="p-button-info"
          onClick={checkVideos}
          disabled={loading}
        />
      </div>
      <div
        className="status-container"
        style={{ display: 'flex', gap: '20px', marginLeft: '20px' }}
      >
        <div
          className="status-item"
          style={{
            backgroundColor: '#f0f0f0',
            padding: '5px 10px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i
            className="pi pi-check-square"
            style={{ color: 'green', fontSize: '1.2em', marginRight: '5px' }}
          ></i>
          <span>OK: {statusCounts.true}</span>
        </div>
        <div
          className="status-item"
          style={{
            backgroundColor: '#f0f0f0',
            padding: '5px 10px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i
            className="pi pi-times"
            style={{ color: 'red', fontSize: '1.2em', marginRight: '5px' }}
          ></i>
          <span>NOT OK: {statusCounts.false}</span>
        </div>
        <div
          className="status-item"
          style={{
            backgroundColor: '#f0f0f0',
            padding: '5px 10px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i
            className="pi pi-question-circle"
            style={{ fontSize: '1.2em', marginRight: '5px' }}
          ></i>
          <span>UNCHECKED: {statusCounts.undefined}</span>
        </div>
      </div>
    </div>
  );

  const footer = (
    <>
      {/* <Button
                label={`Set Selection [${selectedRows.length} feed${selectedRows.length !== 1 ? 's' : ''}]`}
                disabled={!selectedRows.length}
                icon="pi pi-check-circle"
                title="Activate playlist wizard for the selected feeds and close"
                onClick={() => handleClose(selectedRows)}
            /> */}
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-secondary p-button-outlined"
        onClick={handleClose}
      />
    </>
  );

  const header1 = (
    <div>
      {date ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}
        >
          <div
            style={{
              fontWeight: 600,
              fontSize: '1.25rem',
              textAlign: 'center',
              color: '#889',
              letterSpacing: 3,
            }}
          >
            Video Validator
          </div>
          <div style={{ color: '#889', display: 'flex' }}>({headerDate})</div>
        </div>
      ) : (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}
        >
          <div
            style={{
              fontWeight: 600,
              fontSize: '1.25rem',
              textAlign: 'center',
              color: '#889',
              letterSpacing: 3,
            }}
          >
            Video Validator
          </div>
          <div style={{ color: '#889', display: 'flex' }}>
            (<EventName tricodeString={event.name} date={date} />)
          </div>
        </div>
      )}
    </div>
  );

  const fileBodyTemplate = rowData => {
    const cleanAdvertiserName = rowData.advertiser
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/[^a-zA-Z0-9]+/g, '-');

    const exportedFile = `${rowData.id}_${cleanAdvertiserName}.${rowData.file.split('.').pop()}`;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            padding: '0.2em',
            marginRight: '0.2em',
            fontWeight: 'bold',
            color: '#888',
            fontSize: '0.95em',
          }}
        >
          {exportedFile}
        </span>
      </div>
    );
  };

  const eventBodyTemplate = rowData => {
    return (
      <div>
        {rowData && (
          <a
            href={`/#/playlist/${rowData.outputId}`}
            target="_blank"
            rel="noopener noreferrer"
            title={'Click to open this event page'}
          >
            <EventName tricodeString={rowData.eventName} date={date} />
          </a>
        )}
      </div>
    );
  };

  const adBodyTemplate = rowData => {
    const urlPart = 'advert';
    return (
      <div className="ellipsify">
        <a
          href={`/#/${urlPart}/${rowData.adId}`}
          target="_blank"
          rel="noopener noreferrer"
          title={'Open the ad page'}
        >
          {rowData.name}
        </a>
      </div>
    );
  };
  const activeBodyTemplate = rowData => {
    return (
      <div>
        {rowData.active ? (
          <i className="pi pi-eye greenColor" style={{ fontSize: '1.2em' }}></i>
        ) : (
          <i className="pi pi-eye-slash redColor" style={{ fontSize: '1.2em' }}></i>
        )}
      </div>
    );
  };
  const statusBodyTemplate = rowData => {
    if (rowData.status === undefined) {
      return (
        <div>
          <i className="pi pi-question-circle" style={{ fontSize: '1.2em' }}></i>
        </div>
      );
    } else {
      return (
        <div>
          {rowData.status ? (
            <i className="pi pi-check-square greenColor" style={{ fontSize: '1.2em' }}></i>
          ) : (
            <i className="pi pi-times redColor" style={{ fontSize: '1.2em' }}></i>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {isOpen && (
        <Dialog
          visible={isOpen}
          maximized
          header={header1}
          modal
          className="p-fluid styledDialog smallDialog"
          footer={footer}
          onHide={handleClose}
        >
          {assets.length ? (
            <DataTable
              value={assets}
              header={header}
              rowHover
              dataKey="id"
              className="datatable-responsive p-datatable-striped narrow-rows sticky-header"
              emptyMessage={assets.length ? 'No video assets in this event.' : 'Loading...'}
              selection={selectedRows}
              // Two rows below enable selection through a simple row click,
              // not necessarily by clicking on the checkbox
              selectionMode="multiple"
              metaKeySelection={false}
              onSelectionChange={e => setSelectedRows(e.value)}
              style={{ fontSize: '0.95rem' }}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3em', paddingLeft: 14 }} />
              <Column
                field="status"
                header="OK"
                style={{ width: 50 }}
                // sortable
                body={statusBodyTemplate}
              />
              <Column
                header="File"
                className="ellipsify"
                style={{ width: 300 }}
                body={fileBodyTemplate}
              />
              {date && (
                <Column
                  header="Event"
                  className="ellipsify"
                  style={{ width: 100 }}
                  body={eventBodyTemplate}
                />
              )}
              <Column
                field="name"
                header="Advert"
                className="ellipsify"
                // style={{width: 300}}
                sortable
                body={adBodyTemplate}
              />
              <Column
                field="advertiser"
                header="Advertiser"
                // style={{width: 200}}
                sortable
              />
              <Column field="zone" header="Zone" style={{ width: 50 }} />
              <Column field="format" header="Format" style={{ width: 70 }} />
              <Column
                field="club"
                header="Team"
                style={{ width: 100 }}
                sortable
                // body={networkBodyTemplate}
              />
              <Column
                field="active"
                header="Active"
                style={{ width: 100 }}
                body={activeBodyTemplate}
                sortable
              />
            </DataTable>
          ) : (
            <Loader />
          )}
        </Dialog>
      )}
      <ConfirmDialog
        isOpen={confirmDialog}
        handleClose={() => setConfirmDialog(false)}
        props={dialogProps}
      />
    </>
  );
};
