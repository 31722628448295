import AuthService from '../service/AuthService';
import {
  appendToFilename,
  buildS3Url,
  dbLambda,
  formatBytes,
  getOutputNetworks,
  getTimeOrDateInEtTimezone,
} from '../utilities/functions';
import {
  AUDIENCE_DATA_PATH,
  AUDIENCE_FILE_CA,
  AUDIENCE_FILE_US,
  countryList,
  CLUBS,
  FEED_MAP,
  BACKEND_LAMBDA,
  BACKEND_LAMBDA_2,
  BACKEND_LAMBDA_NODE20,
  SQS_LAMBDA,
  STATS_BUCKET,
  EXPORT_LAMBDA,
  STATS_LAMBDA,
  STATS_LAMBDA_NODE20,
  MVP_BUCKET,
  NETWORKS,
  CLOUDFRONT_PREFIX,
} from '../utilities/constants';

const MEDIA_BUCKET = 'test-hub2-media';
const S3_PREFIX = 'test-hub-2/media/event/';

export default class EventService {
  async getCalendarEventData(eventId) {
    const queries = [
      `SELECT 
            id, city, region 
            FROM Venue
            WHERE id in 
              (SELECT venue_id 
                FROM Event 
                WHERE id = ${eventId})`,

      `SELECT 
          id, name
          FROM Output
          where event_id = ${eventId}`,
    ];

    const response = await dbLambda(
      {
        operation: 'customQueryMultiple',
        data: queries,
      },
      BACKEND_LAMBDA
    );

    const [venue, outputs] = response.body || [[], []];
    return { venue, outputs };
  }

  async getCompData(compId) {
    let queries, comp;
    if (compId) {
      queries = await dbLambda({ hub2: true, competition: true, id: compId });
      comp = queries[0][0];
    } else {
      queries = await dbLambda({ hub2: true, competition: true });
    }
    const unsortedSports = this.sortObjectsByName(queries[1]);

    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[2]));

    const allEvents = queries[3]; // this competition events and template events
    const allSegments = queries[4];
    const outputs = queries.length > 5 ? queries[5] : [];
    const zones = queries.length > 6 ? queries[6] : [];

    const sports = unsortedSports.map(sport => {
      const sportSegmentsAndTriggers = allSegments.filter(segment => segment.sport_id === sport.id);
      // sort by segments and triggers. Triggers have no duration
      let segments = [],
        triggers = [];
      for (let el of sportSegmentsAndTriggers) {
        if (el.duration) segments.push(el);
        else triggers.push(el);
      }
      return { ...sport, segments, triggers };
    });

    const compEventsSegmentsAndTriggers = allSegments.filter(segment => segment.event_id);
    const eventsAndTemplates = allEvents.map(event => {
      const thisEventSegmentsAndTriggers = compEventsSegmentsAndTriggers.filter(
        segment => segment.event_id === event.id
      );
      return {
        ...event,
        venue: venues.find(venue => venue.id === event.venueId),
        sport: sports.find(sport => sport.id === event.sportId),
        outputs: outputs.filter(output => output.event_id === event.id),
        segments: thisEventSegmentsAndTriggers.filter(segment => segment.duration),
        triggers: thisEventSegmentsAndTriggers.filter(segment => !segment.duration),
        zones: zones.filter(zone => zone.event_id === event.id),
      };
    });
    const events = eventsAndTemplates.filter(event => event.competitionId);
    const templates = eventsAndTemplates.filter(event => !event.competitionId);
    if (compId) return { comp, sports, venues, events, templates };
    else return { sports, venues, events, templates };
  }

  async getTemplates() {
    let queries;
    queries = await dbLambda({ hub2: true, templates: true });
    console.log(queries);
    const unsortedSports = this.sortObjectsByName(queries[0]);

    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[1]));

    const allEvents = queries[2];
    const allSegments = queries[3];
    const outputs = queries.length > 4 ? queries[4] : [];
    const zones = queries.length > 5 ? queries[5] : [];

    const sports = unsortedSports.map(sport => {
      const sportSegmentsAndTriggers = allSegments.filter(segment => segment.sport_id === sport.id);
      // sort by segments and triggers. Triggers have no duration
      let segments = [],
        triggers = [];
      for (let el of sportSegmentsAndTriggers) {
        if (el.duration) segments.push(el);
        else triggers.push(el);
      }
      return { ...sport, segments, triggers };
    });

    const compEventsSegmentsAndTriggers = allSegments.filter(segment => segment.event_id);
    const events = allEvents.map(event => {
      const thisEventSegmentsAndTriggers = compEventsSegmentsAndTriggers.filter(
        segment => segment.event_id === event.id
      );
      return {
        ...event,
        venue: venues.find(venue => venue.id === event.venueId),
        sport: sports.find(sport => sport.id === event.sportId),
        outputs: outputs.filter(output => output.event_id === event.id),
        segments: thisEventSegmentsAndTriggers.filter(segment => segment.duration),
        triggers: thisEventSegmentsAndTriggers.filter(segment => !segment.duration),
        zones: zones.filter(zone => zone.event_id === event.id),
      };
    });
    return { sports, venues, events };
  }
  async getDayEventVideoAssetList({ eventId, dateString }) {
    if (dateString) {
      let startTime = performance.now();
      const response = await dbLambda(
        {
          query: 'getEventVideos',
          data: { dateString },
        },
        'hub2-db-3'
      );
      // console.log('--------', response)
      const endTime = performance.now();
      // console.log('BE Loaded in', Math.trunc(endTime - startTime), 'ms');
      // console.log('response_BE', JSON.stringify(response).length);

      // set the same file name is in the ZIP export package
      const getExportedAssetName = (file, id, advertiserName) => {
        const cleanAdvertiserName = advertiserName
          .toLowerCase()
          .replace(/ /g, '_')
          .replace(/[^a-zA-Z0-9]+/g, '-');

        return `${id}_${cleanAdvertiserName}.${file.split('.').pop()}`;
      };

      return response.body.map(asset => ({
        ...asset,
        exportedFile: getExportedAssetName(asset.file, asset.id, asset.advertiser),
      }));
    }
  }
  async getEventVideoAssetList(eventId) {
    const response = await dbLambda(
      {
        operation: 'customQuery',
        data: `
          SELECT DISTINCT
              Asset.id,
              Asset.original,
              Asset.zone,
              Advert.name AS name,
              Advert.id AS adId,
              CONCAT(path, original) AS file,
              CASE
                  WHEN Advert.format = 'Full Takeover' THEN 'FTO'
                  WHEN Advert.format = 'End Takeover' THEN 'ETO'
                  WHEN Advert.format = 'Split Zone' THEN 'SPZ'
                  WHEN Advert.format = 'Zone' THEN 'ZON'
                  ELSE Advert.name
              END AS format,
              Advert.active,
              Club.code AS club,
              Advertiser.name AS advertiser
          FROM Asset
          JOIN Advert ON Asset.advert_id = Advert.id
          JOIN Advertiser ON Advert.advertiser_id = Advertiser.id
          JOIN Club ON Advert.club_id = Club.id
          WHERE Asset.framerate IS NOT NULL
              AND Asset.advert_id IN (
                  SELECT DISTINCT advert_id
                  FROM Placement
                  WHERE impression_id IN (
                      SELECT id
                      FROM Impression
                      WHERE output_id IN (
                          SELECT id
                          FROM Output
                          WHERE event_id IN (
                              SELECT id
                              FROM Event
                              WHERE id = ${eventId}
                          )
                      )
                  )
              );`,
      },
      BACKEND_LAMBDA_NODE20
    );
    // set the same file name is in the ZIP export package
    const getExportedAssetName = (file, id, advertiserName) => {
      const cleanAdvertiserName = advertiserName
        .toLowerCase()
        .replace(/ /g, '_')
        .replace(/[^a-zA-Z0-9]+/g, '-');

      return `${id}_${cleanAdvertiserName}.${file.split('.').pop()}`;
    };

    return response.body.map(asset => ({
      ...asset,
      exportedFile: getExportedAssetName(asset.file, asset.id, asset.advertiser),
    }));
  }
  async checkVideos(videoList) {
    const TEMP_FFMPEG_LAMBDA = 'hub2-test-media-handler';
    const response = await dbLambda({ files: videoList }, TEMP_FFMPEG_LAMBDA);
    return response.body;
  }
  async getPlaylistWizardData(currentComptitionId) {
    // season 2024/25 ID (7) is the fallback
    const competitionId = currentComptitionId || 7;
    const response = await dbLambda(
      {
        operation: 'customQuery',
        data: `SELECT Output.name,
                Output.id,
                Event.name AS eventName,
                Event.et_time,
                GROUP_CONCAT(Network.code) AS networkCodes
            FROM Output
            JOIN Event ON Output.event_id = Event.id
            JOIN Output_Network ON Output.id = Output_Network.output_id
            JOIN Network ON Output_Network.network_id = Network.id
            WHERE Event.competition_id in (${competitionId})
            GROUP BY Output.name, Output.id, Event.name, Event.et_time;`,
      },
      BACKEND_LAMBDA
    );
    return response.body;
  }
  async getCalendarEvents(user) {
    let startTime = performance.now();
    const response = await dbLambda(
      {
        operation: 'getCalendarEvents',
      },
      BACKEND_LAMBDA
    );
    const [venues, events, outputs] = response.body;
    const venuesWithCodes = this.handleCountryCodes(venues);

    let userClubId;
    if (user && user.club) {
      userClubId = user.club;
    }
    const isClubUser = userClubId !== 1;
    let eventList = [...events];

    if (isClubUser) {
      const userClub = CLUBS.find(club => club.id === userClubId);
      if (userClub) {
        eventList = events.filter(event => event.name.includes(userClub.code));
      } else {
        eventList = [];
      }
    }

    // index venuesWithCodes by id
    const venueMap = new Map(venuesWithCodes.map(venue => [venue.id, venue]));

    // index outputs by event_id
    const outputsMap = new Map();
    outputs.forEach(output => {
      const eventOutputs = outputsMap.get(output.event_id) || [];
      eventOutputs.push(output);
      outputsMap.set(output.event_id, eventOutputs);
    });

    eventList.forEach(event => {
      event.venue = venueMap.get(event.venue_id);
      event.outputs = outputsMap.get(event.id) || [];
      event.competition = event.competition_id !== 2 ? 'Regular' : 'Playoffs';
    });

    const endTime = performance.now();
    console.log('Loaded in', Math.trunc(endTime - startTime), 'ms');
    return eventList;
  }

  async getCalendarEventsV2(user) {
    let startTime = performance.now();
    const minEventId = 10000;
    const nameMap = {
      'Away RSN': 1,
      'Home RSN': 2,
      'US National': 3,
      'CA National': 4,
      'CA National (FR)': 5,
      'Away RSN (FR)': 6,
      'Home RSN (FR)': 7,
      'NORD FB': 8,
      'DACH BT': 9,
      'NOVA IP': 10,
      CISSET: 11,
      'INTL 1': 12,
    };
    // inversed output name map
    const nameMapReverse = Object.fromEntries(
      Object.entries(nameMap).map(([key, value]) => [value, key])
    );
    const queries = [
      `SELECT 
                id, city, region 
            FROM Venue`,

      `SELECT 
                id as i, 
                name as n, 
                venue_id as v, 
                et_time as t, 
                competition_id as c
            FROM Event 
            WHERE visible = true
                AND id > ${minEventId}`,

      `SELECT id as i,
                CASE
                    WHEN name = 'Away RSN' THEN ${nameMap['Away RSN']}
                    WHEN name = 'Home RSN' THEN ${nameMap['Home RSN']}
                    WHEN name = 'US National' THEN ${nameMap['US National']}
                    WHEN name = 'CA National' THEN ${nameMap['CA National']}
                    WHEN name = 'CA National (FR)' THEN ${nameMap['CA National (FR)']}
                    WHEN name = 'Away RSN (FR)' THEN ${nameMap['Away RSN (FR)']}
                    WHEN name = 'Home RSN (FR)' THEN ${nameMap['Home RSN (FR)']}
                    WHEN name = 'NORD FB' THEN ${nameMap['NORD FB']}
                    WHEN name = 'DACH BT' THEN ${nameMap['DACH BT']}
                    WHEN name = 'NOVA IP' THEN ${nameMap['NOVA IP']}
                    WHEN name = 'CISSET' THEN ${nameMap['CISSET']}
                    WHEN name = 'INTL 1' THEN ${nameMap['INTL 1']}
                    ELSE name
                END AS n,
                event_id as e
            FROM Output
            WHERE event_id > ${minEventId};`,
    ];
    const response = await dbLambda(
      {
        operation: 'customQueryMultiple',
        data: queries,
      },
      BACKEND_LAMBDA
    );

    console.log('Payload size:', JSON.stringify(response.body).length);

    let [venues, events, outputs] = response.body;
    const venuesWithCodes = this.handleCountryCodes(venues);

    events = events.map(e => ({
      id: e.i,
      name: e.n,
      venue_id: e.v,
      et_time: e.t,
      competition_id: e.c,
    }));

    outputs = outputs.map(o => ({
      id: o.i,
      name: nameMapReverse[o.n] || o.n,
      event_id: o.e,
    }));

    let userClubId;
    if (user && user.club) {
      userClubId = user.club;
    }
    const isClubUser = userClubId !== 1;
    let eventList = [...events];

    if (isClubUser) {
      const userClub = CLUBS.find(club => club.id === userClubId);
      if (userClub) {
        eventList = events.filter(event => event.name.includes(userClub.code));
      } else {
        eventList = [];
      }
    }

    // index venuesWithCodes by id
    const venueMap = new Map(venuesWithCodes.map(venue => [venue.id, venue]));

    // index outputs by event_id
    const outputsMap = new Map();
    outputs.forEach(output => {
      const eventOutputs = outputsMap.get(output.event_id) || [];
      eventOutputs.push(output);
      outputsMap.set(output.event_id, eventOutputs);
    });

    eventList.forEach(event => {
      event.venue = venueMap.get(event.venue_id);
      event.outputs = outputsMap.get(event.id) || [];
      event.competition = event.competition_id !== 2 ? 'Regular' : 'Playoffs';
    });

    const endTime = performance.now();
    console.log('Loaded in', Math.trunc(endTime - startTime), 'ms');
    return eventList;
  }

  async getCalendarEventsV3(user) {
    let startTime = performance.now();
    const minEventId = 10000;
    const nameMap = {
      'Away RSN': 1,
      'Home RSN': 2,
      'US National': 3,
      'CA National': 4,
      'CA National (FR)': 5,
      'Away RSN (FR)': 6,
      'Home RSN (FR)': 7,
      'NORD FB': 8,
      'DACH BT': 9,
      'NOVA IP': 10,
      CISSET: 11,
      'INTL 1': 12,
    };
    // inversed output name map
    const nameMapReverse = Object.fromEntries(
      Object.entries(nameMap).map(([key, value]) => [value, key])
    );
    const queries = [
      `SELECT 
                id as i, 
                name as n,
                et_time as t
            FROM Event 
            WHERE visible = true
                AND id > ${minEventId}`,

      `SELECT id as i,
                CASE
                    WHEN name = 'Away RSN' THEN ${nameMap['Away RSN']}
                    WHEN name = 'Home RSN' THEN ${nameMap['Home RSN']}
                    WHEN name = 'US National' THEN ${nameMap['US National']}
                    WHEN name = 'CA National' THEN ${nameMap['CA National']}
                    WHEN name = 'CA National (FR)' THEN ${nameMap['CA National (FR)']}
                    WHEN name = 'Away RSN (FR)' THEN ${nameMap['Away RSN (FR)']}
                    WHEN name = 'Home RSN (FR)' THEN ${nameMap['Home RSN (FR)']}
                    WHEN name = 'NORD FB' THEN ${nameMap['NORD FB']}
                    WHEN name = 'DACH BT' THEN ${nameMap['DACH BT']}
                    WHEN name = 'NOVA IP' THEN ${nameMap['NOVA IP']}
                    WHEN name = 'CISSET' THEN ${nameMap['CISSET']}
                    WHEN name = 'INTL 1' THEN ${nameMap['INTL 1']}
                    ELSE name
                END AS n,
                event_id as e
            FROM Output
            WHERE event_id > ${minEventId}
            AND name = 'Away RSN' 
            OR name = 'ESPN'
            OR name = 'ROW';`,
    ];
    const response = await dbLambda(
      {
        operation: 'customQueryMultiple',
        data: queries,
      },
      BACKEND_LAMBDA
    );

    console.log('Payload size:', JSON.stringify(response.body).length);

    let [events, outputs] = response.body;

    events = events.map(e => ({
      id: e.i,
      name: e.n,
      et_time: e.t,
    }));

    outputs = outputs.map(o => ({
      id: o.i,
      name: nameMapReverse[o.n] || o.n,
      event_id: o.e,
    }));

    let userClubId;
    if (user && user.club) {
      userClubId = user.club;
    }
    const isClubUser = userClubId !== 1;
    let eventList = [...events];

    if (isClubUser) {
      const userClub = CLUBS.find(club => club.id === userClubId);
      if (userClub) {
        eventList = events.filter(event => event.name.includes(userClub.code));
      } else {
        eventList = [];
      }
    }

    // index outputs by event_id
    const outputsMap = new Map();
    outputs.forEach(output => {
      const eventOutputs = outputsMap.get(output.event_id) || [];
      eventOutputs.push(output);
      outputsMap.set(output.event_id, eventOutputs);
    });

    eventList.forEach(event => {
      event.outputs = outputsMap.get(event.id) || [];
    });

    const endTime = performance.now();
    console.log('Loaded in', Math.trunc(endTime - startTime), 'ms');
    return eventList;
  }

  async getEvent(id) {
    const queries = await dbLambda({ hub2: true, event: true, id: id });
    const event = queries[0][0]; // event data
    const unsortedSports = this.sortObjectsByName(queries[1]);
    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[2]));
    const outputs = queries[3].map(output => {
      const advertisers = queries[5]
        .filter(ader => ader.output_id == output.id)
        .map(ader => ader.name);
      return { ...output, advertisers: advertisers.sort((a, b) => a.localeCompare(b)) };
    }); // this event outputs
    const allSegments = queries[4];
    const zones = queries[6];

    const sports = unsortedSports.map(sport => {
      const sportSegmentsAndTriggers = allSegments.filter(segment => segment.sport_id === sport.id);
      // sort by segments and triggers. Triggers have no duration
      let segments = [],
        triggers = [];
      for (let el of sportSegmentsAndTriggers) {
        if (el.duration) segments.push(el);
        else triggers.push(el);
      }
      return { ...sport, segments, triggers };
    });

    event.venue = venues.find(venue => venue.id === event.venueId);
    event.sport = sports.find(sport => sport.id === event.sportId);
    event.segments = allSegments.filter(segment => segment.event_id === event.id);
    event.zones = zones;

    return { event, sports, venues, outputs };
  }
  groupBy = (arr, property) => {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...(acc[cur[property]] || []), cur];
      return acc;
    }, {});
  };
  groupIntoSubarrays = (arr, key) => {
    return arr.reduce(function (r, a, i) {
      if (!i || r[r.length - 1][0][key] !== a[key]) {
        return r.concat([[a]]);
      }
      r[r.length - 1].push(a);
      return r;
    }, []);
  };

  getExportData = async id => {
    const response = await dbLambda(
      {
        operation: 'getExportData', //'getStatisticsData',
        data: { id },
      },
      BACKEND_LAMBDA
    );

    return response;
  };
  getOutputsAds = async outputsIds => {
    const response = await dbLambda(
      {
        operation: 'getOutputsAds',
        data: { outputsIds },
      },
      BACKEND_LAMBDA_NODE20
    );
    return response.body;
  };
  async getExport(id, exportType, bucket, path, outputId = null) {
    const customEvent = id >= 10005 && id <= 10010;
    const customExportLambda = 'hub2-custom-export-handler';
    const lambda = customEvent ? customExportLambda : EXPORT_LAMBDA;
    console.log('Special event:', customEvent);
    const lambdaResponse = await dbLambda(
      {
        hub2: true,
        exportType,
        id,
        bucket,
        path,
        outputId,
      },
      lambda
    );
    return lambdaResponse;
  }
  async sendSqsMessage(data) {
    await dbLambda(data, SQS_LAMBDA);
  }
  async copyS3Object(data) {
    const response = await dbLambda(
      {
        operation: 'copyS3Object',
        data,
      },
      STATS_LAMBDA
    );
    console.log(response);
  }
  async createEventFeedsCSV(eventId, bucket, path) {
    const response = await dbLambda(
      {
        operation: 'createEventFeedsCSV',
        data: {
          eventId,
          path,
          bucket,
        },
      },
      STATS_LAMBDA
    );
    return response;
  }

  async testQueryLambda() {
    const queries = await dbLambda({ hub2: true, testQuery: true });
    return queries;
  }

  // share 3D Preview
  async createSharedPlaylist(id) {
    const response = await dbLambda(
      {
        operation: 'createSharedPlaylist',
        data: { id },
      },
      BACKEND_LAMBDA
    );
    return response.body;
  }
  async createSharedPlaylist_Impressions(sharedObj) {
    console.log(sharedObj);
    const response = await dbLambda(
      {
        operation: 'createSharedPlaylist_Impressions',
        data: sharedObj,
      },
      BACKEND_LAMBDA
    );
    console.log(response);
    return response.body;
  }

  async getTVRPlaylist(id, user, adType, tvrType) {
    const clubId = user.club ? user.club : 0;
    const response = await dbLambda(
      {
        operation: 'getTVRPlaylist',
        data: { id, adType, clubId, customTemplate: false },
      },
      BACKEND_LAMBDA
    );
    const queries = response.body;

    let output = queries[0][0];
    const event = queries[1][0];
    const allSegments = queries[2];
    let adverts = queries[14];
    const advertisers = queries[4];
    let assetList = queries[5];
    const zones = queries[6];
    const impressions = queries[7];
    const placements = queries[8];
    const outputStats = queries[12].length > 0 ? queries[12][0] : null;
    const broadcasters = queries[13];

    const feedOrder = [
      'Away RSN',
      'Home RSN',
      'US National',
      'CA National',
      'CA National (FR)',
      'Away RSN (FR)',
      'Home RSN (FR)',
      'INTL 1',
      'NORD FB',
      'DACH BT',
      'NOVA IP',
      'CISSET',
    ];
    let outputs = queries[9].sort((a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name));

    // All-Star Game events
    if (event.id < 10100) {
      const feedOrder = ['ESPN', 'SN', 'TVA', 'NHLN'];
      outputs = queries[9].sort((a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name));
    }

    outputs = outputs.map(outp => ({
      ...outp,
      broadcasters: broadcasters.filter(b => b.output_id === outp.id),
    }));

    const templates = queries[10];

    event.segments = allSegments.filter(segment => segment.event_id === event.id);
    event.zones = zones;

    assetList = assetList.map(asset => ({
      ...asset,
      thumbnail: CLOUDFRONT_PREFIX + asset.path + asset.thumbnail,
      playout: CLOUDFRONT_PREFIX + asset.path + asset.playout,
      preview: CLOUDFRONT_PREFIX + asset.path + asset.preview,
    }));

    // tv/radio (TODO: incorporate into SQL query)
    const formats = tvrType === 'tv' ? ['TVC', 'TVG'] : ['RAD'];
    adverts = adverts.filter(ad => formats.includes(ad.format));

    // add advertiser and assets info to each advert
    // and then sort adverts array by advertiser name
    adverts = adverts
      .map(advert => ({
        ...advert,
        advertiser: advertisers.find(advertiser => advertiser.id === advert.advertiser_id),
        assets: assetList.filter(asset => asset.advert_id === advert.id),
      }))
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    // allAdverts includes declined ads as well
    const allAdverts = [...adverts];

    // ads to show in the left Ad list table (club related and active ads only)
    const allocatedAdverts = adverts.filter(ad => ad.club_id === user.club && ad.active);

    output.networks = getOutputNetworks(event, output.name);
    output.broadcasters = broadcasters.filter(b => b.output_id === output.id);

    if (outputStats) {
      output.json = outputStats.raw;
      output.statsActive = outputStats.active || 0;
      output.reviewer = outputStats.reviewer;
      output.comment = outputStats.comment;
    } else {
      Object.assign(output, {
        json: '',
        statsActive: '',
        reviewer: '',
        comment: '',
      });
    }

    return {
      event,
      output,
      allocatedAdverts,
      adverts,
      allAdverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates,
      broadcasters,
    };
  }

  async getPlaylist(id, user, adType) {
    const startTime = performance.now();
    const clubId = user.club || 0;

    const assetQuery = `
      SELECT 
          advert_id AS a, 
          id AS i, 
          path AS p, 
          framerate AS f, 
          code AS c, 
          zone AS z, 
          playout AS pl 
      FROM Asset
      WHERE advert_id IN (
          SELECT id FROM Advert
          WHERE type = "${adType}"
          AND active = 1
          AND club_id IN (
              1, 
              (SELECT home FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id})),
              (SELECT away FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id}))
          )
          OR id IN (
            SELECT advert_id FROM Placement
            WHERE impression_id IN (
              SELECT id FROM Impression
              WHERE output_id = ${id}
            )
          )
      );`;

    const adQuery = `SELECT 
        id AS i, 
        advertiser_id AS ai, 
        club_id AS c,
        type AS t, 
        format AS f, 
        zones AS z, 
        name AS n, 
        active AS ac, 
        submitted AS s, 
        feeds AS fd 
      FROM 
        Advert 
      WHERE 
        type="${adType}"
        AND active = 1
        AND club_id IN (
          1, 
          (SELECT home FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id})),
          (SELECT away FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id}))
        )
        OR id IN (
          SELECT advert_id FROM Placement
          WHERE impression_id IN (
            SELECT id FROM Impression
            WHERE output_id = ${id}
          )
        );`;

    const approvalQuery = `SELECT 
        status AS s, 
        advert_id AS a 
      FROM 
        Approval 
      WHERE 
        club_id IN (
          1, 
          (SELECT home FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id})),
          (SELECT away FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id}))
        );`;

    const [dataResponse, assetResponse, adResponse, approvalResponse] = await Promise.all([
      dbLambda(
        {
          operation: 'getPlaylistV3_data',
          data: { id, adType, clubId, customTemplate: false },
        },
        BACKEND_LAMBDA
      ),
      dbLambda(
        {
          operation: 'customQuery',
          data: assetQuery,
        },
        BACKEND_LAMBDA
      ),
      dbLambda(
        {
          operation: 'customQuery',
          data: adQuery,
        },
        BACKEND_LAMBDA
      ),
      dbLambda(
        {
          operation: 'customQuery',
          data: approvalQuery,
        },
        BACKEND_LAMBDA
      ),
    ]);

    console.log('Loaded in', performance.now() - startTime, 'ms');
    console.log('Asset:', formatBytes(JSON.stringify(assetResponse).length));
    console.log('Data:', formatBytes(JSON.stringify(dataResponse).length));
    console.log('Ad:', formatBytes(JSON.stringify(adResponse).length));
    console.log('Approval:', formatBytes(JSON.stringify(approvalResponse).length));

    let adverts = adResponse.body.map(item => ({
      id: item.i,
      advertiser_id: item.ai,
      club_id: item.c,
      type: item.t,
      format: item.f,
      zones: item.z,
      name: item.n,
      active: item.ac,
      submitted: item.s,
      feeds: item.fd,
    }));

    const approvals = approvalResponse.body.map(item => ({
      status: item.s,
      advert_id: item.a,
    }));

    const ASSET_BUCKET = 'hub2-storage';
    const assetResponseParsed = assetResponse.body.map(item => ({
      advert_id: item.a,
      id: item.i,
      path: item.p,
      bucket: ASSET_BUCKET,
      framerate: item.f,
      code: item.c,
      zone: item.z,
      playout: item.pl,
    }));

    let assetList = assetResponseParsed.map(asset => ({
      ...asset,
      playout: CLOUDFRONT_PREFIX + asset.path + asset.playout,
    }));

    adverts = adverts.filter(a => a.type === adType);

    const [
      [output], // data[0][0]
      [event], // data[1][0]
      allSegments, // data[2]
      advertisers, // data[3]
      zones, // data[4]
      impressions, // data[5]
      placements, // data[6]
      outputsData, // data[7]
      templates, // data[8]
      outputStatsArr, // data[9]
      broadcasters, // data[10]
    ] = dataResponse.body;

    const outputStats = outputStatsArr.length > 0 ? outputStatsArr[0] : null;

    const feedOrder = [
      'Away RSN',
      'Home RSN',
      'US National',
      'CA National',
      'CA National (FR)',
      'Away RSN (FR)',
      'Home RSN (FR)',
      'INTL 1',
      'NORD FB',
      'DACH BT',
      'NOVA IP',
      'CISSET',
    ];

    const outputs = outputsData
      .sort((a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name))
      .map(outp => ({
        ...outp,
        broadcasters: broadcasters.filter(b => b.output_id === outp.id),
      }));

    event.segments = allSegments.filter(segment => segment.event_id === event.id);
    event.zones = zones;

    const advertiserMap = new Map(advertisers.map(adv => [adv.id, adv]));

    const assetMap = assetList.reduce((map, asset) => {
      if (!map.has(asset.advert_id)) {
        map.set(asset.advert_id, []);
      }
      map.get(asset.advert_id).push(asset);
      return map;
    }, new Map());

    const sortedAdverts = adverts
      .map(advert => ({
        ...advert,
        advertiser: advertiserMap.get(advert.advertiser_id),
        assets: assetMap.get(advert.id) || [],
      }))
      .filter(advert => advert.assets.length > 0)
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    // allAdverts array includes declined ads as well
    const allAdverts = [...sortedAdverts];

    const approvedAdvertIds = new Set(
      approvals.filter(approval => approval.status === 1).map(approval => approval.advert_id)
    );

    const activeAdverts = sortedAdverts.filter(ad => approvedAdvertIds.has(ad.id));

    // ads to show in the left Ad list table:
    // club-related & feed-related & active ads
    const allocatedAdverts = activeAdverts.filter(ad => {
      let feedSpecific = true;
      if (ad.feeds?.length) {
        // FEED_MAP[output.name] is undefined when loading a template;
        // in this case, output.name is defined by the user and is missing
        // from the list of available feeds, so we should skip the feed
        // check to prevent ads from being cut out.
        if (FEED_MAP[output.name]) {
          feedSpecific = ad.feeds.includes(FEED_MAP[output.name]);
        }
        // Separately, check 'Away Share RSN' option:
        // home team ads with this option disabled
        // must be hidden in the away playlists.
        // Note, event.club === user.club checks whether
        // it's a home team user accessing the away feed.
        if (
          feedSpecific &&
          user.club > 1 &&
          !ad.feeds.includes('S') &&
          output.name.includes('Away RSN') &&
          event.home === user.club
        ) {
          feedSpecific = false;
        }
      }
      return ad.club_id === user.club && feedSpecific && ad.active;
    });

    // The blank ad exists in the database but isn't
    // fetched and processed properly because it's inactive and
    // partly hidden from the user in the UI. Therefore, it's
    // artificially 'injected' below into the ad list for DED and GV
    if (adType === 'ded' || adType === 'gv') {
      const blankAd = {
        name: 'Asset',
        id: null,
        advertiser: { id: 0, name: 'Blank', code: 'BLANK' },
      };

      if (adType === 'ded') {
        blankAd.id = 1;
        blankAd.format = 'Full Takeover';
        blankAd.zones = '1-5';
      } else if (adType === 'gv') {
        blankAd.id = 2;
      }

      allocatedAdverts.unshift(blankAd);
      activeAdverts.unshift(blankAd);
      allAdverts.unshift(blankAd);
    }

    output.networks = getOutputNetworks(event, output.name);
    output.broadcasters = broadcasters.filter(b => b.output_id === output.id);

    if (outputStats) {
      Object.assign(output, {
        json: outputStats.raw,
        statsActive: outputStats.active || 0,
        reviewer: outputStats.reviewer,
        comment: outputStats.comment,
      });
    } else {
      Object.assign(output, {
        json: '',
        statsActive: '',
        reviewer: '',
        comment: '',
      });
    }

    console.log('Calculated in', performance.now() - startTime, 'ms');

    return {
      event,
      output,
      allocatedAdverts,
      adverts: activeAdverts,
      allAdverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates,
      broadcasters,
    };
  }

  async getPlaylistV3(id, user, adType) {
    const startTime = performance.now();
    const clubId = user.club || 0;

    const assetQuery = `
      SELECT 
          advert_id AS a, 
          id AS i, 
          path AS p, 
          framerate AS f, 
          code AS c, 
          zone AS z, 
          playout AS pl 
      FROM Asset
      WHERE advert_id IN (
          SELECT id FROM Advert
          WHERE type = "${adType}"
          AND club_id IN (
              1, 
              (SELECT home FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id})),
              (SELECT away FROM Event WHERE id IN (SELECT event_id FROM Output WHERE id = ${id}))
          )
      );`;

    const [dataResponse, assetResponse] = await Promise.all([
      dbLambda(
        {
          operation: 'getPlaylistV2_data',
          data: { id, adType, clubId, customTemplate: false },
        },
        BACKEND_LAMBDA
      ),
      dbLambda(
        {
          operation: 'customQuery',
          data: assetQuery,
        },
        BACKEND_LAMBDA
      ),
    ]);

    console.log('Loaded in', performance.now() - startTime, 'ms');
    console.log('Asset V2 response size:', JSON.stringify(assetResponse).length);
    console.log('Data response size:', JSON.stringify(dataResponse).length);

    const ASSET_BUCKET = 'hub2-storage';
    const aResponseParsed = assetResponse.body.map(item => ({
      advert_id: item.a,
      id: item.i,
      path: item.p,
      bucket: ASSET_BUCKET,
      framerate: item.f,
      code: item.c,
      zone: item.z,
      playout: item.pl,
    }));

    const assetList = aResponseParsed.map(asset => ({
      ...asset,
      playout: CLOUDFRONT_PREFIX + asset.path + asset.playout,
    }));

    const [
      [output], // data[0][0]
      [event], // data[1][0]
      allSegments, // data[2]
      adverts, // data[3]
      advertisers, // data[4]
      zones, // data[5]
      impressions, // data[6]
      placements, // data[7]
      outputsData, // data[8]
      templates, // data[9]
      approvals, // data[10]
      outputStatsArr, // data[11]
      broadcasters, // data[12]
    ] = dataResponse.body;

    const outputStats = outputStatsArr.length > 0 ? outputStatsArr[0] : null;

    const feedOrder = [
      'Away RSN',
      'Home RSN',
      'US National',
      'CA National',
      'CA National (FR)',
      'Away RSN (FR)',
      'Home RSN (FR)',
      'INTL 1',
      'NORD FB',
      'DACH BT',
      'NOVA IP',
      'CISSET',
    ];

    const outputs = outputsData
      .sort((a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name))
      .map(outp => ({
        ...outp,
        broadcasters: broadcasters.filter(b => b.output_id === outp.id),
      }));

    event.segments = allSegments.filter(segment => segment.event_id === event.id);
    event.zones = zones;

    const advertiserMap = new Map(advertisers.map(adv => [adv.id, adv]));

    const assetMap = assetList.reduce((map, asset) => {
      if (!map.has(asset.advert_id)) {
        map.set(asset.advert_id, []);
      }
      map.get(asset.advert_id).push(asset);
      return map;
    }, new Map());

    const sortedAdverts = adverts
      .map(advert => ({
        ...advert,
        advertiser: advertiserMap.get(advert.advertiser_id),
        assets: assetMap.get(advert.id) || [],
      }))
      .filter(advert => advert.assets.length > 0)
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    // allAdverts array includes declined ads as well
    const allAdverts = [...sortedAdverts];

    const approvedAdvertIds = new Set(
      approvals.filter(approval => approval.status === 1).map(approval => approval.advert_id)
    );

    const activeAdverts = sortedAdverts.filter(ad => approvedAdvertIds.has(ad.id));

    // ads to show in the left Ad list table:
    // club-related & feed-related & active ads
    const allocatedAdverts = activeAdverts.filter(ad => {
      let feedSpecific = true;
      if (ad.feeds?.length) {
        // FEED_MAP[output.name] is undefined when loading a template;
        // in this case, output.name is defined by the user and is missing
        // from the list of available feeds, so we should skip the feed
        // check to prevent ads from being cut out.
        if (FEED_MAP[output.name]) {
          feedSpecific = ad.feeds.includes(FEED_MAP[output.name]);
        }
        // Separately, check 'Away Share RSN' option:
        // home team ads with this option disabled
        // must be hidden in the away playlists.
        // Note, event.club === user.club checks whether
        // it's a home team user accessing the away feed.
        if (
          feedSpecific &&
          user.club > 1 &&
          !ad.feeds.includes('S') &&
          output.name.includes('Away RSN') &&
          event.home === user.club
        ) {
          feedSpecific = false;
        }
      }
      return ad.club_id === user.club && feedSpecific && ad.active;
    });

    // The blank ad exists in the database but isn't
    // fetched and processed properly because it's inactive and
    // partly hidden from the user in the UI. Therefore, it's
    // artificially 'injected' below into the ad list for DED and GV
    if (adType === 'ded' || adType === 'gv') {
      const blankAd = {
        name: 'Asset',
        id: null,
        advertiser: { id: 0, name: 'Blank', code: 'BLANK' },
      };

      if (adType === 'ded') {
        blankAd.id = 1;
        blankAd.format = 'Full Takeover';
        blankAd.zones = '1-5';
      } else if (adType === 'gv') {
        blankAd.id = 2;
      }

      allocatedAdverts.unshift(blankAd);
      activeAdverts.unshift(blankAd);
      allAdverts.unshift(blankAd);
    }

    output.networks = getOutputNetworks(event, output.name);
    output.broadcasters = broadcasters.filter(b => b.output_id === output.id);

    if (outputStats) {
      Object.assign(output, {
        json: outputStats.raw,
        statsActive: outputStats.active || 0,
        reviewer: outputStats.reviewer,
        comment: outputStats.comment,
      });
    } else {
      Object.assign(output, {
        json: '',
        statsActive: '',
        reviewer: '',
        comment: '',
      });
    }

    console.log('Calculated in', performance.now() - startTime, 'ms');

    return {
      event,
      output,
      allocatedAdverts,
      adverts: activeAdverts,
      allAdverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates,
      broadcasters,
    };
  }

  async getPlaylistV2(id, user, adType) {
    const startTime = performance.now();
    const clubId = user.club || 0;

    const [dataResponse, assetResponse] = await Promise.all([
      dbLambda(
        {
          operation: 'getPlaylistV2_data',
          data: { id, adType, clubId, customTemplate: false },
        },
        BACKEND_LAMBDA
      ),
      dbLambda(
        {
          operation: 'getPlaylistV2_assets',
          data: { id, adType, clubId, customTemplate: false },
        },
        BACKEND_LAMBDA
      ),
    ]);

    console.log('Loaded in', performance.now() - startTime, 'ms');
    console.log('Asset response size:', JSON.stringify(assetResponse).length);
    console.log('Data response size:', JSON.stringify(dataResponse).length);

    const assetList = assetResponse.body[0].map(asset => ({
      ...asset,
      playout: CLOUDFRONT_PREFIX + asset.path + asset.playout,
    }));

    const [
      [output], // data[0][0]
      [event], // data[1][0]
      allSegments, // data[2]
      adverts, // data[3]
      advertisers, // data[4]
      zones, // data[5]
      impressions, // data[6]
      placements, // data[7]
      outputsData, // data[8]
      templates, // data[9]
      approvals, // data[10]
      outputStatsArr, // data[11]
      broadcasters, // data[12]
    ] = dataResponse.body;

    const outputStats = outputStatsArr.length > 0 ? outputStatsArr[0] : null;

    const feedOrder = [
      'Away RSN',
      'Home RSN',
      'US National',
      'CA National',
      'CA National (FR)',
      'Away RSN (FR)',
      'Home RSN (FR)',
      'INTL 1',
      'NORD FB',
      'DACH BT',
      'NOVA IP',
      'CISSET',
    ];

    const outputs = outputsData
      .sort((a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name))
      .map(outp => ({
        ...outp,
        broadcasters: broadcasters.filter(b => b.output_id === outp.id),
      }));

    event.segments = allSegments.filter(segment => segment.event_id === event.id);
    event.zones = zones;

    const advertiserMap = new Map(advertisers.map(adv => [adv.id, adv]));

    const assetMap = assetList.reduce((map, asset) => {
      if (!map.has(asset.advert_id)) {
        map.set(asset.advert_id, []);
      }
      map.get(asset.advert_id).push(asset);
      return map;
    }, new Map());

    const sortedAdverts = adverts
      .map(advert => ({
        ...advert,
        advertiser: advertiserMap.get(advert.advertiser_id),
        assets: assetMap.get(advert.id) || [],
      }))
      .filter(advert => advert.assets.length > 0)
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    // allAdverts array includes declined ads as well
    const allAdverts = [...sortedAdverts];

    const approvedAdvertIds = new Set(
      approvals.filter(approval => approval.status === 1).map(approval => approval.advert_id)
    );

    const activeAdverts = sortedAdverts.filter(ad => approvedAdvertIds.has(ad.id));

    // ads to show in the left Ad list table:
    // club-related & feed-related & active ads
    const allocatedAdverts = activeAdverts.filter(ad => {
      let feedSpecific = true;
      if (ad.feeds?.length) {
        // FEED_MAP[output.name] is undefined when loading a template;
        // in this case, output.name is defined by the user and is missing
        // from the list of available feeds, so we should skip the feed
        // check to prevent ads from being cut out.
        if (FEED_MAP[output.name]) {
          feedSpecific = ad.feeds.includes(FEED_MAP[output.name]);
        }
        // Separately, check 'Away Share RSN' option:
        // home team ads with this option disabled
        // must be hidden in the away playlists.
        // Note, event.club === user.club checks whether
        // it's a home team user accessing the away feed.
        if (
          feedSpecific &&
          user.club > 1 &&
          !ad.feeds.includes('S') &&
          output.name.includes('Away RSN') &&
          event.home === user.club
        ) {
          feedSpecific = false;
        }
      }
      return ad.club_id === user.club && feedSpecific && ad.active;
    });

    // The blank ad exists in the database but isn't
    // fetched and processed properly because it's inactive and
    // partly hidden from the user in the UI. Therefore, it's
    // artificially 'injected' below into the ad list for DED and GV
    if (adType === 'ded' || adType === 'gv') {
      const blankAd = {
        name: 'Asset',
        id: null,
        advertiser: { id: 0, name: 'Blank', code: 'BLANK' },
      };

      if (adType === 'ded') {
        blankAd.id = 1;
        blankAd.format = 'Full Takeover';
        blankAd.zones = '1-5';
      } else if (adType === 'gv') {
        blankAd.id = 2;
      }

      allocatedAdverts.unshift(blankAd);
      activeAdverts.unshift(blankAd);
      allAdverts.unshift(blankAd);
    }

    output.networks = getOutputNetworks(event, output.name);
    output.broadcasters = broadcasters.filter(b => b.output_id === output.id);

    if (outputStats) {
      Object.assign(output, {
        json: outputStats.raw,
        statsActive: outputStats.active || 0,
        reviewer: outputStats.reviewer,
        comment: outputStats.comment,
      });
    } else {
      Object.assign(output, {
        json: '',
        statsActive: '',
        reviewer: '',
        comment: '',
      });
    }

    console.log('Calculated in', performance.now() - startTime, 'ms');

    return {
      event,
      output,
      allocatedAdverts,
      adverts: activeAdverts,
      allAdverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates,
      broadcasters,
    };
  }

  async reassignEventNetworks(outputs) {
    const response = await dbLambda(
      {
        operation: 'reassignEventNetworks',
        data: { outputs },
      },
      BACKEND_LAMBDA
    );
    return response;
  }

  // create raw metrics in the MVP's S3
  // bucket for valuation calculations
  async sendMetricsToMVP(id) {
    const path = 'new';
    const bucket = MVP_BUCKET;
    const response = await dbLambda(
      {
        operation: 'createRawMetrics',
        data: {
          outputId: id,
          properId: id,
          path,
          bucket,
        },
      },
      STATS_LAMBDA_NODE20
    );
    return response;
  }

  async updateStats(statsObject) {
    const response = await dbLambda(
      {
        operation: 'updateStatsNew',
        data: statsObject,
      },
      BACKEND_LAMBDA
    );
    return response;
  }

  createStats = async (outputId, raw) => {
    const response = await dbLambda(
      {
        operation: 'createStatsOutput',
        data: {
          output_id: outputId,
          raw: raw,
        },
      },
      BACKEND_LAMBDA
    );
    return response;
  };

  // update multiple Stats_Output objects
  completeOutputs = async (outputIds, complete) => {
    console.log(outputIds, complete);
    const response = await dbLambda(
      {
        operation: 'updateStatsMultiple',
        data: {
          outputIds,
          complete: complete ? '1' : '0',
        },
      },
      BACKEND_LAMBDA
    );
    return response;
  };

  // currently, this function is only used to create an empty stats file;
  // it's called from the Playlist Manager Tools menu.
  saveStatsToS3 = async (data, path) => {
    const jsonString = JSON.stringify(data, null, 1); // 1 space indentation

    const params = {
      Bucket: STATS_BUCKET,
      Key: path,
      Body: jsonString,
      CacheControl: 'no-cache',
    };
    try {
      const authService = new AuthService();
      const s3 = await authService.getS3();
      await s3.putObject(params).promise();
    } catch (error) {
      console.log(error);
    }
  };

  async getOutput(id, user) {
    const response = await dbLambda(
      {
        operation: 'getOutput',
        data: { id },
      },
      BACKEND_LAMBDA
    );
    const queries = response.body;

    let output = queries[0][0]; // output data
    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[1]));
    const event = queries[2][0];
    const allSegments = queries[3];
    let adverts = queries[4];
    const advertisers = queries[5];
    let assetList = queries[6];

    const zones = queries[7];

    const impressions = queries[8];
    const placements = queries[9];
    const approvals = queries[12];

    const feedOrder = [
      'Away RSN',
      'Home RSN',
      'US National',
      'CA National',
      'CA National (FR)',
      'Away RSN (FR)',
      'Home RSN (FR)',
      'INTL 1',
    ];
    const outputs = queries[10].sort(
      (a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name)
    );

    const templates = queries[11];

    event.venue = venues.find(venue => venue.id === event.venueId);
    event.segments = allSegments.filter(segment => segment.event_id === event.id);
    event.zones = zones;

    assetList = assetList.map(asset => ({
      ...asset,
      thumbnail: CLOUDFRONT_PREFIX + asset.path + asset.thumbnail,
      playout: CLOUDFRONT_PREFIX + asset.path + asset.playout,
      preview: CLOUDFRONT_PREFIX + asset.path + asset.preview,
    }));

    // ************************************************************
    // filter ads by club and approval

    const isClubUser = user.club !== 1;

    //if (isClubUser) {

    adverts = adverts.filter(
      ad =>
        ad.club_id === user.club ||
        ad.club_id === event.away ||
        ad.club_id === event.home ||
        ad.club_id === 1
    );
    //}

    adverts = adverts.filter(ad => {
      const approval = approvals.find(appr => appr.advert_id === ad.id);
      if (approval && approval.status === 1) {
        return ad;
      }
    });
    // ************************************************************

    adverts = adverts
      .map(advert => ({
        ...advert,
        advertiser: advertisers.find(advertiser => advertiser.id === advert.advertiser_id),
        assets: assetList.filter(asset => asset.advert_id === advert.id),
      }))
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    // filter adverts (by leagueId and approved)
    console.log(adverts);
    adverts = adverts.filter(advert => advert.assets.length > 0);

    const allocatedAdverts = adverts.filter(ad => ad.club_id === user.club);

    output.networks = [];
    switch (output.name) {
      case 'Away RSN':
        if (event.away_networks) {
          output.networks = event.away_networks.split(',');
        }
        break;
      case 'Home RSN':
        if (event.home_networks) {
          output.networks = event.home_networks.split(',');
        }
        break;
      case 'US National':
        if (event.national_networks) {
          let networks = event.national_networks.split(',');
          let US_networks = NETWORKS.filter(network => network.isUS).map(network => network.code);
          networks = networks.filter(network => US_networks.includes(network));
          output.networks = networks;
        }
        break;
      case 'CA National':
      case 'CA National (FR)':
        if (event.national_networks) {
          let networks = event.national_networks.split(',');
          let CA_networks = NETWORKS.filter(network => !network.isUS).map(network => network.code);
          networks = networks.filter(network => CA_networks.includes(network));
          output.networks = networks;
        }
        break;

      default:
        output.networks = [];
    }
    console.log(output.networks);
    const sports = [];
    return {
      event,
      sports,
      venues,
      output,
      allocatedAdverts,
      adverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates,
    };
  }

  async getTagsAndCategories(user) {
    const response = await dbLambda(
      {
        operation: 'getTagsAndCategories',
        data: { clubId: user.club },
      },
      BACKEND_LAMBDA_NODE20
    );

    return response.body;
  }

  async getTVRStats(user) {
    try {
      const { club } = user;
  
      const response = await dbLambda(
        {
          operation: 'getTVRStats',
          data: { clubId: club },
        },
        BACKEND_LAMBDA_NODE20
      );

      if (!response?.body) throw new Error('Invalid response from dbLambda');

      const { outputs, events } = response.body;

      const outputsMap = new Map();
      for (const outp of outputs) {
        if (!outputsMap.has(outp.event_id)) {
          outputsMap.set(outp.event_id, []);
        }
        outputsMap.get(outp.event_id).push(outp);
      }

      return events
        .sort((a, b) => new Date(a.et_time) - new Date(b.et_time))
        .map(ev => ({
          ...ev,
          date: getTimeOrDateInEtTimezone(ev.et_time, false),
          outputs: outputsMap.get(ev.id) || [],
        }));
    } catch (error) {
      console.error('Error fetching TVR stats:', error);
      return [];
    }
  }

  async getStats(user) {
    const [response, audienceResponse] = await Promise.all([
      dbLambda(
        {
          operation: 'getStats',
          data: { clubId: user.club },
        },
        BACKEND_LAMBDA_NODE20
      ),
      this.getAudienceData(),
    ]);
    const { outputs, events, secondaryOutputs } = response.body;

    const outputMap = new Map(outputs.map(output => [output.id, output]));
    const validSecondaryOutputs = [];
    secondaryOutputs.forEach(secOutput => {
      const primaryOutput = outputMap.get(secOutput.link);
      // we don't have all the data in the secOutp from the DB,
      // so we need to find the same output in the 'outputs'
      // array to retrieve the 'active' property from it
      const originalSecOutput = outputMap.get(secOutput.id);
      if (primaryOutput) {
        validSecondaryOutputs.push({
          ...secOutput,
          // save primary feed name in order
          // to get the proper share model for it
          primaryName: primaryOutput.name,
          primaryId: primaryOutput.id,
          active: originalSecOutput ? originalSecOutput.active : null,
        });
      }
    });

    const allOutputs = [
      ...outputs.filter(output => !secondaryOutputs.some(o => o.id === output.id)),
      ...validSecondaryOutputs,
    ];

    // Create a map from event IDs to outputs and audience responses
    const outputEventMap = new Map();
    allOutputs.forEach(output => {
      const eventId = output.event_id;
      if (!outputEventMap.has(eventId)) {
        outputEventMap.set(eventId, []);
      }
      outputEventMap.get(eventId).push(output);
    });

    const audienceMap = new Map();
    audienceResponse.forEach(row => {
      const eventId = row.event.id;
      if (!audienceMap.has(eventId)) {
        audienceMap.set(eventId, []);
      }
      audienceMap.get(eventId).push(row);
    });

    const availableEvents = [];
    events.forEach(event => {
      const eventOutputs = outputEventMap.get(event.id) || [];
      const eventAudience = audienceMap.get(event.id) || [];
      if (eventOutputs.length > 0) {
        const outputsWithNetworks = eventOutputs.map(output => {
          let audience = 0;
          let complete = false;
          let outputNetworkNames = [];
          let cpm;
          let multipleNetworks = false;
          if (output.network) {
            outputNetworkNames = output.network.split(',');
            if (outputNetworkNames.length > 1) {
              multipleNetworks = true;
              // ensure output networks are in the same order
              // as they appear in the MBS
              const eventNetworkNames = [
                ...event.national_networks.split(','),
                ...event.away_networks.split(','),
                ...event.home_networks.split(','),
              ].filter(Boolean);
              outputNetworkNames.sort((a, b) => {
                return eventNetworkNames.indexOf(a) - eventNetworkNames.indexOf(b);
              });

              // sort by existing networks's audience number (pick the max)
              // TODO: remove the part above
              // with sorting in accordance with the MBS network order
              const audienceValuesForNetworks = outputNetworkNames.map(network =>
                eventAudience.find(row => row.networks === network)
              );
              audienceValuesForNetworks.sort((a, b) => b.total - a.total);
              if (eventAudience.length > 0) {
                if (!audienceValuesForNetworks[0]) {
                  audience = 0;
                  complete = false;
                } else {
                  audience = audienceValuesForNetworks[0].total;
                  complete = audienceValuesForNetworks[0].complete;
                  // spotRate property might be just in one of multiple
                  // audienceValuesForNetworks elements => need to loop through all
                  const networkWithSpotRate = audienceValuesForNetworks.find(
                    item => item && item.spotRate !== undefined
                  );
                  if (networkWithSpotRate) cpm = networkWithSpotRate.spotRate;
                }
              }
            } else {
              const outputAudience = eventAudience.find(
                row => row.networks === outputNetworkNames[0]
              );
              if (outputAudience) {
                audience = outputAudience.total;
                complete = outputAudience.complete;
                cpm = outputAudience.spotRate;
              }
            }

            // solution valid before 03.06.23 (summing up audience values)
            // for (let outputNetworkName of output.network.split(',')) {
            //     const outputAudience = eventAudience
            //         .find(row => row.networks === outputNetworkName);
            //     // when we have multiple networks per feed,
            //     // sum up the audience values for each feed
            //     if (outputAudience) {
            //         audience += outputAudience.total;
            //     }
            // }
          }
          // a quick fix for same-name network event edge case
          // TODO: include outputId === output.id comparison
          // when identifying the correct audience entry for a feed
          if (output.id === 6930) {
            const audienceObj = eventAudience.find(el => el.outputId === output.id);
            if (audienceObj) audience = audienceObj.total;
          }

          if (cpm && cpm.length > 3)
            cpm = cpm[3]; // 4th element is the NHL CPM value
          else cpm = undefined;

          return {
            ...output,
            audience,
            complete,
            cpm,
            ...(multipleNetworks && { network: outputNetworkNames.join(',') }),
          };
        });

        const clubAway = CLUBS.find(club => club.id === event.away);
        const clubHome = CLUBS.find(club => club.id === event.home);
        const away = clubAway ? clubAway.code : '';
        const home = clubAway ? clubHome.code : '';

        const eventDate = getTimeOrDateInEtTimezone(event.et_time, false);
        const eventTime = getTimeOrDateInEtTimezone(event.et_time);

        let name = `${away} @ ${home}`;
        // All-Star special case. TODO: use competition_id instead
        // [TMP]
        if (event.id < 10100) {
          // TODO: fetch event.name from the DB and
          // set name = event.name;
          const allStarMap = {
            10002: 'All-Star 1',
            10003: 'All-Star 2',
            10004: 'All-Star Final',
            10005: 'All-Star Final',
            10006: "Women's Game",
            10007: 'All-Star Skills',
            10009: 'All-Star 1',
            10010: 'All-Star 2',
            10011: 'CA vs SWE',
            10012: 'USA vs FIN',
            10013: 'SWE vs FIN',
            10014: 'USA vs CA',
            10015: 'CA vs FIN',
            10016: 'USA vs SWE',
            10017: 'Final Game',
          };
          name = allStarMap[event.id];
        }
        availableEvents.push({
          ...event,
          name,
          date: eventDate,
          time: eventTime,
          outputs: outputsWithNetworks,
        });
      }
    });

    return {
      events: availableEvents,
    };
  }

  async getAudienceData() {
    const [allEvents, outputsResponse, audienceData] = await Promise.all([
      this.getCalendarEvents({ club: 1 }),
      dbLambda(
        {
          operation: 'getSpotRateData',
        },
        BACKEND_LAMBDA
      ),
      dbLambda(
        {
          operation: 'customQuery',
          data: 'SELECT * FROM Data_Output',
        },
        BACKEND_LAMBDA
      ),
    ]);
    const audienceEntries = audienceData.body.map((item, index) => {
      const output = outputsResponse.body.find(o => o.id == item.output_id);

      let spotRate;

      if (output?.stats) spotRate = output.stats.split(',').map(v => parseFloat(v));
      // todo: double check if it's reasonable to sum up multiple networks

      return {
        ...item,
        event: allEvents.find(e => e.id === item.event_id),
        networks: item.network,
        total: item.ca + item.us,
        total_est: item.ca_est + item.us_est,
        index,
        ...(output && { complete: output.complete }),
        ...(spotRate && { spotRate }),
      };
    });

    return audienceEntries;
  }

  async parseAllAudienceData(toast) {
    const parseCSV = csvData => {
      // split the CSV data into rows
      const rows = csvData
        .split('\n')
        .map(row => row.trim())
        .filter(Boolean);

      // Split the first row to get the headers
      const headers = rows[0].split(',').map(header => header.trim());

      // Helper function to map headers based on rules
      const mapHeader = header => {
        const lHeader = header.toLowerCase();
        const headerStringsToSkip = ['event', 'date', 'cpm', 'total audience', 'est. total'];
        if (headerStringsToSkip.includes(lHeader)) {
          return 'skip';
        }
        // CA ad rate
        else if (lHeader.includes('rate') && lHeader.includes('us')) {
          return 'us_rate';
        }
        // CA ad rate
        else if (lHeader.includes('rate') && lHeader.includes('ca')) {
          return 'ca_rate';
        }
        // US audience estimated
        else if (lHeader.includes('est') && lHeader.includes('us')) {
          return 'us_est';
        }
        // CA audience estimated
        else if (lHeader.includes('est') && lHeader.includes('ca')) {
          return 'ca_est';
          // US audience
          // } else if (lHeader.startsWith('us')) {
          //   return 'us';
          // // CA audience
          // } else if (lHeader.startsWith('ca')) {
          //   return 'ca';
          // :60 TV Spot
        } else if (lHeader.includes('60 tv')) {
          return 'tv_60';
        } else if (lHeader.includes('15 tv')) {
          return 'tv_15';
        } else if (lHeader.includes('15 d') || lHeader.includes('box')) {
          return 'doublebox_15';
        } else if (lHeader.includes('10 tv')) {
          return 'tv_10';
        } else if (lHeader.includes('10 drop')) {
          return 'dropin_10';
        } else if (lHeader.includes('board')) {
          return 'billboard_10';
        } else if (lHeader.includes('60 radio')) {
          return 'radio_60';
        } else if (lHeader.includes('30 radio')) {
          return 'radio_30';
        } else if (lHeader.includes('15 radio')) {
          return 'radio_15';
          // :30 Spot aka 'manual' CPM
        } else if (lHeader.includes('30') && lHeader.includes('spot')) {
          return 'spot_30';
        } else if (lHeader.includes('est') && lHeader.includes('ded')) {
          return 'ded_est';
        } else if (lHeader.includes('est') && lHeader.includes('sv')) {
          return 'sv_est';
        } else if (lHeader.includes('est') && lHeader.includes('gv')) {
          return 'gv_est';
        } else if (lHeader.startsWith('network')) {
          return 'network';
        } else if (lHeader.includes('team')) {
          return 'team';
        } else if (lHeader.startsWith('game id')) {
          return 'id';
        } else {
          return null;
        }
      };

      let warning;
      const result = [];

      // Loop through the remaining rows
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].split(',').map(cell => cell.trim());

        // create obj by mapping headers to corresponding row values
        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          const mappedKey = mapHeader(headers[j]);
          if (mappedKey === 'skip') {
            continue;
          } else if (mappedKey) {
            obj[mappedKey] = row[j];
          } else {
            warning = `Header "${headers[j]}" cannot be recognized`;
            console.log(warning);
          }
        }

        result.push(obj);
      }

      // temporarily comment out toast for unknown headers since NHL has started using
      // the exported file to upload new data, which is causing false positive warnings
      // if (warning) {
      //   toast.current.show({
      //     severity: 'warn',
      //     summary: 'Unknown header',
      //     detail: warning,
      //     life: 7000,
      //   });
      // }

      return result;
    };

    const getContents = async filename => {
      const response = await this.readCSV(filename);
      const dataBuffer = Buffer.from(response.Body.data);
      const utf16Decoder = new TextDecoder('UTF-8');
      return utf16Decoder.decode(dataBuffer);
    };

    const [allEvents, outputsResponse] = await Promise.all([
      this.getCalendarEvents({ club: 1 }),
      dbLambda(
        {
          operation: 'customQuery',
          data: `
                    select Output.id, Output.event_id, Network.code
                    from Output
                    join Output_Network on Output.id = Output_Network.output_id
                    join Network on Output_Network.network_id = Network.id
                    where Output.id > 12000`,
        },
        BACKEND_LAMBDA
      ),
    ]);
    const outputs = outputsResponse.body; // outputs with spot rate and networks info

    const AUDIENCE_DATA_FILE = 'data.csv';
    const dataFile = AUDIENCE_DATA_PATH + AUDIENCE_DATA_FILE;
    const contents = await getContents(dataFile, allEvents);

    const dataArray = parseCSV(contents)
      .map(row => {
        const event = allEvents.find(evt => evt.id == row.id); // must be '=='
        if (!event) return {};

        const output = outputs.find(o => o.event_id == event.id && o.code === row.network);
        const outputId = output ? output.id : undefined;
        return {
          ...row,
          event,
          outputId,
          networks: row.network, // TODO: use network property only. For now, it's being duplicated to be in sync with updateAudienceData()
        };
      })
      .filter(row => row.event);
    return dataArray;
  }

  // handles CA/US audience file upload
  // TODO: combine with the same functions for the ALL uploader
  async parseAudienceFileAndUpdateDB(filename) {
    const getContents = async (filename, allEvents) => {
      const response = await this.readCSV(filename);
      const dataBuffer = Buffer.from(response.Body.data);
      const utf16Decoder = new TextDecoder('UTF-8');
      const contents = utf16Decoder.decode(dataBuffer);

      let arrContents = contents.split(/\r?\n/g);
      // A workaround to identify the order of columns
      // (to handle the fact that NHL uses different order
      // of columns in 2022/23 and 2023/24 season files:
      // i.e. it's ID, Team, Networks, Audience in 2022/23
      // and       ID, Networks, Team, Audience in 2023/24)
      let secondColumn =
        arrContents[0] && arrContents[0].split(',').length > 1 && arrContents[0].split(',')[1];
      if (secondColumn.toLowerCase().includes('network')) {
        secondColumn = 'network';
      }

      return arrContents
        .map(row => {
          // replace commas with semicolons inside quotes
          const row_ = row.replace(/"[^"]+"/g, function (v) {
            return v.replace(/,/g, ';');
          });

          let [id, team, networks, value] = row_.split(',');
          if (secondColumn === 'network') {
            [id, networks, team, value] = row_.split(',');
          }
          return {
            id,
            team,
            networks,
            value,
          };
        })
        .map(row => ({
          event: allEvents.find(evt => evt.id == row.id), // must be '=='
          value: row.value ? parseInt(row.value) : 0,
          team: row.team,
          networks: row.networks,
          id: row.id,
        }))
        .filter(row => row.event);
    };
    const [allEvents, outputsResponse] = await Promise.all([
      this.getCalendarEvents({ club: 1 }),
      dbLambda(
        {
          operation: 'customQuery',
          data: `
                    select Output.id, Output.event_id, Network.code
                    from Output
                    join Output_Network on Output.id = Output_Network.output_id
                    join Network on Output_Network.network_id = Network.id
                    where Output.id > 12000`,
        },
        BACKEND_LAMBDA
      ),
    ]);

    const outputs = outputsResponse.body; // outputs with spot rate and networks info
    const filePath = AUDIENCE_DATA_PATH + filename;

    const contents = await getContents(filePath, allEvents);
    const fieldToChange = filename === AUDIENCE_FILE_CA ? 'ca' : 'us';

    // building SQL query
    const fields = [fieldToChange];

    let query = `INSERT INTO Data_Output (event_id, network, output_id, team, ${fieldToChange}) VALUES `;

    // iterate through the CSV file rows
    contents.forEach((output, index) => {
      const network = `'${output.networks}'`;
      const team = `'${output.team}'`;
      const eventId = output.id;

      const output_ = outputs.find(o => o.event_id == eventId && o.code === output.networks);
      const outputId = output_ ? output_.id : 'NULL'; // handle undefined output ID

      query += `(${eventId}, ${network}, ${outputId}, ${team}, ${output.value})`;

      // add a comma if it's not the last element
      if (index !== contents.length - 1) {
        query += `, `;
      }
    });

    // ON DUPLICATE KEY UPDATE only updates existing
    // entries where event_id and network match
    query += ` ON DUPLICATE KEY UPDATE `;
    query += fields.map(field => `${field} = VALUES(${field})`).join(', ');

    console.log(query);
    const response = await dbLambda(
      {
        operation: 'customQuery',
        data: query,
      },
      BACKEND_LAMBDA
    );
    console.log(response);
  }

  // Update output/audience data based on CSV files
  // uploaded by the NHL admin
  async parseAudienceData() {
    const combineContents = (arr1, arr2) => {
      const arr3 = [];
      for (const item1 of arr1) {
        let found = false;
        for (const item2 of arr2) {
          if (
            item1.id === item2.id &&
            item1.networks === item2.networks &&
            item1.team === item2.team
          ) {
            arr3.push({
              id: item1.id,
              networks: item1.networks,
              team: item1.team,
              value1: item1.value,
              value2: item2.value,
              total: item1.value + item2.value,
            });
            found = true;
            break;
          }
        }
        if (!found) {
          arr3.push({
            id: item1.id,
            networks: item1.networks,
            team: item1.team,
            value1: item1.value,
            value2: 0,
            total: item1.value,
          });
        }
      }

      for (const item2 of arr2) {
        let found = false;
        for (const item3 of arr3) {
          if (item2.id === item3.id && item2.networks === item3.networks) {
            found = true;
            break;
          }
        }
        if (!found) {
          arr3.push({
            id: item2.id,
            networks: item2.networks,
            team: item2.team,
            value1: 0,
            value2: item2.value,
            total: item2.value,
          });
        }
      }
      return arr3;
    };
    // read CSV file and return the array of objects
    // { id, teams, networks, value }
    const getContents = async (filename, allEvents) => {
      const response = await this.readCSV(filename);
      const dataBuffer = Buffer.from(response.Body.data);
      const utf16Decoder = new TextDecoder('UTF-8');
      const contents = utf16Decoder.decode(dataBuffer);

      let arrContents = contents.split(/\r?\n/g);
      // A workaround to identify the order of columns
      // (to handle the fact that NHL uses different order
      // of columns in 2022/23 and 2023/24 season files:
      // i.e. it's ID, Team, Networks, Audience in 2022/23
      // and       ID, Networks, Team, Audience in 2023/24)
      let secondColumn =
        arrContents[0] && arrContents[0].split(',').length > 1 && arrContents[0].split(',')[1];
      if (secondColumn.toLowerCase().includes('network')) {
        secondColumn = 'network';
      }

      return arrContents
        .map(row => {
          // replace commas with semicolons inside quotes
          const row_ = row.replace(/"[^"]+"/g, function (v) {
            return v.replace(/,/g, ';');
          });

          let [id, team, networks, value] = row_.split(',');
          if (secondColumn === 'network') {
            [id, networks, team, value] = row_.split(',');
          }
          return {
            id,
            team,
            networks,
            value,
          };
        })
        .map(row => ({
          event: allEvents.find(evt => evt.id == row.id), // must be '=='
          value: row.value ? parseInt(row.value) : 0,
          team: row.team,
          networks: row.networks,
          id: row.id,
        }))
        .filter(row => row.event);
    };

    const [allEvents, outputsResponse] = await Promise.all([
      this.getCalendarEvents({ club: 1 }),
      dbLambda(
        {
          operation: 'getSpotRateData',
        },
        BACKEND_LAMBDA
      ),
    ]);
    const outputs = outputsResponse.body; // outputs with spot rate and networks info

    const caFile = AUDIENCE_DATA_PATH + AUDIENCE_FILE_CA;
    const usFile = AUDIENCE_DATA_PATH + AUDIENCE_FILE_US;
    const [caContents, usContents] = await Promise.all([
      getContents(caFile, allEvents),
      getContents(usFile, allEvents),
    ]);

    let combined = combineContents([...caContents], [...usContents]);
    combined = combined.map(row => {
      // '==' used intentionally
      const event = allEvents.find(evt => evt.id == row.id);
      return {
        event,
        date: event ? new Date(event.et_time) : '',
        total: row.total,
        ca: row.value1,
        us: row.value2,
        networks: row.networks,
        team: row.team,
        id: row.id,
      };
    });

    // transform to dictionary, groupped by output's event_id
    const groupped = this.groupBy(combined, 'id');
    let index = 1;
    for (const id in groupped) {
      const eventOutputs = outputs.filter(o => id == o.event_id);
      for (let i = 0; i < groupped[id].length; i++) {
        const output = eventOutputs.find(o =>
          o.networks.split(',').includes(groupped[id][i].networks)
        );
        let networks = output ? output.networks.split(',') : [];
        let spotRate;
        // set audience data completion status
        groupped[id][i].complete = output ? output.complete : null;
        // unique index to be used in Audience Data table
        groupped[id][i].index = index++;
        if (output) {
          groupped[id][i].outputId = output.id;
        }

        if (output && output.stats) {
          spotRate = output.stats.split(',').map(v => parseFloat(v));
          if (networks.length === 1) {
            groupped[id][i].spotRate = spotRate;
          } else if (networks.length > 1) {
            const thisFeedEntries = groupped[id].filter(entry => networks.includes(entry.networks));
            const elementWithMaxTotal = thisFeedEntries.reduce((maxElement, obj) => {
              if (obj.total > maxElement.total) return obj;
              else return maxElement;
            });
            groupped[id] = groupped[id].map(el => {
              if (el.networks === elementWithMaxTotal.networks) {
                return { ...el, spotRate };
              } else return el;
            });
          }
        }
      }
    }

    // transform dictionary back to array
    const array = Object.values(groupped)
      .flat()
      .map(item => {
        if (item.event.id === 2022020614 && item.team === 'CAR') return { ...item, outputId: 6931 };
        return item;
      });

    return array;
  }

  async updateAudienceData(data) {
    if (!data.length) return;
    const outputList = data.map(item => {
      return {
        ca_rate: item.ca_rate,
        us_rate: item.us_rate,
        // ca: item.ca,
        // us: item.us,
        ca_est: item.ca_est,
        us_est: item.us_est,
        ded_est: item.ded_est,
        sv_est: item.sv_est,
        gv_est: item.gv_est,
        tv_60: item.tv_60,
        tv_15: item.tv_15,
        tv_10: item.tv_10,
        radio_60: item.radio_60,
        radio_30: item.radio_30,
        radio_15: item.radio_15,
        spot_30: item.spot_30,
        doublebox_15: item.doublebox_15,
        dropin_10: item.dropin_10,
        billboard_10: item.billboard_10,
        event_id: item.event.id,
        output_id: item.outputId, // can be undefined
        network: item.networks,
        team: item.team,
      };
    });

    const buildQuery = outputList => {
      // define fields for both insert and update operations
      const fields = [
        'ca_rate',
        'us_rate',
        // 'ca',
        // 'us',
        'ca_est',
        'us_est',
        'ded_est',
        'sv_est',
        'gv_est',
        'tv_60',
        'tv_15',
        'tv_10',
        'radio_60',
        'radio_30',
        'radio_15',
        'spot_30',
        'doublebox_15',
        'dropin_10',
        'billboard_10',
      ];

      let query = `INSERT INTO Data_Output (event_id, network, output_id, team, ${fields.join(', ')}) VALUES `;

      // iterate through the output list and build the query
      outputList.forEach((output, index) => {
        // in case value is null, undefined or '', return 0
        const values = fields.map(field => ((output[field] ?? '') === '' ? 0 : output[field]));
        const network = `'${output.network}'`;
        const team = `'${output.team}'`;
        const outputId = output.output_id !== undefined ? output.output_id : 'NULL'; // handle undefined output ID

        query += `(${output.event_id}, ${network}, ${outputId}, ${team}, ${values.join(', ')})`;

        // add a comma if it's not the last element
        if (index !== outputList.length - 1) {
          query += `, `;
        }
      });

      // ON DUPLICATE KEY UPDATE only updates existing
      // entries where event_id and network match
      query += ` ON DUPLICATE KEY UPDATE `;
      query += fields.map(field => `${field} = VALUES(${field})`).join(', ');

      return query;
    };

    const query = buildQuery(outputList);
    const response = await dbLambda(
      {
        operation: 'customQuery',
        data: query,
      },
      BACKEND_LAMBDA
    );

    console.log(response);
  }
  // scan raw stats json
  async scanMetrics(jsonPath) {
    const response = await dbLambda(
      {
        operation: 'scanJson',
        data: { path: jsonPath },
      },
      STATS_LAMBDA
    );
    return response;
  }
  // read raw stats json
  async readMetrics(jsonPath) {
    const response = await dbLambda(
      {
        operation: 'readMetrics',
        data: { path: jsonPath },
      },
      STATS_LAMBDA
    );
    return response;
  }
  // read a CSV file
  async readCSV(path) {
    const response = await dbLambda(
      {
        operation: 'readCSV',
        data: { path: path },
      },
      STATS_LAMBDA
    );
    return response;
  }
  // update raw stats json
  async updateMetrics(jsonPath, jsonContents) {
    const response = await dbLambda(
      {
        operation: 'updateMetrics',
        data: { path: jsonPath, json: jsonContents },
      },
      STATS_LAMBDA
    );
    return response;
  }
  // delete stats_output object by output_id
  async deleteMetrics(output_id) {
    const response = await dbLambda(
      {
        operation: 'deleteStats',
        data: { output_id },
      },
      BACKEND_LAMBDA
    );
    return response;
  }
  // precalculate stats to use in the reporting page
  async precalculateFeed(feed, properId, sendToMVP) {
    const response = await dbLambda(
      {
        operation: 'precalculateFeed',
        data: { ...feed, properId, sendToMVP: sendToMVP },
      },
      STATS_LAMBDA_NODE20
    );
    return response;
  }
  // copy a json stats file to serve as
  // a stats source for the secondary feed
  async copyJson(data) {
    console.log(data);
    const response = await dbLambda(
      {
        operation: 'copyJson',
        data: data,
      },
      STATS_LAMBDA
    );
    return response;
  }

  // copy stats_ouptut obj for a given output
  async copyStatsOutput(data) {
    const response = await dbLambda(
      {
        operation: 'copyStatsOutput',
        data: data,
      },
      BACKEND_LAMBDA
    );
    return response.body;
  }

  async getSVOutput(id, user) {
    const response = await dbLambda(
      {
        operation: 'getSVOutput',
        data: { id },
      },
      BACKEND_LAMBDA
    );
    const queries = response.body;

    let output = queries[0][0]; // output data
    const venues = this.handleCountryCodes(this.sortObjectsByName(queries[1]));
    const event = queries[2][0];
    const allSegments = queries[3];
    let adverts = queries[4];
    const advertisers = queries[5];
    let assetList = queries[6];

    const zones = queries[7];

    const impressions = queries[8];
    const placements = queries[9];
    const approvals = queries[12];

    const feedOrder = [
      'Away RSN',
      'Home RSN',
      'US National',
      'CA National',
      'CA National (FR)',
      'Away RSN (FR)',
      'Home RSN (FR)',
      'INTL 1',
    ];
    const outputs = queries[10].sort(
      (a, b) => feedOrder.indexOf(a.name) - feedOrder.indexOf(b.name)
    );

    const templates = queries[11];

    event.venue = venues.find(venue => venue.id === event.venueId);
    event.segments = allSegments.filter(segment => segment.event_id === event.id);
    event.zones = zones;

    assetList = assetList.map(asset => ({
      ...asset,
      thumbnail: CLOUDFRONT_PREFIX + asset.path + asset.thumbnail,
      playout: CLOUDFRONT_PREFIX + asset.path + asset.playout,
      preview: CLOUDFRONT_PREFIX + asset.path + asset.preview,
    }));

    // ************************************************************
    // filter ads by club and approval

    //if (isClubUser) {

    adverts = adverts.filter(
      ad =>
        ad.club_id === user.club ||
        ad.club_id === event.away ||
        ad.club_id === event.home ||
        ad.club_id === 1
    );
    //}

    adverts = adverts.filter(ad => {
      const approval = approvals.find(appr => appr.advert_id === ad.id);
      console.log(approval);
      if (approval && approval.status === 1) {
        return ad;
      }
    });
    // ************************************************************

    adverts = adverts
      .map(advert => ({
        ...advert,
        advertiser: advertisers.find(advertiser => advertiser.id === advert.advertiser_id),
        assets: assetList.filter(asset => asset.advert_id === advert.id),
      }))
      .sort((a, b) => a.advertiser.name.localeCompare(b.advertiser.name));

    // filter adverts (by leagueId and approved)
    adverts = adverts.filter(
      advert =>
        // advert.leagueId === leagueId &&
        advert.assets.length > 0
      //&& advert.assets[0].approved === 1
    );

    const allocatedAdverts = adverts.filter(ad => ad.club_id === user.club);

    // ***********************************
    // current output networks
    console.log(event.away_networks);
    console.log(event.home_networks);
    console.log(event.national_networks);
    output.networks = [];
    switch (output.name) {
      case 'Away RSN':
        if (event.away_networks) {
          output.networks = event.away_networks.split(',');
        }
        break;
      case 'Home RSN':
        if (event.home_networks) {
          output.networks = event.home_networks.split(',');
        }
        break;
      case 'US National':
        if (event.national_networks) {
          let networks = event.national_networks.split(',');
          let US_networks = NETWORKS.filter(network => network.isUS).map(network => network.code);
          networks = networks.filter(network => US_networks.includes(network));
          output.networks = networks;
        }
        break;
      case 'CA National':
      case 'CA National (FR)':
        if (event.national_networks) {
          let networks = event.national_networks.split(',');
          let CA_networks = NETWORKS.filter(network => !network.isUS).map(network => network.code);
          networks = networks.filter(network => CA_networks.includes(network));
          output.networks = networks;
        }
        break;

      default:
        output.networks = [];
    }

    const sports = [];
    return {
      event,
      sports,
      venues,
      output,
      allocatedAdverts,
      adverts,
      advertisers,
      placements,
      impressions,
      outputs,
      templates,
    };
  }

  async createAllocations(output_id, ad_ids) {
    console.log(output_id, ad_ids);
    const result = await dbLambda({
      hub2: true,
      create: true,
      operation: 'createAllocations',
      output_id: output_id,
      ad_ids: ad_ids,
    });
    console.log(result);
  }
  async deleteAllocation(output_id, advert_id) {
    // TODO: use ad_ids array instead of advert_id to batch delete multiple allocations
    console.log(output_id, advert_id);
    //const result = await dbLambda({ 'hub2': true, 'delete': true, 'operation': 'deleteAllocations', 'output_id': output_id, 'advert_id': advert_id});
    const response = await dbLambda(
      {
        operation: 'deleteAllocation',
        data: { output_id, advert_id },
      },
      'hub2-api-venue-post'
    );
    console.log(response);
    return response;
  }

  async createEvent(event) {
    let imageURL = '';
    if (event.image) {
      const now = new Date();
      const fileKey = appendToFilename(S3_PREFIX + event.image.name, '_' + now.getTime());
      await this.uploadImage(event.image, fileKey);
      imageURL = buildS3Url(MEDIA_BUCKET, fileKey);
    }
    const segments = event.segments.map(segment => {
      return {
        sequence: segment.sequence,
        name: segment.name,
        duration: segment.duration,
      };
    });
    const triggers = event.triggers.map(segment => {
      return {
        sequence: segment.sequence,
        name: segment.name,
      };
    });
    const input = {
      // id: event.id,
      name: event.name,
      date_time: event.date_time,
      sportId: event.sport.id,
      venueId: event.venue.id,
      // competitionId: event.competitionId ? event.competitionId : null,
      // competition: event.competitionId, // TODO: remove this from DB schema
      visible: event.visible,
      segments,
      triggers,
      zones: event.zones,
      // conditionally add optional properties
      ...(event.image && { image: imageURL }),
      ...(event.competitionId && { competitionId: event.competitionId }),
      // ...event.competitionId && { competition: event.competitionId },
      ...(event.duration && { duration: event.duration }),
      ...(event.ad_threshold && { ad_threshold: event.ad_threshold }),
    };

    const result = await dbLambda({ operation: 'createEvent', data: input });

    return { id: result.body.id, imageURL };
    // return { ...result.data.createEvent };
  }
  // rename output or update output 'complete' status
  async updateOutput(output) {
    const result = await dbLambda(
      {
        operation: 'updateOutput',
        data: output,
      },
      BACKEND_LAMBDA
    );
    // console.log('outputUpdated:', result);
  }
  async updateEvent(event, oldEvent) {
    // there are 3 options here for event.image
    // if neither oldEvent nor event has image attached
    //    => event.image is null or ''
    // if oldEvent had image attached and image wasn't changed on update
    //    => event.image is string here (url)
    // else a new image was uploaded on update (regardles of oldEvent image existance)
    //    => event.image is a file object here {name: ..., size: ..., etc.}
    let imageUpdated = false;
    let imageURL = null;
    // console.log(event.image)
    // image uploaded
    if (event.image && event.image.name) {
      imageUpdated = true;
      const now = new Date();
      const fileKey = appendToFilename(S3_PREFIX + event.image.name, '_' + now.getTime());
      // upload image
      await this.uploadImage(event.image, fileKey);
      imageURL = buildS3Url(MEDIA_BUCKET, fileKey);
    }
    // image removed
    else if (!event.image && oldEvent.image) {
      imageUpdated = true;
      imageURL = '';
    } else {
      // return imageURL in the end,
      // so need to assign an existing image url to it
      imageURL = event.image;
    }
    const segments = event.sport.segments.map(segment => {
      return {
        sequence: segment.sequence,
        name: segment.name,
        event_id: event.id,
        duration: segment.duration,
      };
    });
    const triggers = event.sport.triggers.map(segment => {
      return {
        sequence: segment.sequence,
        name: segment.name,
        event_id: event.id,
      };
    });

    const input = {
      id: event.id,
      name: event.name,
      date_time: event.date_time,
      sportId: event.sport.id,
      venueId: event.venue.id,
      visible: event.visible,
      segments,
      triggers,
      zones: event.zones,
      // conditionally add optional properties
      ...(event.competitionId && { competitionId: event.competitionId }),
      ...(imageUpdated && { image: imageURL }),
      ...(event.duration && { duration: event.duration }),
      ...(event.ad_threshold && { ad_threshold: event.ad_threshold }),
    };

    await dbLambda({ hub2: true, update: true, operation: 'updateEvent', data: input });

    return { imageURL };
  }

  async savePlaylist(imps, output_id, type, user) {
    const placements = [];
    const impressionIds = [];

    let zoneIndexOffset = 1;

    // TODO: make a global var
    const ZONE_INDEXES = {
      DED: [1, 2, 3, 4, 5],
      SV: [6, 7],
      GV: [8, 9, 10, 11],
      TV: [12],
      Radio: [13],
    };
    const zoneIndexes = ZONE_INDEXES[type];
    const dedZoneNumber = ZONE_INDEXES['DED'].length;
    const svZoneNumber = ZONE_INDEXES['SV'].length;
    const gvZoneNumber = ZONE_INDEXES['GV'].length;
    const tvZoneNumber = 1;
    const radioZoneNumber = 1;

    if (type === 'SV') {
      zoneIndexOffset += dedZoneNumber;
    } else if (type === 'GV') {
      zoneIndexOffset += dedZoneNumber + svZoneNumber;
    } else if (type === 'TV') {
      zoneIndexOffset += dedZoneNumber + svZoneNumber + gvZoneNumber;
    } else if (type === 'Radio') {
      zoneIndexOffset += dedZoneNumber + svZoneNumber + gvZoneNumber + tvZoneNumber;
    }

    imps.forEach(impression => {
      // skip empty (header) rows
      if (impression.id) {
        impressionIds.push(impression.id);

        impression.placements.map((cellPlacements, placementIndex) => {
          if (cellPlacements[0].id) {
            placements.push({
              advert_id: cellPlacements[0].id,
              zone_sequence: placementIndex + zoneIndexOffset,
              impression_id: impression.id,
              sequence: 1,
            });
          }
          if (cellPlacements[1].id) {
            placements.push({
              advert_id: cellPlacements[1].id,
              zone_sequence: placementIndex + zoneIndexOffset,
              impression_id: impression.id,
              sequence: 2,
            });
          }
        });
      }
    });
    const response = await dbLambda(
      {
        operation: 'savePlaylist_NEW',
        data: { output_id, placements, impressionIds, user, zoneIndexes },
      },
      BACKEND_LAMBDA
    );
    console.log('playlist saved:', response);
  }

  async saveMultiplePlaylists(imps, sourceOutputId, type, user, outputIds) {
    const placements = [];
    const impressionIds = [];

    let zoneIndexOffset = 1;

    // TODO: make a global var
    const ZONE_INDEXES = {
      DED: [1, 2, 3, 4, 5],
      SV: [6, 7],
      GV: [8, 9, 10, 11],
      TV: [12],
      Radio: [13],
    };
    const zoneIndexes = ZONE_INDEXES[type];
    const dedZoneNumber = ZONE_INDEXES['DED'].length;
    const svZoneNumber = ZONE_INDEXES['SV'].length;
    const gvZoneNumber = ZONE_INDEXES['GV'].length;
    const tvZoneNumber = 1;
    const radioZoneNumber = 1;

    if (type === 'SV') {
      zoneIndexOffset += dedZoneNumber;
    } else if (type === 'GV') {
      zoneIndexOffset += dedZoneNumber + svZoneNumber;
    } else if (type === 'TV') {
      zoneIndexOffset += dedZoneNumber + svZoneNumber + gvZoneNumber;
    } else if (type === 'Radio') {
      zoneIndexOffset += dedZoneNumber + svZoneNumber + gvZoneNumber + tvZoneNumber;
    }

    imps.forEach(impression => {
      // skip empty (header) rows
      if (impression.id) {
        impressionIds.push(impression.id);

        impression.placements.map((cellPlacements, placementIndex) => {
          if (cellPlacements[0].id) {
            placements.push({
              advert_id: cellPlacements[0].id,
              zone_sequence: placementIndex + zoneIndexOffset,
              impression_id: impression.id,
              sequence: 1,
            });
          }
          if (cellPlacements[1].id) {
            placements.push({
              advert_id: cellPlacements[1].id,
              zone_sequence: placementIndex + zoneIndexOffset,
              impression_id: impression.id,
              sequence: 2,
            });
          }
        });
      }
    });

    const response = await dbLambda(
      {
        operation: 'saveMultiplePlaylists',
        data: {
          sourceOutputId,
          placements,
          impressionIds,
          user,
          zoneIndexes,
          outputIds,
        },
      },
      BACKEND_LAMBDA
    );
    console.log(outputIds.length, 'playlists saved:', response);
  }

  async saveExportToDb(event_id) {
    const lambdaResponse = await dbLambda(
      {
        hub2: true,
        exportType: 'eventExport',
        id: event_id,
        name: 'saveExportToDb',
      },
      EXPORT_LAMBDA
    );
    console.log(lambdaResponse);
  }

  async createOutput(output, playoffs = false) {
    const defaultDuration = 30;

    const input = {
      name: output.name,
      event_id: output.event_id,
      set_duration: defaultDuration,
    };
    const operation = playoffs ? 'createOutput_playoffs' : 'createOutput';
    const response = await dbLambda(
      { hub2: true, operation: operation, data: input },
      BACKEND_LAMBDA
    );
    console.log('create output:', response);
    return response.body;
  }
  // TODO: merge this into createOutput() function
  // use an extra arg to specify whether it's template or not
  async createOutputFromPlaylistManager(output, playoffs = false) {
    const defaultDuration = 30;
    const input = {
      name: output.name,
      event_id: output.event_id,
      set_duration: defaultDuration,
    };
    const operation = playoffs ? 'createOutput_playoffs' : 'createOutputFromPlaylistManager';
    const response = await dbLambda(
      { hub2: true, operation: operation, data: input },
      BACKEND_LAMBDA
    );
    console.log('create output:', response);
    return response.body;
  }

  // deleting templates in Playlist page
  async deleteOutput(id) {
    const input = { id };
    const response = await dbLambda(
      {
        operation: 'deleteOutput',
        data: input,
      },
      BACKEND_LAMBDA
    );
    console.log('response:', response);
    return response.body;
  }

  async exportOutput(event, output, adverts, advertisers, impressions, placements) {
    const result = await dbLambda({
      operation: 'exportOutput',
      event: event,
      output: output,
      adverts: adverts,
      advertisers: advertisers,
      impressions: impressions,
      placements: placements,
    });
    console.log(result);
  }

  async uploadImage(image, fileKey) {
    const params = {
      Bucket: MEDIA_BUCKET,
      Key: fileKey,
      ContentType: image.type,
      Body: image,
    };

    const authService = new AuthService();
    const s3 = await authService.getS3();
    await s3
      .upload(params, function (err, data) {
        if (err) {
          console.log('An error occurred while completing the upload', err);
        } else {
          console.log('Final upload data:', data);
        }
      })
      .promise();
  }
  async deleteEvent(event) {
    const result = await dbLambda({ operation: 'deleteEvent', id: event.id });
    return result;
  }
  async getObject(key, bucket, toast) {
    try {
      const options = {
        Bucket: bucket,
        Key: key,
        ResponseContentType: 'application/json',
        ResponseCacheControl: 'No-cache', // ensure json file is not cached
      };
      const authService = new AuthService();
      const s3 = await authService.getS3();
      const data = await s3.getObject(options).promise();
      const arr = data.Body;
      let str = '';
      for (let i = 0; i < arr.byteLength; i++) {
        str += String.fromCharCode(arr[i]);
      }
      return JSON.parse(str);
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `Can't read JSON stats file. Error message: ${e.message}`,
        life: 7000,
      });
      return null;
    }
  }

  handleCountryCodes = venues => {
    if (!venues) return [];
    const r = venues.map(venue => ({
      ...venue,
      country: countryList.find(obj => {
        return obj.name === venue.region;
      }),
    }));
    return r;
  };
  sortObjectsByName(arr) {
    arr.sort(function (a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return arr;
  }
}
