import React from 'react';
import '../App.css';
// import { THEME } from '../utils/constants';
// import { Button } from '@material-ui/core';

function LoginPage_2() {
  return (
    <div className={'loginPageCenter '}>
      <div className="loginPageLogoBlock">
        <img
          className={'loginPageSportfiveLogo '}
          alt="Logo"
          src="assets/layout/images/supponor-logo-layered.png"
        />
      </div>

      <p className="HubAlanyticsNReporting"> Supponor Hub </p>
      <hr className="loginPageLine" />
      <p className="loginPageDescription">Advertising Inventory Manager and Scheduler</p>
    </div>
  );
}
export default LoginPage_2;
