import React from 'react';
import { APP_VERSION } from './utilities/constants';

const AppFooter = () => {
  return (
    <div className="layout-footer">
      <div className="footer-logo-container">
        <img id="footer-logo" src="assets/layout/images/supponor-logo-layered.png" alt="Hub NHL" />
        <span className="copyright">&nbsp;&nbsp;&nbsp; Hub v{APP_VERSION}</span>
      </div>
      <span className="copyright">Copyright &#169; {new Date().getFullYear()} Supponor Oy</span>
    </div>
  );
};

export default AppFooter;
