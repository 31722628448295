import { getTimezoneOffset } from './functions';

export const APP_VERSION = '2.1.56';
export const APP_DOMAIN = 'nhl.supponor.com';
export const SHARE_DOMAIN = 'https://master.d3q4qnqvqne78h.amplifyapp.com';
export const ENVIRONMENT_NAME = 'production';
export const userRoles = [
  { name: 'Admin', code: 'admin' },
  { name: 'User', code: 'user' },
];
export const userScopes = [
  { name: 'Club', code: 'club' },
  { name: 'League', code: 'league' },
  { name: 'Root', code: 'root' },
];
export const HUB_TYPE = 'NHL';

export const BACKEND_LAMBDA_NODE20 = 'hub2-db-general-us-east';
export const BACKEND_LAMBDA_2 = 'hub2-db-oog'; //'hub2-db-2';
export const BACKEND_LAMBDA = 'hub2-db-general-us-east';
export const EXPORT_LAMBDA = 'hub2-export-handler-node-20';
export const SQS_LAMBDA = 'hub2-sqs';
export const STATS_LAMBDA = 'hub2-statistics-general';
export const STATS_LAMBDA_NODE20 = 'hub2-statistics-general-node-20';
export const TRANSCODE_LAMBDA = 'hub2-media-handler-lambda';

export const CLOUDFRONT_PREFIX = 'https://d33k61efjqbbsg.cloudfront.net/';
export const ASSET_PATH = 'nhl/ads/';
export const AUDIENCE_DATA_PATH = 'stats/audience-data/';
export const AUDIENCE_FILE_CA = 'ca.csv';
export const AUDIENCE_FILE_US = 'us.csv';

export const MEDIA_BUCKET = 'hub2-storage';
export const MVP_BUCKET = 'mvp-nhl';
export const STATS_BUCKET = 'analytics-app-videos';
export const SUPPONOR_NHL_BUCKET = 'supp-nhl-bucket1';

export const FEED_MAP = {
  'Away RSN': '0',
  'Away Share RSN': 'S',
  'Home RSN': '1',
  'US National': '2',
  'CA National': '3',
  'CA National (FR)': '4',
  'Away RSN (FR)': '5',
  'Home RSN (FR)': '6',
  'NORD FB': 'A',
  'DACH BT': 'B',
  'NOVA IP': 'C',
  CISSET: 'D',
};
export const SV_INTERVAL_INDEXES_2022 = [
  0, 6, 13, 20, 27, 34, 40, 46, 53, 60, 67, 74, 80, 86, 93, 100, 107, 114, 120, 130,
];
export const SV_INTERVAL_INDEXES_PLAYOFFS = [
  0, 6, 13, 20, 27, 34, 40, 46, 53, 60, 67, 74, 80, 86, 93, 100, 107, 114, 120, 126, 133, 140, 147,
  154,
];

// export const SV_INTERVAL_INDEXES = [
//      0,  6, 12, 18,  24,  30,  36,
//     40, 46, 52, 58,  64,  70,  76,
//     80, 86, 92, 98, 104, 110, 116,
//     120,
//     130,
//     131, 132, 133, 134, 135, 136,
//     137, 138, 139, 140, 141
// ];
export const SV_INTERVAL_INDEXES = [
  0, 6, 13, 20, 27, 34, 35, 40, 46, 53, 60, 67, 74, 75, 80, 86, 93, 100, 107, 114, 115, 120, 130,
  131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142,
];
// Note! These are not row indexes in the jexcel table;
// instead, these are imp array indexes i.e. [0, 39], [40, 79] ...
export const SV_INTERVAL_INDEXES_PLAYOFFS_2024 = [
  0, 6, 13, 20, 27, 34, 35, 40, 46, 53, 60, 67, 74, 75, 80, 86, 93, 100, 107, 114, 115, 120, 126,
  133, 140, 147, 154, 155, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173,
];
// these clubs can see media values in the reporting table
export const MEDIA_VALUE_CLUBS = [1, 7, 12, 31, 32];

export const LOS_FORMATS = [
  { code: 'COR', name: 'Cornerboard' },
  { code: 'DSH', name: 'Dasherboard' },
  { code: 'BEN', name: 'Player Bench' },
  { code: 'BTW', name: 'Between Benches' },
  { code: 'BOX', name: 'Penalty Box' },
  { code: 'CRI', name: 'Corner In-Ice' },
  { code: 'NEU', name: 'Neutral Zone In-Ice' },
  { code: 'CCI', name: 'Center Circle In-Ice' },
  { code: 'BNI', name: 'Behind the Net In-Ice' },
];

export const TVR_INVENTORY_FORMATS = [
  {
    name: 'TV Commercial',
    code: 'TVC',
    zones: ['60 Spot', '30 Spot', '15 Spot', '15 Double Box', '10 Spot'],
  },
  { name: 'TV Graphic', code: 'TVG', zones: ['10 Drop In', '10 Billboard'] },
  { name: 'Radio Commercial', code: 'RAD', zones: ['60 Spot', '30 Spot', '15 Spot'] },
];

export const MENU_ITEMS_GENERAL = [
  { label: 'Adverts', icon: 'pi pi-fw pi-images', to: '/adverts', id: 1 },
  { label: 'Advertisers', icon: 'pi pi-fw pi-bookmark', to: '/advertisers', id: 2 },
  { label: 'Analytics', icon: 'pi pi-fw pi-chart-bar', to: '/empty', id: 3 },
  { label: 'Competitions', icon: 'pi pi-fw pi-list', to: '/competitions', id: 4 },
  { label: 'Dashboard', icon: 'pi pi-fw pi-calendar-times', to: '/calendar', id: 5 },
  { label: 'Media', icon: 'pi pi-fw pi-image', to: '/media', id: 6 },
  { label: 'Reporting', icon: 'pi pi-fw pi-share-alt', to: '/reporting', id: 7 },
  { label: 'Sports', icon: 'pi pi-fw pi-clock', to: '/sports', id: 8 },
  { label: 'Templates', icon: 'pi pi-fw pi-book', to: '/templates', id: 9 },
  { label: 'Venues', icon: 'pi pi-fw pi-map', to: '/venues', id: 10 },
  { label: 'Users', icon: 'pi pi-fw pi-users', to: '/users', id: 11 },
  { label: 'Noticeboard', icon: 'pi pi-fw pi-external-link', to: '/empty', id: 12 },
  { label: 'Approvals', icon: 'pi pi-fw pi-thumbs-up', to: '/approvals', id: 13 },
];

export const MENU_ITEMS_NHL = [
  { label: 'Game Schedule', icon: 'pi pi-fw pi-calendar-times', to: '/calendar', id: 1 },
  { label: 'OOG Schedule', icon: 'pi pi-fw pi-calendar-times', to: '/out-of-game', id: 21 },
  { label: 'Advertisers', icon: 'pi pi-fw pi-bookmark', to: '/advertisers', id: 2 },
  { label: 'LOS Ads', icon: 'pi pi-fw pi-print', to: '/empty', id: 4 },
  { label: 'DED Ads', icon: 'pi pi-fw pi-images', to: '/adverts', id: 3 },
  { label: 'Slot Virtual Ads', icon: 'pi pi-fw pi-image', to: '/slot-virtual', id: 12 },
  { label: 'Glass Virtual Ads', icon: 'pi pi-fw pi-palette', to: '/glass-virtual', id: 23 },
  { label: 'TV/Radio Ads', icon: 'pi pi-fw pi-desktop', to: '/tv-radio', id: 24 },
  { label: 'Digital Ads', icon: 'pi pi-fw pi-tablet', to: '/digital', id: 25 },
  { label: 'Reporting', icon: 'pi pi-fw pi-chart-bar', to: '/reporting', id: 5 },
  { label: 'Guidelines & Specs', icon: 'pi pi-fw pi-list', to: '/guidelines', id: 13 },
  { label: 'FAQ', icon: 'pi pi-fw pi-question-circle', to: '/faq', id: 15 },
  { label: 'Contacts', icon: 'pi pi-fw pi-info-circle', to: '/contacts', id: 14 },
  { label: 'Seasons', icon: 'pi pi-fw pi-list', to: '/competitions', id: 6 },
  { label: 'Templates', icon: 'pi pi-fw pi-book', to: '/templates', id: 7 },
  { label: 'Venues', icon: 'pi pi-fw pi-map', to: '/venues', id: 8 },
  { label: 'Users', icon: 'pi pi-fw pi-users', to: '/users', id: 9 },
  { label: 'Noticeboard', icon: 'pi pi-fw pi-external-link', to: '/empty', id: 10 },
  { label: 'Audience Data', icon: 'pi pi-fw pi-chart-bar', to: '/audience', id: 16 },
  { label: 'Impressions', icon: 'pi pi-fw pi-chart-bar', to: '/impressions', id: 26 },
  { label: 'Approvals', icon: 'pi pi-fw pi-thumbs-up', to: '/approvals', id: 11 },
];

export const MENU_ITEMS = HUB_TYPE === 'NHL' ? MENU_ITEMS_NHL : MENU_ITEMS_GENERAL;

export const CLUBS = [
  {
    id: 1,
    name: 'National Hockey League',
    code: 'NHL',
    opt_out: true,
    share: 'min',
    country: 'US',
  },
  { id: 2, name: 'Anaheim Ducks', code: 'ANA', opt_out: true, share: 'max', country: 'US' },
  { id: 3, name: 'Arizona Coyotes', code: 'ARI', opt_out: false, share: 'max', country: 'US' },
  { id: 4, name: 'Boston Bruins', code: 'BOS', opt_out: true, share: 'min', country: 'US' },
  { id: 5, name: 'Buffalo Sabres', code: 'BUF', opt_out: true, share: 'no', country: 'US' },
  { id: 6, name: 'Calgary Flames', code: 'CGY', opt_out: true, share: 'no', country: 'CA' },
  { id: 7, name: 'Carolina Hurricanes', code: 'CAR', opt_out: false, share: 'max', country: 'US' },
  { id: 8, name: 'Chicago Blackhawks', code: 'CHI', opt_out: true, share: 'min', country: 'US' },
  { id: 9, name: 'Colorado Avalanche', code: 'COL', opt_out: false, share: 'max', country: 'US' },
  {
    id: 10,
    name: 'Columbus Blue Jackets',
    code: 'CBJ',
    opt_out: false,
    share: 'max',
    country: 'US',
  },
  { id: 11, name: 'Dallas Stars', code: 'DAL', opt_out: false, share: 'min', country: 'US' },
  { id: 12, name: 'Detroit Red Wings', code: 'DET', opt_out: false, share: 'min', country: 'US' },
  { id: 13, name: 'Edmonton Oilers', code: 'EDM', opt_out: true, share: 'no', country: 'CA' },
  { id: 14, name: 'Florida Panthers', code: 'FLA', opt_out: true, share: 'max', country: 'US' },
  { id: 15, name: 'Los Angeles Kings', code: 'LAK', opt_out: true, share: 'min', country: 'US' },
  { id: 16, name: 'Minnesota Wild', code: 'MIN', opt_out: false, share: 'min', country: 'US' },
  { id: 17, name: 'Montreal Canadiens', code: 'MTL', opt_out: true, share: 'no', country: 'CA' },
  { id: 18, name: 'Nashville Predators', code: 'NSH', opt_out: false, share: 'max', country: 'US' },
  { id: 19, name: 'New Jersey Devils', code: 'NJD', opt_out: false, share: 'min', country: 'US' },
  { id: 20, name: 'New York Islanders', code: 'NYI', opt_out: false, share: 'max', country: 'US' },
  { id: 21, name: 'New York Rangers', code: 'NYR', opt_out: true, share: 'no', country: 'US' },
  { id: 22, name: 'Ottawa Senators', code: 'OTT', opt_out: true, share: 'no', country: 'CA' },
  { id: 23, name: 'Philadelphia Flyers', code: 'PHI', opt_out: true, share: 'min', country: 'US' },
  { id: 24, name: 'Pittsburgh Penguins', code: 'PIT', opt_out: false, share: 'max', country: 'US' },
  { id: 25, name: 'San Jose Sharks', code: 'SJS', opt_out: false, share: 'min', country: 'US' },
  { id: 26, name: 'Seattle Kraken', code: 'SEA', opt_out: true, share: 'min', country: 'US' },
  { id: 27, name: 'St Louis Blues', code: 'STL', opt_out: false, share: 'max', country: 'US' },
  { id: 28, name: 'Tampa Bay Lightning', code: 'TBL', opt_out: false, share: 'max', country: 'US' },
  { id: 29, name: 'Toronto Maple Leafs', code: 'TOR', opt_out: true, share: 'no', country: 'CA' },
  { id: 34, name: 'Utah Hockey Club', code: 'UTA', opt_out: false, share: 'max', country: 'US' },
  { id: 30, name: 'Vancouver Canucks', code: 'VAN', opt_out: true, share: 'no', country: 'CA' },
  {
    id: 31,
    name: 'Vegas Golden Knights',
    code: 'VGK',
    opt_out: false,
    share: 'max',
    country: 'US',
  },
  { id: 32, name: 'Washington Capitals', code: 'WSH', opt_out: true, share: 'max', country: 'US' },
  { id: 33, name: 'Winnipeg Jets', code: 'WPG', opt_out: false, share: 'max', country: 'CA' },
];

// clubs with access to GV (club IDs)
export const GV_CLUBS = CLUBS.map(club => club.id);

// GV is enabled only for events within these competitions
export const GV_COMPETITIONS = [3, 4, 5, 7, 11];

export const NETWORKS = [
  { code: 'ABC', name: 'ABC', isUS: true, isNational: true, isEnglish: true },
  { code: 'ESPN', name: 'ESPN', isUS: true, isNational: true, isEnglish: true },
  { code: 'ESPN2', name: 'ESPN2', isUS: true, isNational: true, isEnglish: true },
  { code: 'ESPN+', name: 'ESPN+', isUS: true, isNational: true, isEnglish: true },
  { code: 'HULU', name: 'Hulu', isUS: true, isNational: true, isEnglish: true },
  { code: 'TNT', name: 'TNT', isUS: true, isNational: true, isEnglish: true },
  { code: 'NHLN', name: 'NHL Network', isUS: true, isNational: true, isEnglish: true },
  { code: 'ALT', name: 'Altitude', isUS: true, isNational: false, isEnglish: true },
  { code: 'ALT2', name: 'Altitude 2', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'ATTSN-PT',
    name: 'AT&T SportsNet Pittsburgh',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'ATTSN-RM',
    name: 'AT&T SportsNet Rocky Mountain',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSAZ', name: 'Bally Sports Arizona', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSAZX',
    name: 'Bally Sports Arizona Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'BSAZX 2',
    name: 'Bally Sports Arizona Extra 2',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSDET', name: 'Bally Sports Detroit', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSDETX',
    name: 'Bally Sports Detroit Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSFL', name: 'Bally Sports Florida', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSFLX',
    name: 'Bally Sports Florida Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSMW', name: 'Bally Sports Midwest', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSMWX',
    name: 'Bally Sports Midwest Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSN', name: 'Bally Sports North', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSNX',
    name: 'Bally Sports North Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSOH', name: 'Bally Sports Ohio', isUS: true, isNational: false, isEnglish: true },
  { code: 'BSSC', name: 'Bally Sports SoCal', isUS: true, isNational: false, isEnglish: true },
  { code: 'BSSD', name: 'Bally Sports San Diego', isUS: true, isNational: false, isEnglish: true },
  { code: 'BSSO', name: 'Bally Sports South', isUS: true, isNational: false, isEnglish: true },
  { code: 'BSSUN', name: 'Bally Sports Sun', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSSUNX',
    name: 'Bally Sports Sun Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSSW', name: 'Bally Sports Southwest', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSSWX',
    name: 'Bally Sports Southwest Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'BSW', name: 'Bally Sports West', isUS: true, isNational: false, isEnglish: true },
  { code: 'BSWI', name: 'Bally Sports Wisconsin', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'BSWIX',
    name: 'Bally Sports Wisconsin Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'CHSN', name: 'Chicago Sports Network', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'CHSN+',
    name: 'Chicago Sports Network Plus',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'FX-CA', name: 'FX-CA', isUS: false, isNational: true, isEnglish: true },
  { code: 'KCAL', name: 'KCAL', isUS: true, isNational: false, isEnglish: true },
  { code: 'KCOP-13', name: 'KCOP', isUS: true, isNational: false, isEnglish: true },
  { code: 'KHN', name: 'KHN', isUS: true, isNational: false, isEnglish: true },
  { code: 'KING 5', name: 'KING 5', isUS: true, isNational: false, isEnglish: true },
  { code: 'KONG', name: 'KONG', isUS: true, isNational: false, isEnglish: true },
  { code: 'KTXA', name: 'KTXA', isUS: true, isNational: false, isEnglish: true },
  { code: 'KTNV', name: 'KTNV', isUS: true, isNational: false, isEnglish: true },
  { code: 'KTVD', name: 'KTVD', isUS: true, isNational: false, isEnglish: true },
  { code: 'KMOV-TV', name: 'KMOV-TV', isUS: true, isNational: false, isEnglish: true },
  { code: 'KUSA', name: 'KUSA', isUS: true, isNational: false, isEnglish: true },
  { code: 'Matrix-MW', name: 'Matrix-MW', isUS: true, isNational: false, isEnglish: true },
  { code: 'MAX', name: 'TNT/TBS and HBO Max', isUS: true, isNational: true, isEnglish: true },
  {
    code: 'MNMT',
    name: 'Monumental Sports Network',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'MNMT2',
    name: 'Monumental Sports Network 2',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'MSG', name: 'MSG', isUS: true, isNational: false, isEnglish: true },
  { code: 'MSG+', name: 'MSG+', isUS: true, isNational: false, isEnglish: true },
  { code: 'MSG+ 2', name: 'MSG+ 2', isUS: true, isNational: false, isEnglish: true },
  { code: 'MSG 2', name: 'MSG 2', isUS: true, isNational: false, isEnglish: true },
  { code: 'MSG-B', name: 'MSG Buffalo', isUS: true, isNational: false, isEnglish: true },
  { code: 'MSGSN', name: 'MSG Sportsnet', isUS: true, isNational: false, isEnglish: true },
  { code: 'MSGSN2', name: 'MSG Sportsnet 2', isUS: true, isNational: false, isEnglish: true },
  { code: 'NBCSCA', name: 'NBC Sports California', isUS: true, isNational: false, isEnglish: true },
  { code: 'NBCSCA+', name: 'NBCSCA+', isUS: true, isNational: false, isEnglish: true },
  { code: 'NBCSCH', name: 'NBC Sports Chicago', isUS: true, isNational: false, isEnglish: true },
  { code: 'NBCSCH+', name: 'NBC Sports Chicago+', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'NBCSCH+ 2',
    name: 'NBC Sports Chicago+ 2',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'NBCSP',
    name: 'NBC Sports Philadelphia',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'NBCSP+',
    name: 'NBC Sports Philadelphia+',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'NBCSWA', name: 'NBC Sports Washington', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'NBCSWA+',
    name: 'NBC Sports Washington+',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'NESN', name: 'NESN', isUS: true, isNational: false, isEnglish: true },
  { code: 'NESNplus', name: 'NESN+', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'ROOT-NW',
    name: 'Root Sports Northwest',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'ROOT-NW+',
    name: 'Root Sports Northwest+',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'CBC', name: 'CBC', isUS: false, isNational: true, isEnglish: true },
  { code: 'CITY', name: 'Citytv', isUS: false, isNational: true, isEnglish: true },
  { code: 'HNIC', name: 'HNIC', isUS: false, isNational: true, isEnglish: true },
  { code: 'SN', name: 'Sportsnet', isUS: false, isNational: true, isEnglish: true },
  { code: 'SN1', name: 'Sportsnet One', isUS: false, isNational: true, isEnglish: true },
  { code: 'SN360', name: 'Sportsnet 360', isUS: false, isNational: true, isEnglish: true },
  { code: 'truTV', name: 'truTV', isUS: true, isNational: true, isEnglish: true },
  { code: 'TVAS', name: 'TVA Sports', isUS: false, isNational: true, isEnglish: false },
  { code: 'TVAS2', name: 'TVA Sports 2', isUS: false, isNational: true, isEnglish: false },
  { code: 'TVAS-D', name: 'TVA Sports D', isUS: false, isNational: true, isEnglish: false },
  { code: 'TVAS (JIP)', name: 'TVA Sports (JIP)', isUS: false, isNational: true, isEnglish: false },
  { code: 'TBS', name: 'TBS', isUS: true, isNational: true, isEnglish: true },
  { code: 'Prime', name: 'Prime', isUS: false, isNational: true, isEnglish: true },
  { code: 'RDS', name: 'RDS', isUS: false, isNational: false, isEnglish: false },
  { code: 'RDS2', name: 'RDS2', isUS: false, isNational: false, isEnglish: false },
  { code: 'RDSI', name: 'RDSI', isUS: false, isNational: false, isEnglish: false },
  { code: 'SCRIPPS', name: 'SCRIPPS', isUS: true, isNational: false, isEnglish: true },
  { code: 'SNE', name: 'Sportsnet East', isUS: false, isNational: false, isEnglish: true },
  { code: 'SNF', name: 'Sportsnet Flames', isUS: false, isNational: false, isEnglish: true },
  { code: 'SNO', name: 'Sportsnet Ontario', isUS: false, isNational: false, isEnglish: true },
  { code: 'SNOL', name: 'Sportsnet Oilers', isUS: false, isNational: false, isEnglish: true },
  { code: 'SNP', name: 'Sportsnet Pacific', isUS: false, isNational: false, isEnglish: true },
  { code: 'SN-PIT', name: 'Sportnet Pittsburgh', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'SN-PIT+',
    name: 'Sportnet Pittsburgh Plus',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  { code: 'SNW', name: 'Sportsnet West', isUS: false, isNational: false, isEnglish: true },
  { code: 'SN (JIP)', name: 'SN (JIP)', isUS: false, isNational: true, isEnglish: true },
  { code: 'TSN2', name: 'TSN2', isUS: false, isNational: false, isEnglish: true },
  { code: 'TSN3', name: 'TSN3', isUS: false, isNational: false, isEnglish: true },
  { code: 'TSN4', name: 'TSN4', isUS: false, isNational: false, isEnglish: true },
  { code: 'TSN5', name: 'TSN5', isUS: false, isNational: false, isEnglish: true },
  { code: 'TXA 21', name: 'TXA 21', isUS: true, isNational: false, isEnglish: true },
  { code: 'Utah16', name: 'Utah16', isUS: true, isNational: false, isEnglish: true },
  { code: 'Victory+', name: 'Victory+', isUS: true, isNational: false, isEnglish: true },
  {
    code: 'FDSNSO',
    name: 'FanDuel Sports Network South',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNOH',
    name: 'FanDuel Sports Network Ohio',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNDET',
    name: 'FanDuel Sports Network Detroit',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNDETX',
    name: 'FanDuel Sports Network Detroit Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNW',
    name: 'FanDuel Sports Network West',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNNO',
    name: 'FanDuel Sports Network North',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNNOX',
    name: 'FanDuel Sports Network North Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNWI',
    name: 'FanDuel Sports Network Wisconsin',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNWIX',
    name: 'FanDuel Sports Network Wisconsin Extra',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNSO',
    name: 'FanDuel Sports Network South',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNMW',
    name: 'FanDuel Sports Network Midwest',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  {
    code: 'FDSNSUN',
    name: 'FanDuel Sports Network Sun',
    isUS: true,
    isNational: false,
    isEnglish: true,
  },
  // international networks
  {
    code: 'ADJ',
    name: 'Adjarasport',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'DA-CZ',
    name: 'Dajtko-CZ',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'DA-SK',
    name: 'Dajtko-SK',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  { code: 'LAOLA', name: 'LAOLA', intl: true, isUS: false, isNational: false, isEnglish: false },
  {
    code: 'MA-CZ',
    name: 'Markiza-CZ',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'MA-SK',
    name: 'Markiza-SK',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  { code: 'MTV3', name: 'MTV3', intl: true, isUS: false, isNational: false, isEnglish: false },
  { code: 'N4', name: 'Network 4', intl: true, isUS: false, isNational: false, isEnglish: false },
  { code: 'NOVA', name: 'Nova', intl: true, isUS: false, isNational: false, isEnglish: false },
  { code: 'PRO 7', name: 'PRO 7', intl: true, isUS: false, isNational: false, isEnglish: false },
  { code: 'S TV', name: 'Sport TV', intl: true, isUS: false, isNational: false, isEnglish: false },
  {
    code: 'S-UA',
    name: 'Setanta - UA',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'SKY D',
    name: 'Sky Deutschland',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'SKY I',
    name: 'Sky Italia',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  { code: 'TV10', name: 'TV10', intl: true, isUS: false, isNational: false, isEnglish: false },
  {
    code: 'UPC',
    name: 'UPC Sunrise',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'VI-FI',
    name: 'Viaplay - FI',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'VI-NO',
    name: 'Viaplay - NO',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
  {
    code: 'VI-SE',
    name: 'Viaplay - SE',
    intl: true,
    isUS: false,
    isNational: false,
    isEnglish: false,
  },
];

const ALL_INTERVAL_INDEXES = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
  53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
  77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
  121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 140, 141, 142,
  144, 145, 146, 147, 148, 149,
];
const ALL_INTERVAL_INDEXES_2425 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
  53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
  77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
  121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 140, 141, 142,
  143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 160, 161, 162,
  163, 164, 165, 166, 167, 168, 169, 170,
];
const ALL_INTERVAL_INDEXES_PLAYOFF = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
  53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
  77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
  102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
  121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
  141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159,
  160, 161, 162, 163, 165, 166, 167, 168, 169, 170, 171, 173, 174, 175, 176, 177, 178, 179,
];
const ALL_SV_INTERVAL_INDEXES = [
  1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 22, 24,
];
const ALL_SV_INTERVAL_INDEXES_PLAYOFFS = [
  1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27,
];
export const SHARE_MODELS = {
  // playoffs DED
  'SC-A-1': {
    nhl: [16, 57, 98],
    home: ALL_INTERVAL_INDEXES_PLAYOFF,
    away: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 99, 100,
      101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139,
      140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158,
      159, 160, 161, 162, 163,
    ],
  },
  'SC-H-1': {
    nhl: [16, 57, 98],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 99, 100,
      101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139,
      140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158,
      159, 160, 161, 162, 163,
    ],
    away: ALL_INTERVAL_INDEXES_PLAYOFF,
  },
  'SC-USA-N-1': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES_PLAYOFF,
    away: ALL_INTERVAL_INDEXES_PLAYOFF,
  },
  'SC-CDN-N-1': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES_PLAYOFF,
    away: ALL_INTERVAL_INDEXES_PLAYOFF,
  },
  // CA team plays at home
  'SC-CDN-N-2': {
    nhl: [
      5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 58, 59,
      60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
      97, 115, 116, 117, 118, 119, 120, 121, 122, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137,
      138, 139, 156, 157, 158, 159, 160, 161, 162, 163,
    ],
    home: [
      1, 2, 3, 4, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 46, 47, 48,
      49, 50, 51, 52, 53, 54, 55, 56, 57, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 98,
      99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 124, 125, 126,
      127, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 165, 166,
      167, 168, 169, 170, 171, 173, 174, 175, 176, 177, 178, 179,
    ],
    away: ALL_INTERVAL_INDEXES_PLAYOFF,
  },
  'SC-INT-1': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES_PLAYOFF,
    away: ALL_INTERVAL_INDEXES_PLAYOFF,
  },
  // regular season DED
  'RS-H-1': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    home: [32, 73, 114],
    away: ALL_INTERVAL_INDEXES,
  },
  // NHL: 32
  // away: all except for 32
  // home: all RO
  'RS-A-1': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    home: ALL_INTERVAL_INDEXES,
    away: [32, 73, 114],
  },
  // NHL: 32
  // away: [1-16], [27-31], [33-40]
  // home: 17-26
  'RS-A-2': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
      37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 68, 69, 70,
      71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94,
      95, 96, 97, 98, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124,
      125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    away: [
      17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 32, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 73, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 114,
    ],
  },
  // NHL: 32
  // away: [1-16], [23-31], [33-40]
  // home: 17-22
  'RS-A-3': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
      33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86,
      87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 105, 106, 107, 108, 109, 110, 111, 112, 113,
      114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133,
      135,
    ],
    away: [
      17, 18, 19, 20, 21, 22, 32, 58, 59, 60, 61, 62, 63, 73, 99, 100, 101, 102, 103, 104, 114,
    ],
  },
  // NHL: 32
  // away: [1-16], [21-31], [33-40]
  // home: 17-20
  'RS-A-4': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135,
    ],
    away: [17, 18, 19, 20, 32, 58, 59, 60, 61, 73, 99, 100, 101, 102, 114],
  },

  // NHL: [8-16], [32-40]
  // home: [1-7], [17-31]
  'RS-USA-N-1': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 42, 43, 44,
      45, 46, 47, 48, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 83, 84, 85, 86,
      87, 88, 89, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113,
    ],
    home: [
      8, 9, 10, 11, 12, 13, 14, 15, 16, 32, 33, 34, 35, 36, 37, 38, 39, 40, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 73, 74, 75, 76, 77, 78, 79, 80, 81, 90, 91, 92, 93, 94, 95, 96, 97, 98, 114, 115,
      116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137,
      138, 139, 140, 141, 142, 144, 145, 146, 147, 148, 149,
    ],
    away: ALL_INTERVAL_INDEXES,
  },
  // NHL: all
  'RS-USA-N-2': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES,
    away: ALL_INTERVAL_INDEXES,
  },

  // NHL: [1-16], [21-40]
  // home: [17-20]
  'RS-USA-N-3': {
    nhl: [17, 18, 19, 20, 58, 59, 60, 61, 99, 100, 101, 102],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135, 137, 138, 139, 140, 141, 142, 144, 145, 146, 147, 148, 149,
    ],
    away: ALL_INTERVAL_INDEXES,
  },

  // NHL: 16, 32
  // home: all except for 16, 32
  // away: all RO
  'RS-CDN-N-4': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
      53, 54, 55, 56, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77,
      78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 99, 100, 101, 102,
      103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121, 122,
      124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    home: [16, 32, 57, 73, 98, 114, 137, 138, 139, 140, 141, 142, 144, 145, 146, 147, 148, 149],
    away: ALL_INTERVAL_INDEXES,
  },

  // same as RS-USA-N-2
  'RS-CDN-N-5': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES,
    away: ALL_INTERVAL_INDEXES,
  },

  // same as RS-USA-N-3
  'RS-CDN-N-6': {
    nhl: [17, 18, 19, 20, 58, 59, 60, 61, 99, 100, 101, 102],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135, 137, 138, 139, 140, 141, 142, 144, 145, 146, 147, 148, 149,
    ],
    // all RO
    away: ALL_INTERVAL_INDEXES,
  },
  'RS-INT-1': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES,
    away: ALL_INTERVAL_INDEXES,
  },
  // playoffs SV
  SC_LOC_H: {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27],
    home: [1, 8, 15],
    away: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
  },
  SC_LOC_A: {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27],
    home: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
    away: [1, 8, 15],
  },
  SC_NAT: {
    nhl: [],
    away: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
    home: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
  },
  SC_INT: {
    nhl: [],
    away: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
    home: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
  },
  // regular season SV
  RS_LOC_H: {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 24],
    home: [1, 8, 15],
    away: ALL_SV_INTERVAL_INDEXES,
  },
  RS_LOC_A: {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 24],
    away: [1, 8, 15],
    home: ALL_SV_INTERVAL_INDEXES,
  },
  RS_NAT: {
    nhl: [],
    away: ALL_SV_INTERVAL_INDEXES,
    home: ALL_SV_INTERVAL_INDEXES,
  },
  // a special share model: CA National feed for Ottawa home games
  RS_NAT_CA_OTT: {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 24],
    home: [1, 8, 15],
    away: ALL_SV_INTERVAL_INDEXES,
  },
  RS_INT: {
    nhl: [],
    away: ALL_SV_INTERVAL_INDEXES,
    home: ALL_SV_INTERVAL_INDEXES,
  },
};
export const SHARE_MODELS_2425 = {
  // regular season DED
  'RS-H-1': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 143,
      146, 148,
    ],
    home: [32, 73, 114, 144],
    away: ALL_INTERVAL_INDEXES_2425,
  },
  // NHL: 32
  // away: all except for 32
  // home: all RO
  'RS-A-1': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 144,
      146, 148,
    ],
    home: ALL_INTERVAL_INDEXES_2425,
    away: [32, 73, 114, 143],
  },
  // NHL: 32
  // away: [1-16], [27-31], [33-40]
  // home: 17-26
  'RS-A-2': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 144,
      146, 148,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
      37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 68, 69, 70,
      71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94,
      95, 96, 97, 98, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124,
      125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 143, 144, 146, 148, 150,
      151, 152, 153, 154, 155, 156, 157, 158, 159, 160,
    ],
    away: [
      17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 32, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 73, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 114, 143, 150, 151, 152, 153, 154, 155, 156, 157,
      158, 159, 160,
    ],
  },
  // NHL: 32
  // away: [1-16], [23-31], [33-40]
  // home: 17-22
  'RS-A-3': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 144,
      146, 148,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
      33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 83, 84, 85, 86,
      87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 105, 106, 107, 108, 109, 110, 111, 112, 113,
      114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133,
      135, 137, 138, 139, 141, 143, 144, 146, 148,
    ],
    away: [
      17, 18, 19, 20, 21, 22, 32, 58, 59, 60, 61, 62, 63, 73, 99, 100, 101, 102, 103, 104, 114, 143,
    ],
  },
  // NHL: 32
  // away: [1-16], [21-31], [33-40]
  // home: 17-20
  'RS-A-4': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
      52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75,
      76, 77, 78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119,
      120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 144,
      146, 148,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 143, 144, 146, 148,
    ],
    away: [17, 18, 19, 20, 32, 58, 59, 60, 61, 73, 99, 100, 101, 102, 114, 143],
  },

  // NHL: [8-16], [32-40]
  // home: [1-7], [17-31]
  'RS-USA-N-1': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 42, 43, 44,
      45, 46, 47, 48, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 83, 84, 85, 86,
      87, 88, 89, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113,
    ],
    home: [
      8, 9, 10, 11, 12, 13, 14, 15, 16, 32, 33, 34, 35, 36, 37, 38, 39, 40, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 73, 74, 75, 76, 77, 78, 79, 80, 81, 90, 91, 92, 93, 94, 95, 96, 97, 98, 114, 115,
      116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 137,
      138, 139, 141, 143, 144, 146, 148, 150, 151, 152, 153, 154, 155, 156, 157, 158, 160, 161, 162,
      163, 164, 165, 166, 167, 168, 169, 170,
    ],
    away: ALL_INTERVAL_INDEXES_2425,
  },
  // NHL: all
  'RS-USA-N-2': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES_2425,
    away: ALL_INTERVAL_INDEXES_2425,
  },

  // NHL: [1-16], [21-40]
  // home: [17-20]
  'RS-USA-N-3': {
    nhl: [17, 18, 19, 20, 58, 59, 60, 61, 99, 100, 101, 102],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 143, 144, 146, 148, 150, 151, 152, 153, 154,
      155, 156, 157, 158, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
    ],
    away: ALL_INTERVAL_INDEXES_2425,
  },

  // NHL: 16, 32
  // home: all except for 16, 32
  // away: all RO
  'RS-CDN-N-4': {
    nhl: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
      53, 54, 55, 56, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 74, 75, 76, 77,
      78, 79, 80, 81, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 99, 100, 101, 102,
      103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 119, 120, 121, 122,
      124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135,
    ],
    home: [
      16, 32, 57, 73, 98, 114, 137, 138, 139, 141, 143, 144, 146, 148, 150, 151, 152, 153, 154, 155,
      156, 157, 158,
    ],
    away: ALL_INTERVAL_INDEXES_2425,
  },

  // same as RS-USA-N-2
  'RS-CDN-N-5': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES_2425,
    away: ALL_INTERVAL_INDEXES_2425,
  },

  // same as RS-USA-N-3
  'RS-CDN-N-6': {
    nhl: [17, 18, 19, 20, 58, 59, 60, 61, 99, 100, 101, 102],
    home: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
      55, 56, 57, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
      83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128,
      129, 130, 131, 132, 133, 135, 137, 138, 139, 141, 143, 144, 146, 148, 150, 151, 152, 153, 154,
      155, 156, 157, 158,
    ],
    away: ALL_INTERVAL_INDEXES_2425,
  },
  'RS-INT-1': {
    nhl: [],
    home: ALL_INTERVAL_INDEXES_2425,
    away: ALL_INTERVAL_INDEXES_2425,
  },
};
export const SV_SHARE_MODELS = {
  'SV-LOC-H': {
    nhl: [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
      61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 89, 90,
      91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
      111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130,
      131, 132, 133, 135,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 42, 43, 44, 45, 46, 47, 83, 84, 85, 86, 87, 88, 137, 138, 139, 140, 141,
      142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153,
    ],
    away: ALL_INTERVAL_INDEXES_2425,
  },
  'SV-LOC-A': {
    nhl: [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
      61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 89, 90,
      91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
      111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130,
      131, 132, 133, 135,
    ],
    home: ALL_INTERVAL_INDEXES_2425,
    away: [
      1, 2, 3, 4, 5, 6, 42, 43, 44, 45, 46, 47, 83, 84, 85, 86, 87, 88, 137, 138, 139, 140, 141,
      142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153,
    ],
  },
  'SV-NAT': {
    nhl: [],
    away: ALL_INTERVAL_INDEXES_2425,
    home: ALL_INTERVAL_INDEXES_2425,
  },
  // same as SV-LOC-H
  'SV-NAT-CA-OTT': {
    nhl: [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
      61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 89, 90,
      91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
      111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 124, 125, 126, 127, 128, 129, 130,
      131, 132, 133, 135,
    ],
    home: [
      1, 2, 3, 4, 5, 6, 42, 43, 44, 45, 46, 47, 83, 84, 85, 86, 87, 88, 137, 138, 139, 140, 141,
      142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153,
    ],
    away: ALL_INTERVAL_INDEXES_2425,
  },
  'SV-INT': {
    nhl: [],
    away: ALL_INTERVAL_INDEXES_2425,
    home: ALL_INTERVAL_INDEXES_2425,
  },
};

export const ET_OFFSET = getTimezoneOffset('America/New_York', new Date());
export const ET_OFFSET_MILLISECONDS = ET_OFFSET * 60 * 60 * 1000;
export const ZONE_AREA = {
  Z01: 729000,
  Z02: 702000,
  Z03: 540000,
  Z04: 702000,
  Z05: 729000,
  Z1A: 450000,
  Z1B: 279000,
  Z2A: 351000,
  Z2B: 351000,
  Z3A: 270000,
  Z3B: 270000,
  Z4B: 351000,
  Z4A: 351000,
  Z5B: 279000,
  Z5A: 450000,
};
export const timezoneList = [
  { name: 'Africa/Abidjan' },
  { name: 'Africa/Accra' },
  { name: 'Africa/Addis_Ababa' },
  { name: 'Africa/Algiers' },
  { name: 'Africa/Asmara' },
  { name: 'Africa/Bamako' },
  { name: 'Africa/Bissau' },
  { name: 'Africa/Blantyre' },
  { name: 'Africa/Brazzaville' },
  { name: 'Africa/Bujumbura' },
  { name: 'Africa/Cairo' },
  { name: 'Africa/Casablanca' },
  { name: 'Africa/Ceuta' },
  { name: 'Africa/Conakry' },
  { name: 'Africa/Dakar' },
  { name: 'Africa/Dar_es_Salaam' },
  { name: 'Africa/Djibouti' },
  { name: 'Africa/Douala' },
  { name: 'Africa/El_Aaiun' },
  { name: 'Africa/Freetown' },
  { name: 'Africa/Harare' },
  { name: 'Africa/Johannesburg' },
  { name: 'Africa/Juba' },
  { name: 'Africa/Kampala' },
  { name: 'Africa/Khartoum' },
  { name: 'Africa/Kigali' },
  { name: 'Africa/Kinshasa' },
  { name: 'Africa/Lagos' },
  { name: 'Africa/Libreville' },
  { name: 'Africa/Lome' },
  { name: 'Africa/Luanda' },
  { name: 'Africa/Lusaka' },
  { name: 'Africa/Malabo' },
  { name: 'Africa/Maputo' },
  { name: 'Africa/Mbabane' },
  { name: 'Africa/Mogadishu' },
  { name: 'Africa/Monrovia' },
  { name: 'Africa/Nairobi' },
  { name: 'Africa/Ndjamena' },
  { name: 'Africa/Ouagadougou' },
  { name: 'Africa/Porto-Novo' },
  { name: 'Africa/Sao_Tome' },
  { name: 'Africa/Tripoli' },
  { name: 'Africa/Tunis' },
  { name: 'Africa/Windhoek' },
  { name: 'America/Adak' },
  { name: 'America/Anchorage' },
  { name: 'America/Anguilla' },
  { name: 'America/Antigua' },
  { name: 'America/Araguaina' },
  { name: 'America/Argentina/Buenos_Aires' },
  { name: 'America/Argentina/Catamarca' },
  { name: 'America/Argentina/Cordoba' },
  { name: 'America/Argentina/Jujuy' },
  { name: 'America/Argentina/La_Rioja' },
  { name: 'America/Argentina/Mendoza' },
  { name: 'America/Argentina/Rio_Gallegos' },
  { name: 'America/Argentina/Salta' },
  { name: 'America/Argentina/San_Juan' },
  { name: 'America/Argentina/San_Luis' },
  { name: 'America/Argentina/Tucuman' },
  { name: 'America/Argentina/Ushuaia' },
  { name: 'America/Aruba' },
  { name: 'America/Asuncion' },
  { name: 'America/Bahia' },
  { name: 'America/Bahia_Banderas' },
  { name: 'America/Barbados' },
  { name: 'America/Belem' },
  { name: 'America/Belize' },
  { name: 'America/Blanc-Sablon' },
  { name: 'America/Boa_Vista' },
  { name: 'America/Bogota' },
  { name: 'America/Boise' },
  { name: 'America/Cambridge_Bay' },
  { name: 'America/Campo_Grande' },
  { name: 'America/Cancun' },
  { name: 'America/Caracas' },
  { name: 'America/Cayenne' },
  { name: 'America/Cayman' },
  { name: 'America/Chicago' },
  { name: 'America/Chihuahua' },
  { name: 'America/Costa_Rica' },
  { name: 'America/Creston' },
  { name: 'America/Curacao' },
  { name: 'America/Danmarkshavn' },
  { name: 'America/Dawson' },
  { name: 'America/Dawson_Creek' },
  { name: 'America/Denver' },
  { name: 'America/Detroit' },
  { name: 'America/Dominica' },
  { name: 'America/Edmonton' },
  { name: 'America/Eirunepe' },
  { name: 'America/El_Salvador' },
  { name: 'America/Fortaleza' },
  { name: 'America/Glace_Bay' },
  { name: 'America/Godthab' },
  { name: 'America/Goose_Bay' },
  { name: 'America/Grand_Turk' },
  { name: 'America/Grenada' },
  { name: 'America/Guadeloupe' },
  { name: 'America/Guatemala' },
  { name: 'America/Guayaquil' },
  { name: 'America/Guyana' },
  { name: 'America/Halifax' },
  { name: 'America/Havana' },
  { name: 'America/Hermosillo' },
  { name: 'America/Indiana/Indianapolis' },
  { name: 'America/Indiana/Knox' },
  { name: 'America/Indiana/Marengo' },
  { name: 'America/Indiana/Petersburg' },
  { name: 'America/Indiana/Tell_City' },
  { name: 'America/Indiana/Vevay' },
  { name: 'America/Indiana/Vincennes' },
  { name: 'America/Indiana/Winamac' },
  { name: 'America/Inuvik' },
  { name: 'America/Iqaluit' },
  { name: 'America/Jamaica' },
  { name: 'America/Juneau' },
  { name: 'America/Kentucky/Louisville' },
  { name: 'America/Kentucky/Monticello' },
  { name: 'America/Kralendijk' },
  { name: 'America/La_Paz' },
  { name: 'America/Lima' },
  { name: 'America/Los_Angeles' },
  { name: 'America/Lower_Princes' },
  { name: 'America/Maceio' },
  { name: 'America/Managua' },
  { name: 'America/Manaus' },
  { name: 'America/Marigot' },
  { name: 'America/Martinique' },
  { name: 'America/Matamoros' },
  { name: 'America/Mazatlan' },
  { name: 'America/Menominee' },
  { name: 'America/Merida' },
  { name: 'America/Metlakatla' },
  { name: 'America/Mexico_City' },
  { name: 'America/Miquelon' },
  { name: 'America/Moncton' },
  { name: 'America/Monterrey' },
  { name: 'America/Montevideo' },
  { name: 'America/Montreal' },
  { name: 'America/Montserrat' },
  { name: 'America/Nassau' },
  { name: 'America/New_York' },
  { name: 'America/Nipigon' },
  { name: 'America/Nome' },
  { name: 'America/Noronha' },
  { name: 'America/North_Dakota/Beulah' },
  { name: 'America/North_Dakota/Center' },
  { name: 'America/North_Dakota/New_Salem' },
  { name: 'America/Ojinaga' },
  { name: 'America/Panama' },
  { name: 'America/Paramaribo' },
  { name: 'America/Phoenix' },
  { name: 'America/Port-au-Prince' },
  { name: 'America/Port_of_Spain' },
  { name: 'America/Porto_Velho' },
  { name: 'America/Puerto_Rico' },
  { name: 'America/Rainy_River' },
  { name: 'America/Rankin_Inlet' },
  { name: 'America/Recife' },
  { name: 'America/Regina' },
  { name: 'America/Resolute' },
  { name: 'America/Rio_Branco' },
  { name: 'America/Santa_Isabel' },
  { name: 'America/Santarem' },
  { name: 'America/Santiago' },
  { name: 'America/Santo_Domingo' },
  { name: 'America/Sao_Paulo' },
  { name: 'America/Scoresbysund' },
  { name: 'America/Shiprock' },
  { name: 'America/Sitka' },
  { name: 'America/St_Barthelemy' },
  { name: 'America/St_Johns' },
  { name: 'America/St_Kitts' },
  { name: 'America/St_Lucia' },
  { name: 'America/St_Thomas' },
  { name: 'America/St_Vincent' },
  { name: 'America/Swift_Current' },
  { name: 'America/Tegucigalpa' },
  { name: 'America/Thule' },
  { name: 'America/Thunder_Bay' },
  { name: 'America/Tijuana' },
  { name: 'America/Toronto' },
  { name: 'America/Tortola' },
  { name: 'America/Vancouver' },
  { name: 'America/Whitehorse' },
  { name: 'America/Winnipeg' },
  { name: 'America/Yakutat' },
  { name: 'America/Yellowknife' },
  { name: 'Asia/Aden' },
  { name: 'Asia/Almaty' },
  { name: 'Asia/Amman' },
  { name: 'Asia/Anadyr' },
  { name: 'Asia/Aqtau' },
  { name: 'Asia/Aqtobe' },
  { name: 'Asia/Ashgabat' },
  { name: 'Asia/Baghdad' },
  { name: 'Asia/Bahrain' },
  { name: 'Asia/Baku' },
  { name: 'Asia/Bangkok' },
  { name: 'Asia/Beirut' },
  { name: 'Asia/Bishkek' },
  { name: 'Asia/Brunei' },
  { name: 'Asia/Colombo' },
  { name: 'Asia/Damascus' },
  { name: 'Asia/Dhaka' },
  { name: 'Asia/Dili' },
  { name: 'Asia/Dubai' },
  { name: 'Asia/Dushanbe' },
  { name: 'Asia/Gaza' },
  { name: 'Asia/Harbin' },
  { name: 'Asia/Hebron' },
  { name: 'Asia/Ho_Chi_Minh' },
  { name: 'Asia/Hong_Kong' },
  { name: 'Asia/Hovd' },
  { name: 'Asia/Jakarta' },
  { name: 'Asia/Jayapura' },
  { name: 'Asia/Jerusalem' },
  { name: 'Asia/Kabul' },
  { name: 'Asia/Kamchatka' },
  { name: 'Asia/Karachi' },
  { name: 'Asia/Kashgar' },
  { name: 'Asia/Kathmandu' },
  { name: 'Asia/Khandyga' },
  { name: 'Asia/Kolkata' },
  { name: 'Asia/Krasnoyarsk' },
  { name: 'Asia/Kuala_Lumpur' },
  { name: 'Asia/Kuching' },
  { name: 'Asia/Kuwait' },
  { name: 'Asia/Macau' },
  { name: 'Asia/Magadan' },
  { name: 'Asia/Makassar' },
  { name: 'Asia/Manila' },
  { name: 'Asia/Muscat' },
  { name: 'Asia/Nicosia' },
  { name: 'Asia/Novokuznetsk' },
  { name: 'Asia/Novosibirsk' },
  { name: 'Asia/Omsk' },
  { name: 'Asia/Oral' },
  { name: 'Asia/Phnom_Penh' },
  { name: 'Asia/Pontianak' },
  { name: 'Asia/Pyongyang' },
  { name: 'Asia/Qatar' },
  { name: 'Asia/Qyzylorda' },
  { name: 'Asia/Rangoon' },
  { name: 'Asia/Riyadh' },
  { name: 'Asia/Sakhalin' },
  { name: 'Asia/Samarkand' },
  { name: 'Asia/Seoul' },
  { name: 'Asia/Shanghai' },
  { name: 'Asia/Singapore' },
  { name: 'Asia/Taipei' },
  { name: 'Asia/Tashkent' },
  { name: 'Asia/Tbilisi' },
  { name: 'Asia/Tehran' },
  { name: 'Asia/Thimphu' },
  { name: 'Asia/Tokyo' },
  { name: 'Asia/Ulaanbaatar' },
  { name: 'Asia/Urumqi' },
  { name: 'Asia/Ust-Nera' },
  { name: 'Asia/Vientiane' },
  { name: 'Asia/Vladivostok' },
  { name: 'Asia/Yekaterinburg' },
  { name: 'Asia/Yerevan' },
  { name: 'Atlantic/Azores' },
  { name: 'Atlantic/Bermuda' },
  { name: 'Atlantic/Canary' },
  { name: 'Atlantic/Cape_Verde' },
  { name: 'Atlantic/Faroe' },
  { name: 'Atlantic/Madeira' },
  { name: 'Atlantic/Reykjavik' },
  { name: 'Atlantic/South_Georgia' },
  { name: 'Atlantic/St_Helena' },
  { name: 'Atlantic/Stanley' },
  { name: 'Australia/Adelaide' },
  { name: 'Australia/Brisbane' },
  { name: 'Australia/Broken_Hill' },
  { name: 'Australia/Currie' },
  { name: 'Australia/Darwin' },
  { name: 'Australia/Eucla' },
  { name: 'Australia/Hobart' },
  { name: 'Australia/Lindeman' },
  { name: 'Australia/Lord_Howe' },
  { name: 'Australia/Melbourne' },
  { name: 'Australia/Perth' },
  { name: 'Australia/Sydney' },
  { name: 'Canada/Atlantic' },
  { name: 'Canada/Central' },
  { name: 'Canada/Eastern' },
  { name: 'Canada/Mountain' },
  { name: 'Canada/Newfoundland' },
  { name: 'Canada/Pacific' },
  { name: 'Europe/Amsterdam' },
  { name: 'Europe/Andorra' },
  { name: 'Europe/Athens' },
  { name: 'Europe/Belgrade' },
  { name: 'Europe/Berlin' },
  { name: 'Europe/Bratislava' },
  { name: 'Europe/Brussels' },
  { name: 'Europe/Bucharest' },
  { name: 'Europe/Budapest' },
  { name: 'Europe/Busingen' },
  { name: 'Europe/Chisinau' },
  { name: 'Europe/Copenhagen' },
  { name: 'Europe/Dublin' },
  { name: 'Europe/Gibraltar' },
  { name: 'Europe/Guernsey' },
  { name: 'Europe/Helsinki' },
  { name: 'Europe/Isle_of_Man' },
  { name: 'Europe/Istanbul' },
  { name: 'Europe/Jersey' },
  { name: 'Europe/Kaliningrad' },
  { name: 'Europe/Kiev' },
  { name: 'Europe/Lisbon' },
  { name: 'Europe/Ljubljana' },
  { name: 'Europe/London' },
  { name: 'Europe/Luxembourg' },
  { name: 'Europe/Madrid' },
  { name: 'Europe/Malta' },
  { name: 'Europe/Mariehamn' },
  { name: 'Europe/Minsk' },
  { name: 'Europe/Monaco' },
  { name: 'Europe/Moscow' },
  { name: 'Europe/Oslo' },
  { name: 'Europe/Paris' },
  { name: 'Europe/Podgorica' },
  { name: 'Europe/Prague' },
  { name: 'Europe/Riga' },
  { name: 'Europe/Rome' },
  { name: 'Europe/Samara' },
  { name: 'Europe/San_Marino' },
  { name: 'Europe/Sarajevo' },
  { name: 'Europe/Simferopol' },
  { name: 'Europe/Skopje' },
  { name: 'Europe/Sofia' },
  { name: 'Europe/Stockholm' },
  { name: 'Europe/Tallinn' },
  { name: 'Europe/Tirane' },
  { name: 'Europe/Uzhgorod' },
  { name: 'Europe/Vaduz' },
  { name: 'Europe/Vatican' },
  { name: 'Europe/Vienna' },
  { name: 'Europe/Vilnius' },
  { name: 'Europe/Volgograd' },
  { name: 'Europe/Warsaw' },
  { name: 'Europe/Zagreb' },
  { name: 'Europe/Zaporozhye' },
  { name: 'Europe/Zurich' },
  { name: 'GMT' },
  { name: 'Indian/Antananarivo' },
  { name: 'Indian/Chagos' },
  { name: 'Indian/Christmas' },
  { name: 'Indian/Cocos' },
  { name: 'Indian/Comoro' },
  { name: 'Indian/Kerguelen' },
  { name: 'Indian/Mahe' },
  { name: 'Indian/Maldives' },
  { name: 'Indian/Mauritius' },
  { name: 'Indian/Mayotte' },
  { name: 'Indian/Reunion' },
  { name: 'Pacific/Apia' },
  { name: 'Pacific/Auckland' },
  { name: 'Pacific/Chatham' },
  { name: 'Pacific/Chuuk' },
  { name: 'Pacific/Easter' },
  { name: 'Pacific/Efate' },
  { name: 'Pacific/Enderbury' },
  { name: 'Pacific/Fakaofo' },
  { name: 'Pacific/Fiji' },
  { name: 'Pacific/Galapagos' },
  { name: 'Pacific/Gambier' },
  { name: 'Pacific/Guadalcanal' },
  { name: 'Pacific/Guam' },
  { name: 'Pacific/Honolulu' },
  { name: 'Pacific/Palau' },
  { name: 'Pacific/Pitcairn' },
  { name: 'Pacific/Pohnpei' },
  { name: 'Pacific/Port_Moresby' },
  { name: 'Pacific/Rarotonga' },
  { name: 'Pacific/Saipan' },
  { name: 'Pacific/Tahiti' },
  { name: 'Pacific/Tongatapu' },
  { name: 'Pacific/Wake' },
  { name: 'Pacific/Wallis' },
  { name: 'US/Alaska' },
  { name: 'US/Arizona' },
  { name: 'US/Central' },
  { name: 'US/Eastern' },
  { name: 'US/Hawaii' },
  { name: 'US/Mountain' },
  { name: 'US/Pacific' },
  { name: 'UTC' },
];
export const countryList = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D"Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People"S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People"S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];
