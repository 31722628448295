import React, { useEffect, useState, lazy, Suspense } from 'react';
import classNames from 'classnames';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import UserService from './service/UserService';

import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact,
  withAuthenticator,
  AmplifyTheme,
  OAuthButton,
} from 'aws-amplify-react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import '@aws-amplify/ui/dist/style.css';
import './App.css';

import { APP_VERSION, MENU_ITEMS, HUB_TYPE, GV_CLUBS } from './utilities/constants';
import { CalendarPage } from './pages/CalendarPage';
import { NoResource } from './pages/404';
import LoginPage_2 from './pages/LoginPage';

import Amplify, { Auth } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

const PreviewPage = lazy(() =>
  import('./pages/PreviewPage').then(module => ({ default: module.PreviewPage }))
);
const AdvertPage = lazy(() => import('./pages/AdvertPage'));
const AdvertiserPage = lazy(() => import('./pages/AdvertiserPage'));
const AdvertsPage = lazy(() => import('./pages/AdvertsPage'));
const ApprovalsPage = lazy(() => import('./pages/ApprovalsPage'));
const AudiencePage = lazy(() => import('./pages/AudiencePage'));
const CompetitionPage = lazy(() => import('./pages/CompetitionPage'));
const CompetitionsPage = lazy(() => import('./pages/CompetitionsPage'));
const ContactsPage = lazy(() => import('./pages/ContactsPage'));
const EmptyPage = lazy(() => import('./pages/EmptyPage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));
const GuidelinesPage = lazy(() => import('./pages/GuidelinesPage'));
const GVAdvertPage = lazy(() => import('./pages/GVAdvertPage'));
const GVAdvertsPage = lazy(() => import('./pages/GVAdvertsPage'));
const GVPlaylistPage = lazy(() => import('./pages/GVPlaylistPage'));
const PlaylistPage = lazy(() => import('./pages/PlaylistPage'));
const RadioPlaylistPage = lazy(() => import('./pages/RadioPlaylistPage'));
const ReportingPage = lazy(() => import('./pages/ReportingPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const SVAdvertPage = lazy(() => import('./pages/SVAdvertPage'));
const SVAdvertsPage = lazy(() => import('./pages/SVAdvertsPage'));
const SVPlaylistPage = lazy(() => import('./pages/SVPlaylistPage'));
const TVAdvertsPage = lazy(() => import('./pages/TVAdvertsPage'));
const TVAdvertPage = lazy(() => import('./pages/TVAdvertPage'));
const DigitalAdvertsPage = lazy(() => import('./pages/DigitalAdvertsPage'));
const DigitalAdvertPage = lazy(() => import('./pages/DigitalAdvertPage'));
const TVPlaylistPage = lazy(() => import('./pages/TVPlaylistPage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const VenuePage = lazy(() => import('./pages/VenuePage'));
const OutOfGamePage = lazy(() => import('./pages/OutOfGame/OutOfGamePage'));
const ImpressionsPage = lazy(() => import('./pages/ImpressionsPage'));

const App = () => {
  // PrimeReact Diamond template settings
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState('static');
  const [colorScheme, setColorScheme] = useState('light');
  const [menuTheme, setMenuTheme] = useState('layout-sidebar-darkgray');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(false);

  const [menu, setMenu] = useState(null);
  const [user, setUser] = useState({ username: 'Test User' });

  let menuClick = false;
  let searchClick = false;
  let userMenuClick = false;
  let notificationMenuClick = false;
  let rightMenuClick = false;
  let configClick = false;

  const routers = [
    {
      path: '/',
      component: CalendarPage,
      exact: true,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Event Calendar' }] },
    },
    {
      path: '/adverts',
      component: AdvertsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Advertisements' }] },
    },
    {
      path: '/advert/:id',
      component: AdvertPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Advertisement' }] },
    },
    {
      path: '/advertisers',
      component: AdvertiserPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Advertisers' }] },
    },
    {
      path: '/approvals',
      component: ApprovalsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Approvals' }] },
    },
    {
      path: '/audience',
      component: AudiencePage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Audience Data' }] },
    },
    {
      path: '/impressions',
      component: ImpressionsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Impressions' }] },
    },
    {
      path: '/calendar',
      component: CalendarPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Event Calendar' }] },
    },
    {
      path: '/competitions',
      component: CompetitionsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Seasons' }] },
    },
    {
      path: '/competition/:id',
      component: CompetitionPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Season' }] },
    },
    {
      path: '/contacts',
      component: ContactsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Contacts' }] },
    },
    {
      path: '/empty',
      component: EmptyPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }] },
    },
    {
      path: '/faq',
      component: FaqPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Frequently Asked Questions' }] },
    },
    {
      path: '/guidelines',
      component: GuidelinesPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Guidelines' }] },
    },
    {
      path: '/glass-virtual-ad/:id',
      component: GVAdvertPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Glass Virtual Advert' }] },
    },
    {
      path: '/glass-virtual',
      component: GVAdvertsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Glass Virtual Ads' }] },
    },
    {
      path: '/preview/:id',
      component: PreviewPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: '3D Preview' }] },
    },
    {
      path: '/404',
      component: NoResource,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Resource not available' }] },
    },
    {
      path: '/playlist/:id',
      component: PlaylistPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Playlist Manager (DED)' }] },
    },
    {
      path: '/playlist-gv/:id',
      component: GVPlaylistPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Playlist Manager (Glass Virtual)' }] },
    },
    {
      path: '/playlist-sv/:id',
      component: SVPlaylistPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Playlist Manager (Slot Virtual)' }] },
    },
    {
      path: '/reporting',
      component: ReportingPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Reporting' }] },
    },
    {
      path: '/settings',
      component: SettingsPage,
      meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Settings' }] },
    },
    {
      path: '/slot-virtual-ad/:id',
      component: SVAdvertPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Slot Virtual Advert' }] },
    },
    {
      path: '/slot-virtual',
      component: SVAdvertsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Slot Virtual Ads' }] },
    },
    {
      path: '/playlist-radio/:id',
      component: RadioPlaylistPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Playlist Manager (Radio)' }] },
    },
    {
      path: '/out-of-game',
      component: OutOfGamePage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Out of Game' }] },
    },
    {
      path: '/playlist-tv/:id',
      component: TVPlaylistPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Playlist Manager (TV)' }] },
    },
    {
      path: '/tv-radio',
      component: TVAdvertsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'TV/Radio Ads' }] },
    },
    {
      path: '/tv-radio-ad/:id',
      component: TVAdvertPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'TV/Radio Advert' }] },
    },
    {
      path: '/digital',
      component: DigitalAdvertsPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Digital Ads' }] },
    },
    {
      path: '/digital-ad/:id',
      component: DigitalAdvertPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Digital Advert' }] },
    },
    {
      path: '/users',
      component: UsersPage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Users' }] },
    },
    {
      path: '/venues',
      component: VenuePage,
      meta: { breadcrumb: [{ parent: 'Pages', label: 'Venues' }] },
    },
  ];

  useEffect(() => {
    // build menu based on user role/settings
    const userService = new UserService();
    userService.getUserData().then(data => {
      setUser(data);
      const _menu = buildMenu(data);
      setMenu(_menu);
    });
  }, []);

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide();
    }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!notificationMenuClick) {
      setTopbarNotificationMenuActive(false);
    }

    if (!rightMenuClick) {
      setRightMenuActive(false);
    }

    if (!menuClick) {
      if (isSlim()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    searchClick = false;
    configClick = false;
    userMenuClick = false;
    rightMenuClick = false;
    notificationMenuClick = false;
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuButtonClick = event => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setTopbarNotificationMenuActive(false);
    setRightMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
    } else {
      setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
    }

    event.preventDefault();
  };

  const onMenuitemClick = event => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive(prevMenuActive => !prevMenuActive);
  };

  const onTopbarUserMenuButtonClick = event => {
    userMenuClick = true;
    setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarNotificationMenuButtonClick = event => {
    notificationMenuClick = true;
    setTopbarNotificationMenuActive(
      prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const toggleSearch = () => {
    setSearchActive(prevSearchActive => !prevSearchActive);
    searchClick = true;
  };

  const onSearchHide = () => {
    setSearchActive(false);
    searchClick = false;
  };

  const onRightMenuButtonClick = event => {
    rightMenuClick = true;
    setRightMenuActive(prevRightMenuActive => !prevRightMenuActive);
    hideOverlayMenu();
    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const isOverlay = () => {
    return menuMode === 'overlay';
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-overlay': menuMode === 'overlay',
      'layout-static': menuMode === 'static',
      'layout-slim': menuMode === 'slim',
      'layout-sidebar-dim': colorScheme === 'dim',
      'layout-sidebar-dark': colorScheme === 'dark',
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple,
    },
    colorScheme === 'light' ? menuTheme : ''
  );

  const logOut = async () => {
    Auth.signOut({}).catch(err => console.log(err));
  };

  const getMenuItems = ids => {
    return MENU_ITEMS.filter(item => ids.includes(item.id));
  };

  // Convert DB string into array of integers or vice versa
  const intsStringConversion = (intsOrString, toInts = true) => {
    const separator = ',';
    if (toInts) return intsOrString.split(separator).map(Number);
    else return intsOrString.join(separator);
  };

  // Convert user.settings string into array of integers.
  // In case user.settings is null, returns default values.
  // Example: 'blabla:bla;menu:3,5;bla; => [3, 5]
  const parseUserSettings = settingsString => {
    //const separator = ',';
    const startString = 'menu:';
    const endString = ';';
    // default values (if not changed in Settings)
    let finalArray = [1, 4, 5];
    if (settingsString) {
      // Split settings string into two parts,
      // before the 'menu:' and after the 'menu:'.
      // Example: '000menu:111;bla' => ['000', '111;bla']
      const splittedArr = settingsString.split(startString);
      // if there's menu settings in the string
      if (splittedArr.length > 1) {
        let str = splittedArr[1];

        const before = str.slice(0, str.indexOf(endString));
        finalArray = intsStringConversion(before);
      }
    }
    return finalArray;
  };

  // TODO: conditionally add favorites (if exist)
  const buildMenu = (user, ints) => {
    let _menu = [];
    // non-NHL version is no longer relevant
    if (HUB_TYPE !== 'NHL') {
      // favorites section
      const menuFavorites = ints ? ints : parseUserSettings(user.settings);
      _menu = [
        { label: 'Favorites', icon: 'pi pi-fw pi-home', items: getMenuItems(menuFavorites) },
      ];
      _menu.push({ separator: true });

      // main section
      _menu = [
        ..._menu,
        ...[
          {
            label: 'Main',
            icon: 'pi pi-fw pi-id-card',
            items: getMenuItems([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
          },
        ],
      ];

      // admin section
      if (user.role === 'admin') {
        _menu.push({ separator: true });
        _menu = [
          ..._menu,
          ...[{ label: 'Admin', icon: 'pi pi-fw pi-id-user', items: getMenuItems([11, 12, 13]) }],
        ];
      }
    }
    // NHL version
    else {
      // main section
      _menu = [
        ..._menu,
        ...[
          {
            label: 'Main',
            icon: 'pi pi-fw pi-id-card',
            items: getMenuItems([1, 4, 2, 3, 12, 5, 13, 15, 14]),
          },
        ],
      ];

      // conditionally include 'Glass Virtual Ads' item
      if (GV_CLUBS.includes(user.club)) {
        const gvItem = getMenuItems([23])[0];
        const indexToInsert = 5;
        _menu[0].items.splice(indexToInsert, 0, gvItem);
      }

      // show 'TV/Radio Ads and Digital Adds' items to NHL users/admins only
      if (user.club === 1) {
        const tvItem = getMenuItems([24])[0];
        const digitalItem = getMenuItems([25])[0];
        const indexToInsert = 6;
        _menu[0].items.splice(indexToInsert, 0, tvItem, digitalItem);
        const outOfGameScheduleItem = getMenuItems([21])[0];
        const indexToInsert1 = 1;
        _menu[0].items.splice(indexToInsert1, 0, outOfGameScheduleItem);
      }
      if (user.club === 1 && user.role === 'admin') {
        _menu.push({ separator: true });
        _menu = [
          ..._menu,
          ...[
            {
              label: 'Admin',
              icon: 'pi pi-fw pi-id-user',
              items: getMenuItems([6, 7, 8, 9, 10, 16, 26, 11]),
            },
          ],
        ];
      }
    }
    return _menu;
  };

  const updateGlobalState = (operation, args) => {
    if (operation === 'saveFavorites') {
      setMenu(
        buildMenu(
          user,
          args.map(arg => arg.code)
        )
      );
    }
  };

  if (!user.role) return null;
  else
    return (
      <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
        <div className="layout-content-wrapper">
          <AppTopBar
            routers={routers}
            topbarNotificationMenuActive={topbarNotificationMenuActive}
            topbarUserMenuActive={topbarUserMenuActive}
            onMenuButtonClick={onMenuButtonClick}
            onSearchClick={toggleSearch}
            onTopbarNotification={onTopbarNotificationMenuButtonClick}
            onTopbarUserMenu={onTopbarUserMenuButtonClick}
            onRightMenuClick={onRightMenuButtonClick}
            onRightMenuButtonClick={onRightMenuButtonClick}
            onLogout={logOut}
            userEmail={user.email}
          >
            {' '}
          </AppTopBar>

          <div className="layout-content">
            {routers.map((router, index) => {
              if (router.exact) {
                return (
                  <Suspense fallback={<div className="lds-ellipsis"></div>} key={`router${index}`}>
                    <Route
                      path={router.path}
                      exact
                      render={props => <router.component {...props} user={user} />}
                    />
                  </Suspense>
                );
              }

              return (
                <Suspense fallback={<div className="lds-ellipsis"></div>} key={`router${index}`}>
                  <Route
                    path={router.path}
                    render={props => (
                      <router.component
                        {...props}
                        user={user}
                        updateGlobalState={updateGlobalState}
                      />
                    )}
                  />
                </Suspense>
              );
            })}
          </div>
          <AppFooter />
        </div>
        <AppMenu
          model={menu}
          menuMode={menuMode}
          active={menuActive}
          mobileMenuActive={staticMenuMobileActive}
          onMenuClick={onMenuClick}
          onMenuitemClick={onMenuitemClick}
          onRootMenuitemClick={onRootMenuitemClick}
        ></AppMenu>
        <div className="layout-mask modal-in"></div>
      </div>
    );
};

// export default withAuthenticator(App)
const theme = {
  ...AmplifyTheme,
  container: {
    ...AmplifyTheme.container,
    padding: 0,
    marginTop: '-8px',
  },
  navBar: {
    ...AmplifyTheme.navBar,
    background: '#dbe8f4',
    backgroundImage: '-webkit-linear-gradient(top,#08364e,#094361)',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    border: 0,
  },
  button: {
    ...AmplifyTheme.button,
    backgroundColor: '#41769c',
    color: 'white',
  },
  sectionBody: {
    ...AmplifyTheme.sectionBody,
    padding: '0px',
  },
  sectionHeader: {
    ...AmplifyTheme.sectionHeader,
    backgroundColor: 'var(--squidInk)',
    display: 'none',
  },

  oAuthSignInButton: {
    ...AmplifyTheme.oAuthSignInButton,
    width: 186,
    height: 58,
    fontSize: 16,
    background: 'linear-gradient(90deg, #54979e 0%, #296670 99%)',
    padding: 0,
    paddingTop: 1,
    margin: '120px auto',
    border: 0,
    color: 'white',
  },
};

// https://techinscribed.com/authentication-in-serverless-react-application-using-aws-amplify/
//   const signUpConfig = {
//     header: 'Create an Account',
//     hideAllDefaults: true,
//     defaultCountryCode: '1',
//     signUpFields: [
//       {
//         label: 'Name',
//         key: 'name',
//         required: true,
//         displayOrder: 1,
//         type: 'string'
//       },
//       {
//         label: 'Email',
//         key: 'username',
//         required: true,
//         displayOrder: 2,
//         type: 'string'
//       },
//       {
//         label: 'Password',
//         key: 'password',
//         required: true,
//         displayOrder: 3,
//         type: 'password'
//       }
//     ]
//   };

export class LoginPage extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }
  showComponent(theme) {
    const oAuthButton = document.querySelectorAll('[variant="oAuthSignInButton"]')[0];
    if (oAuthButton) {
      if (oAuthButton.children[0]) {
        oAuthButton.children[0].innerHTML =
          "<span><img class='loginButtonLogo' src='assets/layout/images/nhl-logo.png' alt='NHL'> <p class='loginButtonText'>SIGN IN</p> </span>";
      }
    }

    return (
      <Router>
        <Switch>
          {/* remove trailing slash */}
          <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />

          <Route exact path="/">
            <Redirect to="/" />
          </Route>
        </Switch>
        <LoginPage_2 />
      </Router>
    );
  }
}

export class LoginPageFooter extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }
  showComponent(theme) {
    return (
      <div className="loginPageFooter">
        <hr className="loginPageFooterLine"></hr>
        <p className="footerText">
          Copyright © {new Date().getFullYear()} Supponor Oy. All rights reserved
          &nbsp;&nbsp;|&nbsp;&nbsp; Supponor Hub {APP_VERSION} &nbsp;&nbsp;|&nbsp;&nbsp;{' '}
          <a href="mailto: alexandru.oat@supponor.com">Give feedback</a>
        </p>
      </div>
    );
  }
}
export default withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: false,
  // signUpConfig,
  // Show only certain components
  authenticatorComponents: [
    <LoginPage />,
    // <SignIn />,
    <OAuthButton />,
    <LoginPageFooter />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <SignUp />,
    <ConfirmSignUp />,
    <ForgotPassword />,
    <RequireNewPassword />,
  ],
  // display federation/social provider buttons
  federated: null,
  // customize the UI/styling
  theme: theme,
});
