import { useEffect, useState } from 'react';
import { getTimeInEtTimezone } from '../../utilities/functions';
import { Divider } from 'primereact/divider';
import EventService from '../../service/EventsService';
import { countryList } from '../../utilities/constants';

const EventTooltipComponent = ({ id, events, openEventPage, openPlaylistPage, tooltipRef }) => {
  const DEFAULT_FLAG = 'assets/demo/images/flags/flag-placeholder-white.jpg';
  const [eventVenueFeeds, setEventVenueFeeds] = useState(null);
  const [flagImage, setFlagImage] = useState({
    src: DEFAULT_FLAG,
    className: '',
    city: '',
  });

  const loadData = async eventId => {
    const eventService = new EventService();
    const data = await eventService.getCalendarEventData(eventId);
    if (data) {
      setEventVenueFeeds(data);
      if (data.venue && data.venue[0]) {
        const { region, city } = data.venue[0];
        const isUnitedStates = region === 'United States';

        setFlagImage({
          src: isUnitedStates ? getFlagSrc(region) : null,
          city: city,
          className: isUnitedStates ? '' : getFlagClass(region),
        });
      }
    }
  };

  useEffect(() => {
    if (id) {
      loadData(id);
    }
  }, [id]);

  const closeTooltip = () => {
    tooltipRef.current.hideTooltip();
    document.getElementById('eventTooltipId').style.display = 'none';
  };

  const event = events.find(ev => ev.id === parseInt(id));

  if (!event) return null;

  const getFlagClass = region => {
    const country = countryList.find(obj => {
      return obj.name === region;
    });
    if (!country) return `flag flag-ca`;
    return `flag flag-${country.code.toLowerCase()}`;
  };
  const getFlagSrc = region => {
    return region === 'United States' ? 'assets/demo/images/flags/us-flag.png' : null;
  };

  return (
    <>
      <div className="calendarEventTooltipHeader">
        <span onClick={() => openEventPage(event)}>{event.title}</span>
        <i
          onClick={closeTooltip}
          style={{ position: 'absolute', right: 2, top: 2 }}
          className="pi pi-times"
        ></i>
      </div>

      <div className="calendarEventTooltipBody">
        <div style={{ display: 'table', width: '100%' }}>
          <div className="tooltipTableRow" style={{ display: 'table-row' }}>
            <div
              className="tooltipTableCellLeft"
              style={{ display: 'table-cell', textAlign: 'left', paddingRight: '1em' }}
            >
              Competition
            </div>
            <div
              className="tooltipTableCellRight"
              style={{ display: 'table-cell', textAlign: 'right' }}
            >
              {event.competition ? event.competition : 'Regular'}
            </div>
          </div>
          <div className="tooltipTableRow" style={{ display: 'table-row' }}>
            <div
              className="tooltipTableCellLeft"
              style={{
                display: 'table-cell',
                textAlign: 'left',
                paddingRight: '1em',
                verticalAlign: 'middle',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {flagImage.src ? (
                  <img
                    src={flagImage.src}
                    alt=""
                    width={30}
                    height={20}
                    style={{ marginRight: '.5em' }}
                  />
                ) : (
                  <div>
                    <img
                      alt=""
                      className={flagImage.className}
                      width={30}
                      height={20}
                      style={{ marginRight: '.5em' }}
                    />
                  </div>
                )}
                <span style={{ fontWeight: '400' }}>{flagImage.city}</span>
              </div>
            </div>
            <div
              className="tooltipTableCellRight"
              style={{ display: 'table-cell', textAlign: 'right', verticalAlign: 'middle' }}
            >
              {getTimeInEtTimezone(event.et_time, false)}
            </div>
          </div>
        </div>

        {eventVenueFeeds?.outputs?.length > 0 && (
          <>
            <Divider align="center">
              <div className="inline-flex align-items-center">
                <i className="pi pi-list mr-2"></i>&nbsp;
                <b>Feeds</b>
              </div>
            </Divider>

            {eventVenueFeeds.outputs.map((output, i) => (
              <div
                onClick={() => openPlaylistPage(output.id)}
                className={
                  i % 2 === 0
                    ? 'cursorPointer tooltipTableRow greyTableRow'
                    : 'cursorPointer tooltipTableRow'
                }
                style={{ paddingLeft: 10, paddingRight: 30, color: '#1976D2' }}
                key={i}
              >
                <div className="tooltipTableCellLeft">{output.name}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default EventTooltipComponent;
