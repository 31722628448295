import { CLUBS, FEED_MAP, NETWORKS } from '../utilities/constants';
import AuthService from '../service/AuthService';

// build s3 object URL
// TODO: pass region as arg, now we assume it's always 'eu-central-1'
export const buildS3Url = (bucket, key) => {
  return 'https://' + bucket + '.s3.eu-central-1.amazonaws.com/' + key;
};
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
export const sortObjectsByName = arr => {
  arr.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return arr;
};
// copy string clipboard and show a toast notification
export const copyToClipboard = (toast_, str, message) => {
  navigator.clipboard.writeText(str);
  toast_.current.show({ severity: 'success', summary: 'Copied', detail: message, life: 500 });
};
// make sure name property is unique for each element (segments, zones)
export const isNameUnique = items => {
  var names = items.map(item => item.name.trim());
  return !names.some(function (v) {
    return (
      names.filter(function (w) {
        return w === v;
      }).length > 1
    );
  });
};
export const groupIntoSubarrays = (arr, key) => {
  return arr.reduce(function (r, a, i) {
    if (!i || r[r.length - 1][0][key] !== a[key]) {
      return r.concat([[a]]);
    }
    r[r.length - 1].push(a);
    return r;
  }, []);
};
/** Convert a 2D array into a CSV string
 */
export const arrayToCsv = data => {
  return data
    .map(
      row =>
        row
          .map(String) // convert every value to String
          .map(v => v.replaceAll('"', '""')) // escape double colons
          .join(',') // comma-separated
    )
    .join('\r\n'); // rows starting on new lines
};
/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export const downloadBlob = (content, filename, contentType) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};
// returns CEC as an array of 5 elements
// export const getExecutionCode = (ad) => {
//     const emptyCodePart = '???';
//     const teamCode = 'NHL';
//     const advertiserCode = ad.advertiser ? ad.advertiser.code : emptyCodePart;
//     const getInventoryFormatCode = () => {
//         let code;

//         if (!ad.format)
//             return emptyCodePart;
//         // (TMP) in adverts page we deal with ad.format.name,
//         // while in advert profile page we should check ad.format
//         const adFormat = ad.format.name ? ad.format.name : ad.format;
//         switch(adFormat) {
//             case 'Full Takeover': code = 'FTO'; break;
//             case 'End Takeover': code = 'ETO'; break;
//             case 'Zone': code = 'ZON'; break;
//             case 'Split Zone': code = 'SPZ'; break;
//             default: code = emptyCodePart; break;
//         }
//         return code;
//     }

//     const getZoneCode = () => {
//         let code;

//         if (!ad.zones)
//             return emptyCodePart;
//         switch(ad.zones) {
//             case '1, 2, 4, 5': code = 'Z15'; break;
//             case '1, 2, 3, 4, 5': code = 'Z15'; break;
//             case '1 & 5': code = 'Z15'; break;
//             case '1-5': code = 'Z15'; break;
//             case '1, 2': code = 'Z12'; break;
//             case '4, 5': code = 'Z45'; break;
//             case '2 & 4': code = 'Z24'; break;
//             case '1': code = 'Z01'; break;
//             case '2': code = 'Z02'; break;
//             case '3': code = 'Z03'; break;
//             case '4': code = 'Z04'; break;
//             case '5': code = 'Z05'; break;
//             case '1A': code = 'Z1A'; break;
//             case '2A': code = 'Z2A'; break;
//             case '3A': code = 'Z3A'; break;
//             case '4A': code = 'Z4A'; break;
//             case '5A': code = 'Z5A'; break;
//             case '1B': code = 'Z1B'; break;
//             case '2B': code = 'Z2B'; break;
//             case '3B': code = 'Z3B'; break;
//             case '4B': code = 'Z4B'; break;
//             case '5B': code = 'Z5B'; break;
//             default: code = 'Z00'; break;
//         }
//         return code;
//     }

//     const getSequenceCode = () => {
//         if (advertiserCode !== emptyCodePart && getInventoryFormatCode() !== emptyCodePart && getZoneCode !== emptyCodePart)
//             return '001';
//         else
//             return emptyCodePart;
//     }

//     return [teamCode, advertiserCode, getInventoryFormatCode(), getSequenceCode(), getZoneCode()];
// }

// build approval/reject email
export const buildApprovalEmail = (email, isRejected, comments, url, ad) => {
  const emailObject = !isRejected
    ? {
        subject: 'Submitted NHL Artwork has been Approved',
        body: `<div style="font-size: 12px;"><p>Your submitted creative (linked below) has been approved and is ready to be scheduled.</p>
<br/>
<p>${comments}</p>
<br/>

<span style="width: 65px; display: inline-block;">Ad name:</span> <b>${ad.name}</b>
<br/>
<span style="width: 65px; display: inline-block;">Advertiser:</span> <b>${ad.advertiser && ad.advertiser.name}</b>
<br/>
<span style="width: 65px; display: inline-block;">Link:</span> <a href=${url}>${url}</a>
<br/><br/>

<p>Please reach out to your NHL contact should you have any questions.</p>         
<br/>
Thank You,
<br/>
NHL Team</div>`,
        email: [email],
      }
    : {
        subject: 'Submitted NHL Artwork has been Rejected',

        body: `<p>Your submitted creative (linked below) has been rejected for the following reason(s):
<p>${comments}<p>
<br/>            

<span style="width: 65px; display: inline-block;">Ad name:</span> <b>${ad.name}</b>
<br/>
<span style="width: 65px; display: inline-block;">Advertiser:</span> <b>${ad.advertiser && ad.advertiser.name}</b>
<br/>
<span style="width: 65px; display: inline-block;">Link:</span> <a href=${url}>${url}</a>
<br/><br/>

<p>Please reach out to your NHL contact should you have any questions. Otherwise, please resubmit with the edits suggested above for additional consideration.</p>            
<br/>
Thank You,
<br/>
NHL Team`,
        email: [email],
      };

  return emailObject;
};

// Returns the hour difference between the specified
// timezone and UTC. E.g. for "America/New_York" the
// result would be -4 or -5 hours, depending on DST
export const getTimezoneOffset = (tz, hereDate) => {
  hereDate = new Date(hereDate || Date.now());
  hereDate.setMilliseconds(0); // rounding

  const hereOffsetHrs = (hereDate.getTimezoneOffset() / 60) * -1,
    thereLocaleStr = hereDate.toLocaleString('en-US', {
      timeZone: tz,
    }),
    thereDate = new Date(thereLocaleStr),
    diffHrs = (thereDate.getTime() - hereDate.getTime()) / 1000 / 60 / 60,
    thereOffsetHrs = hereOffsetHrs + diffHrs;

  return thereOffsetHrs;
};
/**
 * Converts a date string into the 'MM/DD/YYYY H:MM ET' format in the ET timezone.
 * @param {string} dateStr - The date string to be converted.
 * @returns {string} The formatted date string in 'MM/DD/YYYY H:MM ET' format.
 */
export const getTimeInEtTimezone = (origDate, timeOnly = true) => {
  if (!origDate) return '';
  let d = new Date(origDate);
  const ET_OFFSET = getTimezoneOffset('America/New_York', d);
  const ET_OFFSET_MILLISECONDS = ET_OFFSET * 60 * 60 * 1000;
  d = new Date(d.getTime() + ET_OFFSET_MILLISECONDS);

  const dateOptions = timeOnly
    ? { timeZone: 'UTC', hour: 'numeric', minute: '2-digit' }
    : {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      };

  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
  const dateAsFormattedString =
    dateFormatter.format(d).replace(' A', 'A').replace(' P', 'P') + ' ET';
  return dateAsFormattedString;
};

export const deltaDate = (input, days) => {
  const evD = new Date(input);
  const ET_OFFSET = getTimezoneOffset('America/New_York', evD);
  evD.setHours(evD.getHours() - days * 24 + ET_OFFSET);
  const utcEvDate = new Date(evD);
  return utcEvDate;
};

// TODO: remove DateFormatter.formatDate from code throughout the app,
// use DateFormatter.formatUTCDate instead!
// getUTCDate has getUTCDate, getUTCMonth... instead of getDate and so on
export var DateFormatter = {
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  formatUTCDate: function (date, format) {
    var self = this;
    format = self.getProperDigits(format, /d+/gi, date.getUTCDate());
    format = self.getProperDigits(format, /M+/g, date.getUTCMonth() + 1);
    format = format.replace(/y+/gi, function (y) {
      var len = y.length;
      var year = date.getFullYear();
      if (len == 2) return (year + '').slice(-2);
      else if (len == 4) return year;
      return y;
    });
    format = self.getProperDigits(format, /H+/g, date.getUTCHours());
    format = self.getProperDigits(format, /h+/g, self.getHours12(date.getUTCHours()));
    format = self.getProperDigits(format, /m+/g, date.getUTCMinutes());
    format = self.getProperDigits(format, /s+/gi, date.getSeconds());
    format = format.replace(/a/gi, function (a) {
      var amPm = self.getAmPm(date.getHours());
      if (a === 'A') return amPm.toUpperCase();
      return amPm;
    });
    format = self.getFullOr3Letters(format, /d+/gi, self.dayNames, date.getUTCDay());
    format = self.getFullOr3Letters(format, /M+/g, self.monthNames, date.getUTCMonth());
    return format;
  },
  formatDate: function (date, format) {
    var self = this;
    format = self.getProperDigits(format, /d+/gi, date.getDate());
    format = self.getProperDigits(format, /M+/g, date.getMonth() + 1);
    format = format.replace(/y+/gi, function (y) {
      var len = y.length;
      var year = date.getFullYear();
      if (len == 2) return (year + '').slice(-2);
      else if (len == 4) return year;
      return y;
    });
    format = self.getProperDigits(format, /H+/g, date.getHours());
    format = self.getProperDigits(format, /h+/g, self.getHours12(date.getHours()));
    format = self.getProperDigits(format, /m+/g, date.getMinutes());
    format = self.getProperDigits(format, /s+/gi, date.getSeconds());
    format = format.replace(/a/gi, function (a) {
      var amPm = self.getAmPm(date.getHours());
      if (a === 'A') return amPm.toUpperCase();
      return amPm;
    });
    format = self.getFullOr3Letters(format, /d+/gi, self.dayNames, date.getDay());
    format = self.getFullOr3Letters(format, /M+/g, self.monthNames, date.getMonth());
    return format;
  },
  getProperDigits: function (format, regex, value) {
    return format.replace(regex, function (m) {
      var length = m.length;
      if (length == 1) return value;
      else if (length == 2) return ('0' + value).slice(-2);
      return m;
    });
  },
  getHours12: function (hours) {
    // https://stackoverflow.com/questions/10556879/changing-the-1-24-hour-to-1-12-hour-for-the-gethours-method
    return (hours + 24) % 12 || 12;
  },
  getAmPm: function (hours) {
    // https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
    return hours >= 12 ? 'pm' : 'am';
  },
  getFullOr3Letters: function (format, regex, nameArray, value) {
    return format.replace(regex, function (s) {
      var len = s.length;
      if (len == 3) return nameArray[value].substr(0, 3);
      else if (len == 4) return nameArray[value];
      return s;
    });
  },
};

export const formatDate = (origDate, dbFormat = false) => {
  const d = new Date(origDate);
  let formattedDate;
  if (dbFormat) {
    // YYYY-mm-dd (DB)
    const ye = d.getFullYear();
    const mo = (1 + d.getMonth()).toString().padStart(2, '0');
    const da = d.getDate().toString().padStart(2, '0');
    formattedDate = `${ye}-${mo}-${da}`;
  } else {
    // mm/dd/yy (UI)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = (1 + d.getMonth()).toString().padStart(2, '0');
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    formattedDate = `${mo}/${da}/${ye}`;
  }
  return formattedDate;
};

export const getZoneArray = (zones, inverse_AB_for_Zones_4_5 = false) => {
  let code = [1, 5];

  if (inverse_AB_for_Zones_4_5) {
    switch (zones) {
      case '1, 2, 4, 5':
        code = [1, 2, 4, 5];
        break;
      case '1-2 and/or 4-5':
        code = [1, 2, 4, 5];
        break;
      case '1, 2, 3, 4, 5':
        code = [1, 2, 3, 4, 5];
        break;
      case '1 & 5':
        code = [1, 5];
        break;
      case '1 and/or 5':
        code = [1, 5];
        break;
      case '1-5':
        code = [1, 2, 3, 4, 5];
        break;
      case '1, 2':
        code = [1, 2];
        break;
      case '4, 5':
        code = [4, 5];
        break;
      case '2 & 4':
        code = [2, 4];
        break;
      case '2 and/or 4':
        code = [2, 4];
        break;
      case '1':
        code = [1];
        break;
      case '2':
        code = [2];
        break;
      case '3':
        code = [3];
        break;
      case '4':
        code = [4];
        break;
      case '5':
        code = [5];
        break;
      case '1A':
        code = ['1A'];
        break;
      case '2A':
        code = ['2A'];
        break;
      case '3A':
        code = ['3A'];
        break;
      case '4A':
        code = ['4B'];
        break;
      case '5A':
        code = ['5B'];
        break;
      case '1B':
        code = ['1B'];
        break;
      case '2B':
        code = ['2B'];
        break;
      case '3B':
        code = ['3B'];
        break;
      case '4B':
        code = ['4A'];
        break;
      case '5B':
        code = ['5A'];
        break;
      case '1A & 5A':
        code = ['1A', '5B'];
        break;
      case '1A and/or 5A':
        code = ['1A', '5B'];
        break;
      case '1B & 5B':
        code = ['1B', '5A'];
        break;
      case '1B and/or 5B':
        code = ['1B', '5A'];
        break;
      case '2A & 4A':
        code = ['2A', '4B'];
        break;
      case '2B & 4B':
        code = ['2B', '4A'];
        break;
      case '2A, 2B, 4A, 4B':
        code = ['2A', '2B', '4B', '4A'];
        break;
      case '2A, 2B, 4A and/or 4B':
        code = ['2A', '2B', '4B', '4A'];
        break;
      case '3A & 3B':
        code = ['3A', '3B'];
        break;
      case '3A and/or 3B':
        code = ['3A', '3B'];
        break;
      default:
        code = [1, 5];
        break;
    }
  } else {
    switch (zones) {
      case '1, 2, 4, 5':
        code = [1, 2, 4, 5];
        break;
      case '1-2 and/or 4-5':
        code = [1, 2, 4, 5];
        break;
      case '1, 2, 3, 4, 5':
        code = [1, 2, 3, 4, 5];
        break;
      case '1 & 5':
        code = [1, 5];
        break;
      case '1 and/or 5':
        code = [1, 5];
        break;
      case '1-5':
        code = [1, 2, 3, 4, 5];
        break;
      case '1, 2':
        code = [1, 2];
        break;
      case '4, 5':
        code = [4, 5];
        break;
      case '2 & 4':
        code = [2, 4];
        break;
      case '2 and/or 4':
        code = [2, 4];
        break;
      case '1':
        code = [1];
        break;
      case '2':
        code = [2];
        break;
      case '3':
        code = [3];
        break;
      case '4':
        code = [4];
        break;
      case '5':
        code = [5];
        break;
      case '1A':
        code = ['1A'];
        break;
      case '2A':
        code = ['2A'];
        break;
      case '3A':
        code = ['3A'];
        break;
      case '4A':
        code = ['4A'];
        break;
      case '5A':
        code = ['5A'];
        break;
      case '1B':
        code = ['1B'];
        break;
      case '2B':
        code = ['2B'];
        break;
      case '3B':
        code = ['3B'];
        break;
      case '4B':
        code = ['4B'];
        break;
      case '5B':
        code = ['5B'];
        break;
      case '1A & 5A':
        code = ['1A', '5A'];
        break;
      case '1A and/or 5A':
        code = ['1A', '5A'];
        break;
      case '1B & 5B':
        code = ['1B', '5B'];
        break;
      case '1B and/or 5B':
        code = ['1B', '5B'];
        break;
      case '2A & 4A':
        code = ['2A', '4A'];
        break;
      case '2B & 4B':
        code = ['2B', '4B'];
        break;
      case '2A, 2B, 4A, 4B':
        code = ['2A', '2B', '4A', '4B'];
        break;
      case '2A, 2B, 4A and/or 4B':
        code = ['2A', '2B', '4A', '4B'];
        break;
      case '3A & 3B':
        code = ['3A', '3B'];
        break;
      case '3A and/or 3B':
        code = ['3A', '3B'];
        break;
      default:
        code = [1, 5];
        break;
    }
  }
  return code;
};
export const setBreadcrumb = breadcrumbHTML => {
  const breadcrumbDiv = document.getElementsByClassName('layout-breadcrumb viewname')[0];
  breadcrumbDiv.innerHTML = breadcrumbHTML;
};
// lambda function invocation
export const invokeLambda = (lambda, params) =>
  new Promise((resolve, reject) => {
    lambda.invoke(params, (error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
export const getSVOutputShareModel = (event, output) => {
  const homeRsnNames = ['Home RSN', 'Home RSN (FR)'];
  const awayRsnNames = ['Away RSN', 'Away RSN (FR)'];
  const nationalNames = ['CA National', 'CA National (FR)', 'US National'];

  if (homeRsnNames.includes(output.name)) {
    return 'SV-LOC-H';
  } else if (awayRsnNames.includes(output.name)) {
    return 'SV-LOC-A';
  } else if (nationalNames.includes(output.name)) {
    // a special share model for Ottawa home games (CA feeds)
    if (
      event.home === 22 &&
      (output.name === 'CA National' || output.name === 'CA National (FR)')
    ) {
      return 'SV-NAT-CA-OTT';
    }
    return 'SV-NAT';
  }
  return 'SV-INT';
};
export const getOutputShareModel = (event, output) => {
  // TODO: handle this in DB, on Output level
  // exceptions
  // Winter Classic and other special cases;
  // also NHLN US National feeds
  const USN2 = [
    7724, 8492, 18175, 18736, 18868, 19814, 19913, 20342, 20485, 20914, 21046, 21497, 21640, 22091,
    23137, 23489, 23632, 24193, 24611, 24732, 25183, 25337, 26283, 26327, 27350, 27493, 28483,
    29088, 29649, 29363, 30617, 38001, 38089, 38452, 38903, 38958, 38980, 39112, 39145, 39442,
    39464, 39508, 39629, 40025, 40102, 40841, 40962, 41006, 41050, 41369, 41380, 41435, 41545,
    41589, 41611, 41974, 42029, 42095, 42139, 42447, 42612, 42788, 42931, 43173, 43470, 43624,
    43690, 43756, 44097, 44108, 44152, 44273, 44350, 44493, 44702, 44735, 44900, 45208, 42678,
    45307, 45747, 45967, 46066, 46187, 46242, 46561,
  ];
  const CDNN5 = [17642, 17643]; // Heritage Classic
  if (USN2.includes(output.id)) {
    return 'RS-USA-N-2';
  } else if (CDNN5.includes(output.id)) {
    return 'RS-CDN-N-5';
  }

  const outputName = output.name;

  const home = CLUBS.find(club => club.id === event.home);
  const away = CLUBS.find(club => club.id === event.away);

  if (home && away) {
    const crossBorder = home.country !== away.country;

    if (outputName === 'Home RSN' || outputName === 'Home RSN (FR)') {
      return 'RS-H-1';
    }

    if (outputName === 'Away RSN' || outputName === 'Away RSN (FR)') {
      // RS-A-1 applies when
      // a) both clubs are 'NO SHARE'
      // b) preseason-2023 NYR away games [special case]
      if (
        (home.share === 'no' && away.share === 'no') ||
        (event.competition_id === 11 && away.code === 'NYR')
      ) {
        return 'RS-A-1';
      }
      // cross border and away club opts out
      // TODO: double-check home.opt_out condition!!!
      if (crossBorder && away.opt_out && home.opt_out) {
        return 'RS-A-1';
      }

      if (!crossBorder && (home.share === 'max' || away.share === 'max')) {
        return 'RS-A-2';
      }

      if (
        !crossBorder &&
        home.share !== 'max' &&
        away.share !== 'max' &&
        (home.share === 'min' || away.share === 'min')
      ) {
        return 'RS-A-3';
      }
      // cross border
      // if (crossBorder) {
      //     // except cases where both clubs are a 'NO SHARE'
      //     if (home.share === 'no' && away.share !== 'no') {
      //         console.log('NO RS-A-4: Both clubs are a "NO SHARE"');
      //     }
      //     // or away club is 'opt_out'
      //     else if (away.opt_out) {
      //         // console.log('NO RS-A-4: Away club is "OPT OUT"');
      //     }
      //     else {
      //         return 'RS-A-4';
      //     }
      // }

      // (home.share === 'no' && away.share === 'no') ||
      // (crossBorder && away.opt_out)
      return 'RS-A-4';
    }

    if (outputName === 'US National') {
      if (home.country === 'US') {
        return 'RS-USA-N-1';
      }

      if (
        (away.country === 'US' && away.share === 'no') ||
        (home.country === 'CA' && away.opt_out)
      ) {
        return 'RS-USA-N-2';
      }

      return 'RS-USA-N-3';
    }

    if (outputName === 'CA National' || outputName === 'CA National (FR)') {
      if (home.country === 'CA') {
        return 'RS-CDN-N-4';
      }
      if (
        (away.country === 'CA' && home.share === 'no') ||
        (home.country === 'US' && home.opt_out)
      ) {
        return 'RS-CDN-N-5';
      }

      return 'RS-CDN-N-6';
    }
  }
  return 'RS-INT-1'; // default
};
export const getOutputShareModel_playoffs = (homeClubId, awayClubId, output) => {
  const outputName = output.name;
  const home = CLUBS.find(club => club.id === homeClubId);
  const away = CLUBS.find(club => club.id === awayClubId);
  const testEventException = [33111, 33112].includes(output.id);
  if (home && away) {
    if (outputName === 'Home RSN' || outputName === 'Home RSN (FR)') {
      return 'SC-H-1';
    }
    if (outputName === 'Away RSN' || outputName === 'Away RSN (FR)') {
      return 'SC-A-1';
    }
    if (outputName === 'US National') {
      return 'SC-USA-N-1';
    }
    if (outputName === 'CA National' || outputName === 'CA National (FR)') {
      if (home.country === 'CA' || testEventException) {
        return 'SC-CDN-N-2';
      }

      return 'SC-CDN-N-1';
    }
  }
  return 'SC-INT-1';
};
// This function is used solely to provide a share model object
// for SV reporting needs. This functionality should be refactored
// to utilize a combination of getSVOutputShareModel()
// and the SV_SHARE_MODELS constant instead.
export const getSVReportingShareModel = (event, output, season2023, playoffs) => {
  const home = CLUBS.find(club => club.id === event.home);
  const ALL_SV_INTERVAL_INDEXES_2022 = [
    1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 22, 24,
  ];
  const ALL_SV_INTERVAL_INDEXES = [
    1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 25, 27, 29, 30, 31,
    32, 33, 34, 36, 37, 38, 39, 40, 41,
  ];
  const ALL_SV_INTERVAL_INDEXES_PLAYOFFS = [
    1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27,
  ];
  // TODO: move this const into constants.js

  const SC_LOC_H = {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27],
    home: [1, 8, 15],
    away: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
  };
  const SC_LOC_A = {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27],
    home: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
    away: [1, 8, 15],
  };
  const SC_NAT = {
    nhl: [],
    away: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
    home: ALL_SV_INTERVAL_INDEXES_PLAYOFFS,
  };

  const RS_LOC_H_2022 = {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 24],
    home: [1, 8, 15],
    away: ALL_SV_INTERVAL_INDEXES_2022,
  };
  const RS_LOC_A_2022 = {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 24],
    away: [1, 8, 15],
    home: ALL_SV_INTERVAL_INDEXES_2022,
  };
  const RS_NAT_2022 = {
    nhl: [],
    away: ALL_SV_INTERVAL_INDEXES_2022,
    home: ALL_SV_INTERVAL_INDEXES_2022,
  };

  // a special share model: CA National feed for Ottawa home games
  const RS_NAT_CA_OTT_2022 = {
    nhl: [2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 22, 24],
    home: [1, 8, 15],
    away: ALL_SV_INTERVAL_INDEXES_2022,
  };
  const SC_INT = SC_NAT;
  const RS_INT_2022 = RS_NAT_2022;

  const RS_LOC_H = {
    nhl: [2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23, 25, 27],
    home: [1, 9, 17],
    away: ALL_SV_INTERVAL_INDEXES,
  };
  const RS_LOC_A = {
    nhl: [2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23, 25, 27],
    away: [1, 9, 17],
    home: ALL_SV_INTERVAL_INDEXES,
  };
  const RS_NAT = {
    nhl: [],
    away: ALL_SV_INTERVAL_INDEXES,
    home: ALL_SV_INTERVAL_INDEXES,
  };

  // a special share model: CA National feed for Ottawa home games
  const RS_NAT_CA_OTT = {
    nhl: [2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 15, 18, 19, 20, 21, 22, 23, 25, 27],
    home: [1, 9, 17, 29, 30, 31, 32, 33, 34, 36, 37, 38, 39, 40, 41],
    away: ALL_SV_INTERVAL_INDEXES,
  };
  const RS_INT = RS_NAT;

  if (season2023) {
    if (output.name === 'Home RSN' || output.name === 'Home RSN (FR)') {
      return RS_LOC_H;
    } else if (output.name === 'Away RSN' || output.name === 'Away RSN (FR)') {
      return RS_LOC_A;
    } else if (
      output.name === 'CA National' ||
      output.name === 'CA National (FR)' ||
      output.name === 'US National'
    ) {
      // a special share model for Ottawa home games (CA feeds)
      if (home.id === 22) {
        if (output.name === 'CA National' || output.name === 'CA National (FR)') {
          return RS_NAT_CA_OTT;
        }
      }
      return RS_NAT;
    }
    return RS_INT;
  }

  if (output.name === 'Home RSN' || output.name === 'Home RSN (FR)') {
    if (!playoffs) {
      return RS_LOC_H_2022;
    }
    return SC_LOC_H;
  } else if (output.name === 'Away RSN' || output.name === 'Away RSN (FR)') {
    if (!playoffs) {
      return RS_LOC_A_2022;
    }
    return SC_LOC_A;
  } else if (
    output.name === 'CA National' ||
    output.name === 'CA National (FR)' ||
    output.name === 'US National'
  ) {
    // a special share model for Ottawa home games (CA feeds)
    if (home.id === 22) {
      if (output.name === 'CA National' || output.name === 'CA National (FR)') {
        return RS_NAT_CA_OTT_2022;
      }
    }
    if (!playoffs) {
      return RS_NAT_2022;
    }
    return SC_NAT;
  } else {
    if (!playoffs) {
      return RS_INT;
    }
    return SC_INT;
  }
};

export const getOutputNetworks = (event, outputName) => {
  let result = [];

  switch (outputName) {
    case 'Away RSN':
      if (event.away_networks) {
        const networks = event.away_networks.split(',');

        const nonNational_En = NETWORKS.filter(
          network => !network.isNational && network.isEnglish
        ).map(network => network.code);

        result = networks.filter(network => nonNational_En.includes(network));
      }
      break;

    case 'Home RSN':
      if (event.home_networks) {
        const networks = event.home_networks.split(',');

        const nonNational_En = NETWORKS.filter(
          network => !network.isNational && network.isEnglish
        ).map(network => network.code);

        result = networks.filter(network => nonNational_En.includes(network));
      }
      break;

    case 'Away RSN (FR)':
      if (event.away_networks) {
        const networks = event.away_networks.split(',');

        const nonNational_En = NETWORKS.filter(
          network => !network.isNational && !network.isEnglish
        ).map(network => network.code);

        result = networks.filter(network => nonNational_En.includes(network));
      }
      break;

    case 'Home RSN (FR)':
      if (event.home_networks) {
        const networks = event.home_networks.split(',');

        const nonNational_En = NETWORKS.filter(
          network => !network.isNational && !network.isEnglish
        ).map(network => network.code);

        result = networks.filter(network => nonNational_En.includes(network));
      }
      break;

    case 'US National':
      if (event.national_networks) {
        const networks = event.national_networks.split(',');
        const US_networks = NETWORKS.filter(network => network.isUS).map(network => network.code);

        result = networks.filter(network => US_networks.includes(network));
      }
      break;

    case 'CA National':
      if (event.national_networks) {
        let networks = event.national_networks.split(',');

        let CA_networks_En = NETWORKS.filter(network => !network.isUS && network.isEnglish).map(
          network => network.code
        );

        result = networks.filter(network => CA_networks_En.includes(network));
      }
      break;

    case 'CA National (FR)':
      if (event.national_networks) {
        const networks = event.national_networks.split(',');

        const CA_networks_Fr = NETWORKS.filter(network => !network.isUS && !network.isEnglish).map(
          network => network.code
        );

        result = networks.filter(network => CA_networks_Fr.includes(network));
      }
      break;
    default:
      result = [];
  }

  return result;
};

export const dbLambda = async (payload, functionName = 'hub2-api-event-get') => {
  const params = {
    FunctionName: functionName,
    Payload: JSON.stringify(payload),
  };

  const usLambdas = [
    'hub2-db-general-us-east',
    'hub2-db-general-node-20',
    'hub2-db-1',
    'hub2-db-2',
    'hub2-db-3',
    'hub2-db-oog',
  ];
  const usRegion = usLambdas.includes(functionName);
  const authService = new AuthService();
  const lambda = usRegion ? await authService.getLambdaUS() : await authService.getLambdaEU();
  console.log(functionName, 'US:', usRegion);
  const result = await invokeLambda(lambda, params);
  return JSON.parse(result.Payload);
};

export const getColoredRows_SV = (competitionId, outputName) => {
  const season2324Ids = [3, 4, 11];
  const season2425Ids = [7, 12];
  const season2324 = season2324Ids.includes(competitionId);
  const season2425 = season2425Ids.includes(competitionId);
  let purpleRows = [1, 8, 15];
  let yellowRows = [4, 11, 18];

  if (season2425) {
    // the first 3 minutes of each period
    purpleRows = [1, 2, 3, 4, 5, 6, 42, 43, 44, 45, 46, 47, 83, 84, 85, 86, 87, 88];
    yellowRows = [];
  } else if (season2324) {
    // national feeds
    if (['US National', 'CA National', 'CA National [FR]'].includes(outputName)) {
      purpleRows = [1, 4, 9, 17, 20];
      yellowRows = [12];
    }
    // local and international feeds
    else {
      purpleRows = [1, 9, 17];
      yellowRows = [];
    }
  }

  return { purpleRows, yellowRows };
};

export function getFileNameFromUrl(fileUrl) {
  let fileName = fileUrl;
  const n = fileUrl.lastIndexOf('/');
  if (n > -1)
    // does the url contain any '/' characters?
    fileName = fileUrl.substring(n + 1);
  return fileName;
}
export const isNumeric = str => {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};
export const getMediaType = fileName => {
  const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'bmp',
    'svg',
    'jfif',
    'pjpeg',
    'pjp',
    'svg',
    'webp',
    'gif',
    'apng',
    'avif',
  ];
  const videoExtensions = ['mp4', 'mov', 'avi', 'webm'];
  const audioExtensions = ['mp3'];
  const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  if (imageExtensions.includes(extension)) {
    return 'image';
  } else if (videoExtensions.includes(extension)) {
    return 'video';
  } else if (audioExtensions.includes(extension)) {
    return 'audio';
  }
  return null;
};
export const downloadMedia = async (keyWithPrefix, bucket, setLoading) => {
  if (setLoading) setLoading(true);
  // as we are downloading from AWS S3 bucket,
  // we presume to have either 'cloudfront.net'
  // or 'amazonaws.com' in keyWithPrefix
  const key = keyWithPrefix.includes('.net/')
    ? keyWithPrefix.split('.net/')[1]
    : keyWithPrefix.split('.com/')[1];

  const downloadFile = (bytesArray, fileName) => {
    const blob = new Blob([bytesArray]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    if (setLoading) setLoading(false);
    link.click();
  };

  const fileName = getFileNameFromUrl(key);
  try {
    const options = {
      Bucket: bucket,
      Key: key,
      ResponseContentType: 'application/json',
    };
    const authService = new AuthService();
    const s3 = await authService.getS3();
    const data = await s3.getObject(options).promise();
    downloadFile(data.Body, fileName);
  } catch (e) {
    throw new Error(`Could not retrieve file from S3: ${e.message}`);
  }
};
export const getUploadedFileDimensions = file =>
  new Promise((resolve, reject) => {
    try {
      let img = new Image();
      img.onload = () => {
        const width = img.naturalWidth,
          height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        return resolve({ width, height });
      };
      img.src = window.URL.createObjectURL(file);
    } catch (exception) {
      return reject(exception);
    }
  });

/**
 * Checks if a given file is an image based on its (valid) extension.
 *
 * @param {File} file - The file to be checked.
 * @returns {boolean} - Returns true if the file is an image, false otherwise.
 */
export const isFileAnImage = file => {
  const validImageExtensions = new Set([
    'jpg',
    'jpeg',
    'png',
    'bmp',
    'jfif',
    'pjpeg',
    'pjp',
    'webp',
    'gif',
    'apng',
    'avif',
  ]);
  const extension = file.name.split('.').pop().toLowerCase();
  return validImageExtensions.has(extension);
};

export const getTimeOrDateInEtTimezone = (origDate, timeOnly = true) => {
  if (!origDate) {
    return '';
  }

  let d = new Date(origDate);
  const ET_OFFSET = getTimezoneOffset('America/New_York', d);
  const ET_OFFSET_MILLISECONDS = ET_OFFSET * 60 * 60 * 1000;
  d = new Date(d.getTime() + ET_OFFSET_MILLISECONDS);

  const dateOptions = timeOnly
    ? { timeZone: 'UTC', hour: 'numeric', minute: '2-digit' }
    : { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' };

  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
  const dateAsFormattedString = dateFormatter.format(d).replace(' AM', 'am').replace(' PM', 'pm');
  return timeOnly ? dateAsFormattedString + ' ET' : dateAsFormattedString;
};
// Converts feed string => array or vice versa,
// depeding on the argument type.
// In case all feeds are selected, returns null.
export const convertFeeds = feeds => {
  if (!feeds || !feeds.length) return null;
  if (typeof feeds === 'string') {
    const feedArr = [];
    for (const feedKey in FEED_MAP) {
      if (feeds.includes(FEED_MAP[feedKey])) {
        feedArr.push(feedKey);
      }
    }
    return feedArr;
  }
  let feedStr = '';
  for (const feed of feeds) {
    feedStr += FEED_MAP[feed];
  }
  return feedStr;
};

export const getFormattedMatchDate = dateString => {
  let d = new Date(dateString);
  const ET_OFFSET = getTimezoneOffset('America/New_York', d);
  const ET_OFFSET_MILLISECONDS = ET_OFFSET * 60 * 60 * 1000;
  d = new Date(d.getTime() + ET_OFFSET_MILLISECONDS);

  let month = '' + (d.getUTCMonth() + 1);
  let day = '' + d.getUTCDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('-');
};
export const convertSeconds = totalSeconds => {
  //let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;
  // leading zeroes
  minutes = String(minutes).padStart(2, '0');
  //hours = String(hours).padStart(2, "0");
  seconds = String(seconds).padStart(2, '0');
  // return hours + ":" + minutes + ":" + seconds;
  return minutes + ':' + seconds;
};
// insert string in filename before extension suffix
// e.g. public/file.jpg -> public/file_resized.jpg
export const appendToFilename = (filename, string) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex == -1) return filename + string;
  else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
};
export const contextMenuFunction = (obj, x, y, e) => {
  var items = [];

  // click on upper part of column
  if (y === null) {
    // Insert a new column
    // if (obj.options.allowInsertColumn == true) {
    //     items.push({
    //         title:obj.options.text.insertANewColumnBefore,
    //         onclick:function() {
    //             obj.insertColumn(1, parseInt(x), 1);
    //         }
    //     });
    // }
    // if (obj.options.allowInsertColumn == true) {
    //     items.push({
    //         title:obj.options.text.insertANewColumnAfter,
    //         onclick:function() {
    //             obj.insertColumn(1, parseInt(x), 0);
    //         }
    //     });
    // }
    // // Delete a column
    // if (obj.options.allowDeleteColumn == true) {
    //     items.push({
    //         title:obj.options.text.deleteSelectedColumns,
    //         onclick:function() {
    //             obj.deleteColumn(obj.getSelectedColumns().length ? undefined : parseInt(x));
    //         }
    //     });
    // }
    // Rename column
    // if (obj.options.allowRenameColumn == true) {
    //     items.push({
    //         title:obj.options.text.renameThisColumn,
    //         onclick:function() {
    //             obj.setHeader(x);
    //         }
    //     });
    // }
    // Sorting
    // if (obj.options.columnSorting == true) {
    // Line
    // items.push({ type:'line' });
    // items.push({
    //     title:obj.options.text.orderAscending,
    //     onclick:function() {
    //         obj.orderBy(x, 0);
    //     }
    // });
    // items.push({
    //     title:obj.options.text.orderDescending,
    //     onclick:function() {
    //         obj.orderBy(x, 1);
    //     }
    // });
    // }
  } else {
    // Insert new row
    // if (obj.options.allowInsertRow == true) {
    //     items.push({
    //         title: 'Insert interval above', //obj.options.text.insertANewColumnBefore,
    //         onclick:function() {
    //             obj.insertRow(1, parseInt(y), 1);
    //         }
    //     });

    //     items.push({
    //         title: 'Insert interval below', //obj.options.text.insertANewRowAfter,
    //         onclick:function() {
    //             obj.insertRow(1, parseInt(y));
    //         }
    //     });
    // }

    // if (obj.options.allowDeleteRow == true) {
    //     items.push({
    //         title: 'Delete selected intervals',  // title:obj.options.text.deleteSelectedRows,
    //         onclick:function() {
    //             obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
    //         }
    //     });
    // }

    if (x) {
      if (obj.options.allowComments == true) {
        items.push({ type: 'line' });

        var title = obj.records[y][x].getAttribute('title') || '';

        items.push({
          title: title ? obj.options.text.editComments : obj.options.text.addComments,
          onclick: function () {
            obj.setComments([x, y], prompt(obj.options.text.comments, title));
          },
        });

        if (title) {
          items.push({
            title: obj.options.text.clearComments,
            onclick: function () {
              obj.setComments([x, y], '');
            },
          });
        }
      }
    }
  }

  // Line
  items.push({ type: 'line' });

  // Save
  if (obj.options.allowExport) {
    items.push({
      title: 'Save to CSV', // title: obj.options.text.saveAs,
      shortcut: 'Ctrl + S',
      onclick: function () {
        obj.download();
      },
    });
  }
  return items;
};

// handle event lock
export const isEventLocked = (et_time, no_lock) => {
  const ET_OFFSET = getTimezoneOffset('America/New_York', new Date());
  const lockDay = new Date(deltaDate(new Date(et_time), 2));
  lockDay.setUTCHours(23 - ET_OFFSET, 59, 0, 0); // modify the original date object directly
  const isLocked = new Date() > lockDay;

  return isLocked && !no_lock;
};

export const getUniqueAdvertisers = ads => {
  const advertiserMap = new Map();
  ads.forEach(ad => {
    const { name, code } = ad.advertiser;
    if (!advertiserMap.has(name)) {
      advertiserMap.set(name, { name, code });
    }
  });
  return Array.from(advertiserMap.values());
};

// export const getColWidthDED = () => {
//     const windowWidth = window.innerWidth ? window.innerWidth : 1700;
//     let zoneColWidth = 190;

//     if (windowWidth <= 1060) {
//         zoneColWidth = 70;
//     } else if (windowWidth <= 1160) {
//         zoneColWidth = 90;
//     } else if (windowWidth <= 1260) {
//         zoneColWidth = 110;
//     } else if (windowWidth <= 1360) {
//         zoneColWidth = 130;
//     } else if (windowWidth <= 1440) {
//         zoneColWidth = 150;
//     } else if (windowWidth <= 1536) {
//         zoneColWidth = 179;
//     } else if (windowWidth <= 1555) {
//         zoneColWidth = 183;
//     } else if (windowWidth <= 1580) {
//         zoneColWidth = 188;
//     } else if (windowWidth <= 1630) {
//         zoneColWidth = 194;
//     } else if (windowWidth <= 1664) {
//         zoneColWidth = 202;
//     } else if (windowWidth <= 1680) {
//         zoneColWidth = 208;
//     } else if (windowWidth <= 1700) {
//         zoneColWidth = 213;
//     }else if (windowWidth <= 1735) {
//         zoneColWidth = 218;
//     } else if (windowWidth <= 1770) {
//         zoneColWidth = 224;
//     }else if (windowWidth <= 1800) {
//         zoneColWidth = 230;
//     } else if (windowWidth <= 1840) {
//         zoneColWidth = 237;
//     } else if (windowWidth <= 1880) {
//         zoneColWidth = 245;
//     }else if (windowWidth <= 1910) {
//         zoneColWidth = 254;
//     }else if (windowWidth <= 1965) {
//         zoneColWidth = 260;
//     }else if (windowWidth <= 2000) {
//         zoneColWidth = 266;
//     } else if (windowWidth <= 2050) {
//         zoneColWidth = 272;
//     } else if (windowWidth <= 2100) {
//         zoneColWidth = 280;
//     } else if (windowWidth <= 2150) {
//         zoneColWidth = 291;
//     } else if (windowWidth <= 2200) {
//         zoneColWidth = 301;
//     } else if (windowWidth <= 2250) {
//         zoneColWidth = 311;
//     } else if (windowWidth <= 2300) {
//         zoneColWidth = 318;
//     } else if (windowWidth <= 2350) {
//         zoneColWidth = 328;
//     }
//     else if (windowWidth <= 2400) {
//         zoneColWidth = 338;
//     }  else if (windowWidth <= 2450) {
//         zoneColWidth = 348;
//     }  else if (windowWidth <= 2500) {
//         zoneColWidth = 358;
//     }  else if (windowWidth <= 2550) {
//         zoneColWidth = 368;
//     }  else if (windowWidth <= 2600) {
//         zoneColWidth = 378;
//     }  else if (windowWidth <= 2650) {
//         zoneColWidth = 384;
//     }  else if (windowWidth <= 2700) {
//         zoneColWidth = 392;
//     }  else if (windowWidth <= 2750) {
//         zoneColWidth = 402;
//     }
//     else if (windowWidth <= 2850) {
//         zoneColWidth = 410;
//     }  else if (windowWidth <= 2900) {
//         zoneColWidth = 420;
//     }  else if (windowWidth <= 3000) {
//         zoneColWidth = 430;
//     }  else if (windowWidth <= 3100) {
//         zoneColWidth = 445;
//     }  else if (windowWidth <= 3200) {
//         zoneColWidth = 460;
//     }  else if (windowWidth <= 3300) {
//         zoneColWidth = 475;
//     } else if (windowWidth <= 3400) {
//         zoneColWidth = 490;
//     }  else if (windowWidth <= 3500) {
//         zoneColWidth = 505;
//     }  else if (windowWidth <= 3600) {
//         zoneColWidth = 520;
//     }  else if (windowWidth <= 3700) {
//         zoneColWidth = 540;
//     }
//     else if (windowWidth <= 3800) {
//         zoneColWidth = 570;
//     } else if (windowWidth > 3800) {
//         zoneColWidth = 590;
//     }

//     zoneColWidth -= 10;
//     return zoneColWidth;
// }

export const getColWidthDED = () => {
  const windowWidth = window.innerWidth || 1700;

  const widthMap = [
    { maxWidth: 1060, colWidth: 80 },
    { maxWidth: 1110, colWidth: 90 },
    { maxWidth: 1160, colWidth: 100 },
    { maxWidth: 1210, colWidth: 110 },
    { maxWidth: 1260, colWidth: 120 },
    { maxWidth: 1310, colWidth: 130 },
    { maxWidth: 1360, colWidth: 140 },
    { maxWidth: 1440, colWidth: 150 },
    { maxWidth: 1505, colWidth: 160 },
    { maxWidth: 1536, colWidth: 179 },
    { maxWidth: 1555, colWidth: 183 },
    { maxWidth: 1580, colWidth: 188 },
    { maxWidth: 1630, colWidth: 194 },
    { maxWidth: 1664, colWidth: 200 },
    { maxWidth: 1680, colWidth: 202 },
    { maxWidth: 1700, colWidth: 207 },
    { maxWidth: 1735, colWidth: 211 },
    { maxWidth: 1770, colWidth: 217 },
    { maxWidth: 1800, colWidth: 222 },
    { maxWidth: 1840, colWidth: 230 },
    { maxWidth: 1880, colWidth: 238 },
    { maxWidth: 1910, colWidth: 247 },
    { maxWidth: 1965, colWidth: 253 },
    { maxWidth: 2000, colWidth: 259 },
    { maxWidth: 2050, colWidth: 265 },
    { maxWidth: 2100, colWidth: 273 },
    { maxWidth: 2150, colWidth: 284 },
    { maxWidth: 2200, colWidth: 294 },
    { maxWidth: 2250, colWidth: 304 },
    { maxWidth: 2300, colWidth: 311 },
    { maxWidth: 2350, colWidth: 318 },
    { maxWidth: 2400, colWidth: 328 },
    { maxWidth: 2450, colWidth: 338 },
    { maxWidth: 2500, colWidth: 348 },
    { maxWidth: 2550, colWidth: 358 },
    { maxWidth: 2600, colWidth: 368 },
    { maxWidth: 2650, colWidth: 374 },
    { maxWidth: 2700, colWidth: 382 },
    { maxWidth: 2750, colWidth: 392 },
    { maxWidth: 2850, colWidth: 400 },
    { maxWidth: 2900, colWidth: 410 },
    { maxWidth: 3000, colWidth: 420 },
    { maxWidth: 3100, colWidth: 435 },
    { maxWidth: 3200, colWidth: 450 },
    { maxWidth: 3300, colWidth: 465 },
    { maxWidth: 3400, colWidth: 480 },
    { maxWidth: 3500, colWidth: 495 },
    { maxWidth: 3600, colWidth: 510 },
    { maxWidth: 3700, colWidth: 530 },
    { maxWidth: 3800, colWidth: 560 },
    { maxWidth: Infinity, colWidth: 590 },
  ];

  const { colWidth } = widthMap.find(({ maxWidth }) => windowWidth <= maxWidth);

  return colWidth - 10;
};

export const getColWidthGV = () => {
  const windowWidth = window.innerWidth || 1700;

  const widthMap = [
    { maxWidth: 1050, colWidth: 90 },
    { maxWidth: 1100, colWidth: 105 },
    { maxWidth: 1150, colWidth: 120 },
    { maxWidth: 1200, colWidth: 130 },
    { maxWidth: 1250, colWidth: 140 },
    { maxWidth: 1300, colWidth: 150 },
    { maxWidth: 1350, colWidth: 160 },
    { maxWidth: 1440, colWidth: 170 },
    { maxWidth: 1490, colWidth: 190 },
    { maxWidth: 1536, colWidth: 210 },
    { maxWidth: 1680, colWidth: 230 },
    { maxWidth: 1750, colWidth: 240 },
    { maxWidth: 1800, colWidth: 260 },
    { maxWidth: 2000, colWidth: 290 },
    { maxWidth: 2300, colWidth: 330 },
    { maxWidth: 2400, colWidth: 350 },
    { maxWidth: 2800, colWidth: 390 },
    { maxWidth: 3400, colWidth: 450 },
    { maxWidth: 3800, colWidth: 480 },
    { maxWidth: Infinity, colWidth: 620 },
  ];

  const { colWidth } = widthMap.find(({ maxWidth }) => windowWidth <= maxWidth);
  return colWidth;
};

// export const createHdmFiles = async (props) => {
//     const outputId = props.output.id;

//     const tmpSegment = props.event.segments[0];
//     const s3Location = `export/${outputId}/`;
//     const eventFileKey = s3Location + 'event.hdm';

//     const sequenceFileKey = s3Location + `sequences/${props.output.name.replace(/ /g, "-")}_${tmpSegment.name.replace(/ /g, "-").toLowerCase()}.hdm`;
//     console.log(sequenceFileKey)

//     const eventFile = helperFunctions.createEventFile(props.event, props.output);
//     const sequenceFile = helperFunctions.createSequenceFile(props.event, props.output, tmpSegment, props.adverts, props.advertisers, props.impressions, props.placements);
//     console.log(sequenceFile)
//     const res = await S3.putObject({
//         Bucket: props.bucket,
//         Key: eventFileKey,
//         Body: eventFile
//     }).promise();
//     const resSequence = await S3.putObject({
//         Bucket: props.bucket,
//         Key: sequenceFileKey,
//         Body: sequenceFile
//     }).promise();
//     return [eventFileKey, sequenceFileKey]
// }
