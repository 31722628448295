import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';

const AppBreadcrumb = props => {
  console.log(props.routers);
  console.log(props.location);
  if (props.location.state) {
    console.log(props.location.state);
    // return <span><a href="/#/competitions">{props.location.state.metaInfo. </a> / {id}</span>;
  }

  const location = useLocation();
  const pathname = location.pathname;

  let name = pathname.replace('/', '');

  if (name.startsWith('advert/')) {
    const id = name.split('/').pop();
    return (
      <span>
        <a href="/#/adverts">ADVERTISEMENTS </a> / {id}
      </span>
    );
  }
  if (name.startsWith('competition/')) {
    const id = name.split('/').pop();
    return (
      <span>
        <a href="/#/competitions">COMPETITIONS </a> / {id}
      </span>
    );
  }
  if (name.startsWith('event/')) {
    const id = name.split('/').pop();
    // const _href = '/#/competition/:' + id;
    return (
      <span>
        <a href="/#/competitions">EVENTS </a> / {id}
      </span>
    );
  }
  if (props.routers) {
    let currentRouter = props.routers.find(router => router.path === pathname);
    name = currentRouter ? currentRouter['meta'].breadcrumb[0].label : name;
  }

  return <span>{name}</span>;
};

export default withRouter(AppBreadcrumb);
